import { Button, LoadingLogoFullPage, OrdersIcon } from '@common-ui';

import { useAppMutation, useToast } from '@web-admin/hooks/internals';
import { useAirport } from '@web-admin/hooks/stores';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Breadcrumb, Modal } from 'antd';
import { DetailPrice } from './DetailPrice';
import ModalConfirmOrder from './ModalConfirmOrder';
import ModalContent from '@vemaybay-admin/components/Modal/ModalContent';
import { EModalMode } from '@vemaybay-admin/types';
import {
  hotelApiServiceWebPartnership,
  IBookingDetail,
} from '@tixlabs/grpc-client/web-partnership';
import { InfoBookingDB } from './InfoBookingDB';
import { objClassName } from '../Status';
import { BOOKING_STATUS_HOTEL_LABEL } from '@vemaybay-admin/utils';
import { BookingStatus } from '@api/hotel/base_pb';
import { useHotel } from '@vemaybay-admin/hooks/apps';
import { Form } from 'antd';

import TextArea from 'antd/es/input/TextArea';
import { DifferentPrice } from './DifferentPrice';
import { useTime } from '@vemaybay-admin/hooks/internals';

type Props = {
  //
};

export const DetailOrderHotel = (props: Props) => {
  const [modalRefuned, setModalRefuned] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalCancel, setModalCancel] = useState(false);
  const navigate = useNavigate();
  const { updateBookingStatus } = useHotel();

  const { error, success } = useToast();
  const { formatHourDateTime } = useTime();
  const [formCancel] = Form.useForm();

  const [orderCode, setOrderCode] = useState<string>('');
  const toast = useToast();
  const handleSubmitCancel = async (data) => {
    const fakeData = [{ occupancyIndex: 1, confirmationId: 'string' }];
    if (orderCode) {
      await updateBookingStatus({
        actualAmount: 0,
        orderCode,
        cancelReason: data?.reasonCancel,
        occupancyUpdatesList: fakeData,
        source: '',
        status: BookingStatus.CANCELLED,
      }).then((res) => {
        toast.success('Hủy đơn hàng thành công');
        getBookingDetail({ orderCode });
      });
      setModalCancel(false);
    }
  };

  const handleRefuned = async () => {
    // if (orderCode) {
    //   await updateBookingStatus({
    //     actualAmount: 0,
    //     orderCode,
    //     reservationCode: '',
    //     status: BookingStatus.REFUNDED,
    //     cancelReason: 'Hoàn tiền',
    //   }).then((res) => {
    //     console.log('res refund', res);
    //     toast.success('Xác nhận hoàn tiền thành công');
    //   });
    //   setModalRefuned(false);
    // }
  };

  useEffect(() => {
    const pathSegments = window.location.pathname.split('/');
    const code = pathSegments[pathSegments.length - 1]; // Assuming `orderCode` is the last segment
    setOrderCode(code);
  }, []);

  const [bookingDetail, setBookingDetail] = useState<IBookingDetail>();

  const { mutateAsync: getBookingDetail, isLoading } = useAppMutation({
    mutationKey: ['hotelApiServiceWebPartnership', 'getBookingDetail'],
    mutationFn: hotelApiServiceWebPartnership.getBookingDetail,
    onSuccess: ({ isSuccess, data, errorCode }) => {
      if (isSuccess) {
        setBookingDetail(data);
      } else {
        error(errorCode);
        // navigate('/order-management-hotel', {
        //   replace: true,
        // });
      }
    },
    onError: (error) => {
      // navigate('/order-management-hotel', {
      //   replace: true,
      // });
    },
  });

  const renderButtons = () => {
    switch (bookingDetail?.status) {
      case BookingStatus.PENDING:
        return (
          <div className='flex gap-3'>
            <Button
              onClick={() => setModalConfirm(true)}
              theme='green'
              className='w-1/2'>
              Hoàn thành
            </Button>
            <Button
              theme='secondary'
              className='w-1/2'
              onClick={() => setModalCancel(true)}>
              Hủy đơn hàng
            </Button>
          </div>
        );
      // case BookingStatus.CANCELLED:
      //   return (
      //     <div className='flex justify-end'>
      //       <Button
      //         className='w-1/2 bg-purple-600 hover:bg-purple-400 active:!bg-purple-400'
      //         onClick={() => setModalRefuned(true)}>
      //         Xác nhận hoàn tiền
      //       </Button>
      //     </div>
      //   );
      default:
        break;
    }
    return null;
  };

  useEffect(() => {
    if (orderCode) {
      getBookingDetail({ orderCode });
    }
  }, [orderCode]);

  const renderInfo = () => {
    switch (bookingDetail?.status) {
      case BookingStatus.PENDING:
        return (
          <div className='flex gap-3'>
            {/* {`Thời gian hết hạn: ${formatDateTime(bookingDetail?.expiredAt)}`} */}
          </div>
        );
      case BookingStatus.CANCELLED:
      case BookingStatus.FAILED:
        return <div>{`Lý do hủy: ${bookingDetail?.cancelReason}`}</div>;
      // case BookingStatus.REFUNDED:
      //   return (
      //     <div>
      //       <div>
      //         Thời gian hoàn: {/* {formatDateTime(bookingDetail?.createdAt)} */}
      //       </div>
      //       <div>Lý do hủy .....</div>
      //     </div>
      //   );
      default:
        break;
    }
    return null;
  };

  return isLoading ? (
    <LoadingLogoFullPage />
  ) : (
    <div className='space-y-5 p-2.5'>
      <Breadcrumb
        items={[
          {
            title: (
              <span
                className='cursor-pointer'
                onClick={() => {
                  navigate('/');
                }}>
                Dashboard
              </span>
            ),
          },
          {
            title: (
              <span
                className='cursor-pointer'
                onClick={() => {
                  navigate('/order-management-hotel');
                }}>
                Quản lý đặt chỗ
              </span>
            ),
          },
          {
            title: 'Chi tiết đặt chỗ',
          },
        ]}
      />
      <div className='flex space-x-2.5 items-center'>
        <OrdersIcon className='w-5 h-5 shrink-0' />
        <span className='font-semibold text-lg'>
          <span className='mr-2'>Chi tiết đơn hàng</span>
          <span className='text-primary'>{orderCode}</span>
        </span>
      </div>

      <div className='flex gap-6 '>
        {/* left */}
        <div className='w-2/3 text-md flex flex-col gap-6'>
          <div className=' bg-white p-5 flex flex-col gap-5 border-neutral-3 rounded border'>
            <div className='flex justify-between'>
              <span>{`Mã đơn hàng: ${bookingDetail?.orderCode}`}</span>
              <span>
                Trạng thái:{' '}
                <span
                  className={`${objClassName[bookingDetail?.status || 0]
                    } font-semibold`}>
                  {BOOKING_STATUS_HOTEL_LABEL[bookingDetail?.status || 0]}
                </span>
              </span>
            </div>
            <div className='text-neutral-black flex justify-between'>
              <span>
                Ngày giờ đặt: {formatHourDateTime(bookingDetail?.createdAt)}
              </span>
              {renderInfo()}
            </div>
            {renderButtons()}
          </div>
          {bookingDetail && (
            <InfoBookingDB bookingData={bookingDetail}></InfoBookingDB>
          )}

          <div className='text-end'>
            <Button
              onClick={() => navigate('/order-management-hotel')}
              className='w-1/4'
              theme='primary'>
              Quay lại
            </Button>
          </div>
        </div>
        {/* right */}
        {bookingDetail && (
          <div className='w-1/3 flex flex-col gap-6'>
            <DetailPrice bookingData={bookingDetail}></DetailPrice>
            {bookingDetail?.status === BookingStatus.SUCCESS && (
              <DifferentPrice bookingData={bookingDetail}></DifferentPrice>
            )}
          </div>
        )}
      </div>

      <Modal
        className='custom-modal'
        title={
          <span className='text-2xl font-bold p-2 text-blue-500'>
            Xác nhận hoàn tất đơn hàng
          </span>
        }
        footer={null}
        centered={true}
        closeIcon={false}
        open={modalConfirm}
        width={800}>
        {bookingDetail && (
          <ModalConfirmOrder
            bookingDetail={bookingDetail}
            setModalConfirm={setModalConfirm}
            orderCode={orderCode}
            getBookingDetail={getBookingDetail}
          />
        )}
      </Modal>

      <Modal
        open={modalCancel}
        centered={true}
        closeIcon={false}
        footer={() => null}>
        <ModalContent
          className='w-full'
          mode={EModalMode.WARNING}
          content={
            <div>
              <div className='mb-4  text-lg text-black font-semibold'>
                Bạn đang thực hiện hủy đơn hàng. Vui lòng cân nhắc và kiểm tra
                cẩn thận trước khi xác nhận.
              </div>
              <div className='mb-2 font-semibold text-md'>
                Lý do hủy đơn hàng *
              </div>
              <Form form={formCancel} onFinish={handleSubmitCancel}>
                <Form.Item
                  name='reasonCancel'
                  rules={[
                    { required: true, message: 'Vui lòng nhập lý do hủy' },
                  ]}>
                  <TextArea
                    maxLength={100}
                    rows={3}
                    style={{ resize: 'none' }}
                    placeholder='Nhập tối đa 100 ký tự'
                  />
                </Form.Item>
                <div className='flex gap-3 justify-center'>
                  <Button
                    onClick={() => {
                      setModalCancel(false);
                      formCancel.resetFields();
                    }}
                    theme='neutral'
                    className='w-1/3'>
                    Quay lại
                  </Button>
                  <Button type='submit' theme='primary' className='w-1/3'>
                    Xác nhận
                  </Button>
                </div>
              </Form>
            </div>
          }
        />
      </Modal>

      <Modal
        open={modalRefuned}
        centered={true}
        closeIcon={false}
        footer={() => null}>
        <ModalContent
          className='w-full'
          mode={EModalMode.WARNING}
          content={
            <div>
              <div className='mb-4 text-center text-lg text-black font-semibold'>
                Sau khi bạn xác nhận, hệ thống sẽ tự động gửi một email thông
                báo cho khách hàng về việc hoàn tiền.
              </div>
              <div className='flex gap-3 justify-center'>
                <Button
                  onClick={() => setModalRefuned(false)}
                  theme='neutral'
                  className='w-1/3'>
                  Quay lại
                </Button>
                <Button
                  onClick={handleRefuned}
                  theme='primary'
                  className='w-1/3'>
                  Xác nhận
                </Button>
              </div>
            </div>
          }
        />
      </Modal>
    </div>
  );
};

export default DetailOrderHotel;
