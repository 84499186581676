import { Location } from '@icon';
import { IBookingDetail } from '@tixlabs/grpc-client/web-partnership';
import { useCountry } from '@vemaybay-admin/hooks/apps';
import { useTime } from '@vemaybay-admin/hooks/internals';
import { Rate } from 'antd';
import { InfoInvoiceDB } from './InfoInvoiceDB';
import { InfoAndPolicy } from './InfoAndPolicy';

interface Props {
  bookingData: IBookingDetail;
}

export const InfoBookingDB = ({ bookingData }: Props) => {
  const { formatDateDayMonthYear } = useTime();

  const { getCountryByCode } = useCountry();

  const countryCode = bookingData.hotelInfo?.address?.countryCode;
  const countryName = countryCode
    ? getCountryByCode(countryCode)?.country
    : 'Unknown Country';

  return (
    <div className=' bg-white p-5 border-neutral-3 rounded border flex flex-col gap-4'>
      <div className='flex flex-col gap-1'>
        <span className='text-lg font-semibold'>
          {bookingData.hotelInfo?.name}
        </span>
        <div className='flex items-center gap-2 text-custom-gray'>
          <Location className='h-4 w-4 text-current' />
          <div>
            {[
              bookingData.hotelInfo?.address?.line1,
              bookingData.hotelInfo?.address?.line2,
              bookingData.hotelInfo?.address?.city,
              countryName,
            ]
              .filter((value) => value !== undefined && value !== '') // Chỉ giữ lại các giá trị không phải undefined và không rỗng
              .join(', ')}{' '}
          </div>
        </div>
        <Rate
          className='custome-star'
          disabled
          allowHalf
          defaultValue={bookingData.hotelInfo?.rating || 0}
        />
      </div>

      <div className='flex flex-col'>
        <div className='flex gap-6 flex-col'>
          {bookingData.roomsList.map((room, index) => (
            <div key={index}>
              <div className='text-custom-gray grid grid-cols-3 mb-3'>
                <span>
                  <span className='font-semibold'>{`Phòng ${index + 1}:`}</span>{' '}
                  <span>{room.name}</span>
                  {room.occupancy?.adt && room.occupancy?.adt > 0 ? (
                    <span>{`, ${room.occupancy?.adt} người lớn`}</span>
                  ) : null}
                  {room.occupancy?.chd && room.occupancy?.chd > 0 ? (
                    <span>{`, ${room.occupancy?.chd} trẻ em`}</span>
                  ) : null}
                </span>

                <span>
                  {room?.bedOption?.totalBeds && room?.bedOption?.totalBeds > 0
                    ? `${room?.bedOption?.name}`
                    : null}
                </span>

                <span className='font-semibold text-md'>
                  {`Mã đặt chỗ: ${room?.confirmationId}`}
                </span>
              </div>
              <div className='grid md:grid-cols-3'>
                <div className='flex flex-col'>
                  <span className='text-gray-500'>Họ và tên</span>
                  <span className='font-semibold'>
                    {room?.holder?.surname} {room?.holder?.givenName}
                  </span>
                </div>

                <div className='flex flex-col'>
                  <span className='text-gray-500'>Email</span>
                  <span className='w-fit'>{room?.holder?.email}</span>
                </div>

                <div className='flex flex-col'>
                  <span className='text-gray-500'>Số điện thoại</span>
                  <span>
                    {room?.holder?.phoneCode && room?.holder?.phoneNumber
                      ? `(${room?.holder?.phoneCode}) ${room?.holder?.phoneNumber}`
                      : null}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='border-[1px] border-slate-300 border-dashed'></div>

      <div className='grid md:grid-cols-3 md:gap-3 gap-[8px]'>
        <div className='flex flex-col gap-[2px] md:gap-0'>
          <div className='md:mb-[6px] text-gray-500'>Nhận phòng</div>
          <div className='font-semibold'>
            {bookingData?.checkInDate &&
              bookingData?.checkInDate !== '' &&
              formatDateDayMonthYear(bookingData?.checkInDate)}
          </div>
          <div className='text-gray-500'>
            {bookingData?.checkInTime && `Từ ${bookingData?.checkInTime}`}
          </div>
        </div>

        <div className='flex flex-col'>
          <div className='md:mb-[6px] text-gray-500'>Trả phòng</div>
          <div className='font-semibold'>
            {bookingData?.checkOutDate &&
              bookingData?.checkOutDate !== '' &&
              formatDateDayMonthYear(bookingData?.checkOutDate)}
          </div>
          <div className='text-gray-500'>
            {bookingData?.checkOutTime && `Đến ${bookingData?.checkOutTime}`}
          </div>
        </div>

        <div className='flex flex-col'>
          <div className='md:mb-[6px] text-gray-500'>
            Tổng thời gian lưu trú
          </div>
          <div className='font-semibold'>{`${bookingData.totalNights} đêm`}</div>
        </div>
      </div>

      <div className='border-[1px] border-slate-300 border-dashed'></div>
      {bookingData && <InfoAndPolicy bookingData={bookingData} />}


      {bookingData?.invoicingInfo && <div className='border-[1px] border-slate-300 border-dashed'></div>}
      {bookingData?.invoicingInfo && (
        <InfoInvoiceDB invoicingInfo={bookingData?.invoicingInfo} />
      )}
    </div>
  );
};
