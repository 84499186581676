import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import { HotelServiceClient as SkyHubHotelServiceClient } from '@api/skyhub/web_partnership/hotel_grpc_web_pb';
import {
  SearchHotelReq,
  SearchHotelRes,
} from '@api/skyhub/web_partnership/hotel_pb';
import { BasicRes, Empty, PaginationReq } from '@api/base/base_pb';

class SkyHubHotelServiceApi extends BaseApi<SkyHubHotelServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new SkyHubHotelServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  searchHotel = ({
    keywords,
    language,
    pagination,
  }: SearchHotelReq.AsObject): Promise<SearchHotelRes.AsObject> => {
    const req = new SearchHotelReq();
    const paginationObj = new PaginationReq();

    keywords && req.setKeywords(keywords);
    language && req.setLanguage(language);

    if (pagination) {
      paginationObj.setPageNumber(pagination.pageNumber);
      paginationObj.setPageLimit(pagination.pageLimit);
      req.setPagination(paginationObj);
    }

    return this.grpc<SearchHotelReq, SearchHotelRes, SearchHotelRes.AsObject>(
      this.serviceClient.searchHotel,
      req,
      {}
    );
  };
}

export const skyHubHotelServiceApi = new SkyHubHotelServiceApi();
