import { IRetrieveBookingFlightData } from '@tixlabs/grpc-client/web-partnership';
import { EPaxType } from '@tixlabs/grpc-client/';
import React from 'react';
import { useCurrency } from '@vemaybay-admin/hooks/internals';

type Props = {
  bookingFlight: IRetrieveBookingFlightData;
};

export const PaymentInfo = ({ bookingFlight }: Props) => {
  const { formatPrice, formatCurrency } = useCurrency();

  const counts = {
    [EPaxType.ADT]: 0,
    [EPaxType.CHD]: 0,
    [EPaxType.INF]: 0,
  };

  bookingFlight.passengersList.forEach((passenger) => {
    counts[passenger.type]++;
  });

  const ticketPrice =
    bookingFlight.fee?.itemsList.reduce((sum: number, pax) => {
      return sum + pax.totalPrice;
    }, 0) || 0;

  const contentArr: string[] = [];
  if (counts[EPaxType.ADT] > 0) {
    contentArr.push(`${counts[EPaxType.ADT]} người lớn`);
  }
  if (counts[EPaxType.CHD] > 0) {
    contentArr.push(`${counts[EPaxType.CHD]} trẻ em`);
  }
  if (counts[EPaxType.INF] > 0) {
    contentArr.push(`${counts[EPaxType.INF]} trẻ sơ sinh`);
  }

  const test = [
    {
      code: 'BAYVUIGHE',
      amount: 200000,
    },
    {
      code: 'BAYVUIGHE1',
      amount: 200000,
    },
  ];

  return (
    <div className='flex flex-col rounded-lg bg-white'>
      <h3 className='text-lg font-semibold py-3 px-4 mb-0'>
        Thông tin thanh toán
      </h3>
      <div className='flex flex-col gap-4 px-4 py-3'>
        <div className='flex flex-col gap-1'>
          <span className='text-md font-semibold'>Vé máy bay</span>
          <div className='flex items-center gap-2'>
            <span className='flex-1 text-neutral-gray-60'>{`Hành khách (${contentArr.join(
              ', '
            )})`}</span>
            <span className='text-neutral-gray-60'>
              {formatCurrency(ticketPrice, bookingFlight.currency)}
            </span>
          </div>
        </div>
        {/* <div className='flex flex-col gap-1'>
          <span className='text-md font-semibold'>Dịch vụ bổ sung</span>
          <div className='flex items-center gap-2'>
            <span className='flex-1 text-neutral-gray-60'>Hành lý thêm</span>
            <span className='text-neutral-gray-60'>
              {formatPrice(0)} {bookingFlight.currency}
            </span>
          </div>
          <div className='flex items-center gap-2'>
            <span className='flex-1 text-neutral-gray-60'>Chọn chỗ ngồi</span>
            <span className='text-neutral-gray-60'>
              {formatPrice(0)} {bookingFlight.currency}
            </span>
          </div>
        </div> */}

        {(bookingFlight.paymentInfo ||
          (bookingFlight.fee?.vat !== undefined &&
            bookingFlight.fee?.vat > 0)) && (
          <div className='flex flex-col gap-1'>
            <span className='text-md font-semibold'>Khác</span>
            {bookingFlight.paymentInfo && (
              <div className='flex items-center gap-2'>
                <span className='flex-1 text-neutral-gray-60'>
                  Phí tiện ích
                </span>
                <span className='text-neutral-gray-60'>
                  {formatCurrency(
                    bookingFlight.paymentInfo?.feeAmount,
                    bookingFlight.currency
                  )}{' '}
                </span>
              </div>
            )}
            {bookingFlight.fee?.vat !== undefined &&
              bookingFlight.fee?.vat > 0 && (
                <div className='flex items-center gap-2'>
                  <span className='flex-1 text-neutral-gray-60'>Phí VAT</span>
                  <span className='text-neutral-gray-60'>
                    {formatCurrency(
                      bookingFlight.fee?.vat,
                      bookingFlight.currency
                    )}{' '}
                  </span>
                </div>
              )}
          </div>
        )}
        {bookingFlight?.promoLogsList &&
          bookingFlight.promoLogsList.length > 0 && (
            <div className='flex w-full'>
              {/* <div className='w-[80px]'>
                <span className='text-neutral-gray-60'>Khuyến mãi</span>
              </div> */}
              <div className='flex-1 text-neutral-gray-60'>
                {bookingFlight?.promoLogsList.map((item, index) => (
                  <div className='flex justify-between' key={index}>
                    <span>Khuyến mãi ({item.code})</span>
                    <span>
                      - {formatCurrency(item.amount, bookingFlight.currency)}
                    </span>
                  </div>
                ))}
                <div className='flex justify-between'></div>
              </div>
            </div>
          )}

        <div className='h-px bg-neutral-gray-30'></div>
        <div className='flex items-center'>
          <span className='flex-1 text-lg font-semibold'>Tổng thanh toán</span>
          <span className='flex-1 text-right text-lg font-semibold'>
            {formatCurrency(
              bookingFlight.paymentInfo?.totalPrice ||
                bookingFlight.fee?.priceAfterPromo ||
                0 ||
                0,
              bookingFlight.currency
            )}{' '}
            {}
          </span>
        </div>
        {bookingFlight.paymentInfo && (
          <div className='flex items-center'>
            <span className='flex-1 text-neutral-6'>
              Phương thức thanh toán
            </span>
            <span className='flex-1 text-right text-neutral-6'>
              {bookingFlight.paymentInfo.method?.name || ''}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
