/**
 * @fileoverview gRPC-Web generated client stub for skyhub.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: skyhub/web_partnership/hidden_fee.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var skyhub_hidden_fee_pb = require('../../skyhub/hidden_fee_pb.js')
const proto = {};
proto.skyhub = {};
proto.skyhub.web_partnership = require('./hidden_fee_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.skyhub.web_partnership.HiddenFeeServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.skyhub.web_partnership.HiddenFeeServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.base.Empty,
 *   !proto.skyhub.web_partnership.GetConfigOptionsRes>}
 */
const methodDescriptor_HiddenFeeService_GetConfigOptions = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.HiddenFeeService/GetConfigOptions',
  grpc.web.MethodType.UNARY,
  base_base_pb.Empty,
  proto.skyhub.web_partnership.GetConfigOptionsRes,
  /**
   * @param {!proto.base.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.GetConfigOptionsRes.deserializeBinary
);


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.GetConfigOptionsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.GetConfigOptionsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.HiddenFeeServiceClient.prototype.getConfigOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.HiddenFeeService/GetConfigOptions',
      request,
      metadata || {},
      methodDescriptor_HiddenFeeService_GetConfigOptions,
      callback);
};


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.GetConfigOptionsRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.HiddenFeeServicePromiseClient.prototype.getConfigOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.HiddenFeeService/GetConfigOptions',
      request,
      metadata || {},
      methodDescriptor_HiddenFeeService_GetConfigOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.ListHiddenFeeReq,
 *   !proto.skyhub.web_partnership.ListHiddenFeeRes>}
 */
const methodDescriptor_HiddenFeeService_ListHiddenFee = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.HiddenFeeService/ListHiddenFee',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.ListHiddenFeeReq,
  proto.skyhub.web_partnership.ListHiddenFeeRes,
  /**
   * @param {!proto.skyhub.web_partnership.ListHiddenFeeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.ListHiddenFeeRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.ListHiddenFeeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.ListHiddenFeeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.ListHiddenFeeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.HiddenFeeServiceClient.prototype.listHiddenFee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.HiddenFeeService/ListHiddenFee',
      request,
      metadata || {},
      methodDescriptor_HiddenFeeService_ListHiddenFee,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.ListHiddenFeeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.ListHiddenFeeRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.HiddenFeeServicePromiseClient.prototype.listHiddenFee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.HiddenFeeService/ListHiddenFee',
      request,
      metadata || {},
      methodDescriptor_HiddenFeeService_ListHiddenFee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.DetailHiddenFeeReq,
 *   !proto.skyhub.web_partnership.DetailHiddenFeeRes>}
 */
const methodDescriptor_HiddenFeeService_DetailHiddenFee = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.HiddenFeeService/DetailHiddenFee',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.DetailHiddenFeeReq,
  proto.skyhub.web_partnership.DetailHiddenFeeRes,
  /**
   * @param {!proto.skyhub.web_partnership.DetailHiddenFeeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.DetailHiddenFeeRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.DetailHiddenFeeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.DetailHiddenFeeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.DetailHiddenFeeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.HiddenFeeServiceClient.prototype.detailHiddenFee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.HiddenFeeService/DetailHiddenFee',
      request,
      metadata || {},
      methodDescriptor_HiddenFeeService_DetailHiddenFee,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.DetailHiddenFeeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.DetailHiddenFeeRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.HiddenFeeServicePromiseClient.prototype.detailHiddenFee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.HiddenFeeService/DetailHiddenFee',
      request,
      metadata || {},
      methodDescriptor_HiddenFeeService_DetailHiddenFee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.CreateHiddenFeeReq,
 *   !proto.skyhub.web_partnership.CreateHiddenFeeRes>}
 */
const methodDescriptor_HiddenFeeService_CreateHiddenFee = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.HiddenFeeService/CreateHiddenFee',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.CreateHiddenFeeReq,
  proto.skyhub.web_partnership.CreateHiddenFeeRes,
  /**
   * @param {!proto.skyhub.web_partnership.CreateHiddenFeeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.CreateHiddenFeeRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.CreateHiddenFeeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.CreateHiddenFeeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.CreateHiddenFeeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.HiddenFeeServiceClient.prototype.createHiddenFee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.HiddenFeeService/CreateHiddenFee',
      request,
      metadata || {},
      methodDescriptor_HiddenFeeService_CreateHiddenFee,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.CreateHiddenFeeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.CreateHiddenFeeRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.HiddenFeeServicePromiseClient.prototype.createHiddenFee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.HiddenFeeService/CreateHiddenFee',
      request,
      metadata || {},
      methodDescriptor_HiddenFeeService_CreateHiddenFee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.UpdateHiddenFeeReq,
 *   !proto.skyhub.web_partnership.UpdateHiddenFeeRes>}
 */
const methodDescriptor_HiddenFeeService_UpdateHiddenFee = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.HiddenFeeService/UpdateHiddenFee',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.UpdateHiddenFeeReq,
  proto.skyhub.web_partnership.UpdateHiddenFeeRes,
  /**
   * @param {!proto.skyhub.web_partnership.UpdateHiddenFeeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.UpdateHiddenFeeRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.UpdateHiddenFeeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.UpdateHiddenFeeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.UpdateHiddenFeeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.HiddenFeeServiceClient.prototype.updateHiddenFee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.HiddenFeeService/UpdateHiddenFee',
      request,
      metadata || {},
      methodDescriptor_HiddenFeeService_UpdateHiddenFee,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.UpdateHiddenFeeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.UpdateHiddenFeeRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.HiddenFeeServicePromiseClient.prototype.updateHiddenFee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.HiddenFeeService/UpdateHiddenFee',
      request,
      metadata || {},
      methodDescriptor_HiddenFeeService_UpdateHiddenFee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.DeleteHiddenFeeReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_HiddenFeeService_DeleteHiddenFee = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.HiddenFeeService/DeleteHiddenFee',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.DeleteHiddenFeeReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.skyhub.web_partnership.DeleteHiddenFeeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.DeleteHiddenFeeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.HiddenFeeServiceClient.prototype.deleteHiddenFee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.HiddenFeeService/DeleteHiddenFee',
      request,
      metadata || {},
      methodDescriptor_HiddenFeeService_DeleteHiddenFee,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.DeleteHiddenFeeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.HiddenFeeServicePromiseClient.prototype.deleteHiddenFee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.HiddenFeeService/DeleteHiddenFee',
      request,
      metadata || {},
      methodDescriptor_HiddenFeeService_DeleteHiddenFee);
};


module.exports = proto.skyhub.web_partnership;

