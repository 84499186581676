import { useUserStore } from '@vemaybay-admin/hooks/stores';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export const AuthLayout = () => {
  const navigate = useNavigate();
  const { isLogin, isFetching } = useUserStore();

  useEffect(() => {
    if (!isFetching && isLogin) {
      navigate('/');
    }
  }, [isLogin, isFetching]);

  return (
    <div className='w-screen h-screen items-center flex justify-center bg-[#fbf8f8]'>
      <div className='space-y-7'>
        <div>
          <Outlet />
        </div>
        <div className='text-sm text-center text-neutral-8'>
          © {new Date().getFullYear()} vemaybay.
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
