import { BasicRes, Empty } from '@api/base/base_pb';
import { PartnerShopServiceClient } from '@api/partner/web_partner/partner_shop_grpc_web_pb';
import {
  GetOfficeRes,
  UpdateOfficeReq,
} from '@api/partner/web_partner/partner_shop_pb';

import { BaseApi, BaseApiOptions } from '@core/grpc-client';
class PartnerShopApi extends BaseApi<PartnerShopServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new PartnerShopServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  getOffice = (): Promise<GetOfficeRes.AsObject> => {
    const req = new Empty();
    return this.grpc<Empty, GetOfficeRes, GetOfficeRes.AsObject>(
      this.serviceClient.getOffice,
      req,
      {}
    );
  };

  updateOffice = ({
    email,
    phone,
    name,
    shopName,
    status,
    logo,
  }: UpdateOfficeReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new UpdateOfficeReq();
    req.setEmail(email);
    req.setPhone(phone);
    req.setName(name);
    req.setShopName(shopName);
    req.setStatus(status);
    logo && req.setLogo(logo);
    return this.grpc<UpdateOfficeReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.updateOffice,
      req,
      {}
    );
  };
}

export const partnerShopClient = new PartnerShopApi();
export default partnerShopClient;
