/**
 * @fileoverview gRPC-Web generated client stub for partner.web_partner
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: partner/web_partner/partner_agent_group.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var partner_base_pb = require('../../partner/base_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.partner = {};
proto.partner.web_partner = require('./partner_agent_group_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.partner.web_partner.PartnerAgentGroupServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.partner.web_partner.PartnerAgentGroupServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner.CreateAgentGroupReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_PartnerAgentGroupService_CreateAgentGroup = new grpc.web.MethodDescriptor(
  '/partner.web_partner.PartnerAgentGroupService/CreateAgentGroup',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner.CreateAgentGroupReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.partner.web_partner.CreateAgentGroupReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner.CreateAgentGroupReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner.PartnerAgentGroupServiceClient.prototype.createAgentGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner.PartnerAgentGroupService/CreateAgentGroup',
      request,
      metadata || {},
      methodDescriptor_PartnerAgentGroupService_CreateAgentGroup,
      callback);
};


/**
 * @param {!proto.partner.web_partner.CreateAgentGroupReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner.PartnerAgentGroupServicePromiseClient.prototype.createAgentGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner.PartnerAgentGroupService/CreateAgentGroup',
      request,
      metadata || {},
      methodDescriptor_PartnerAgentGroupService_CreateAgentGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner.UpdateAgentGroupReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_PartnerAgentGroupService_UpdateAgentGroup = new grpc.web.MethodDescriptor(
  '/partner.web_partner.PartnerAgentGroupService/UpdateAgentGroup',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner.UpdateAgentGroupReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.partner.web_partner.UpdateAgentGroupReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner.UpdateAgentGroupReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner.PartnerAgentGroupServiceClient.prototype.updateAgentGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner.PartnerAgentGroupService/UpdateAgentGroup',
      request,
      metadata || {},
      methodDescriptor_PartnerAgentGroupService_UpdateAgentGroup,
      callback);
};


/**
 * @param {!proto.partner.web_partner.UpdateAgentGroupReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner.PartnerAgentGroupServicePromiseClient.prototype.updateAgentGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner.PartnerAgentGroupService/UpdateAgentGroup',
      request,
      metadata || {},
      methodDescriptor_PartnerAgentGroupService_UpdateAgentGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner.GetAgentGroupsReq,
 *   !proto.partner.web_partner.GetAgentGroupsRes>}
 */
const methodDescriptor_PartnerAgentGroupService_GetAgentGroups = new grpc.web.MethodDescriptor(
  '/partner.web_partner.PartnerAgentGroupService/GetAgentGroups',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner.GetAgentGroupsReq,
  proto.partner.web_partner.GetAgentGroupsRes,
  /**
   * @param {!proto.partner.web_partner.GetAgentGroupsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partner.GetAgentGroupsRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner.GetAgentGroupsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partner.GetAgentGroupsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partner.GetAgentGroupsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner.PartnerAgentGroupServiceClient.prototype.getAgentGroups =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner.PartnerAgentGroupService/GetAgentGroups',
      request,
      metadata || {},
      methodDescriptor_PartnerAgentGroupService_GetAgentGroups,
      callback);
};


/**
 * @param {!proto.partner.web_partner.GetAgentGroupsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partner.GetAgentGroupsRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner.PartnerAgentGroupServicePromiseClient.prototype.getAgentGroups =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner.PartnerAgentGroupService/GetAgentGroups',
      request,
      metadata || {},
      methodDescriptor_PartnerAgentGroupService_GetAgentGroups);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner.GetAgentGroupDetailReq,
 *   !proto.partner.web_partner.GetAgentGroupDetailRes>}
 */
const methodDescriptor_PartnerAgentGroupService_GetAgentGroupDetail = new grpc.web.MethodDescriptor(
  '/partner.web_partner.PartnerAgentGroupService/GetAgentGroupDetail',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner.GetAgentGroupDetailReq,
  proto.partner.web_partner.GetAgentGroupDetailRes,
  /**
   * @param {!proto.partner.web_partner.GetAgentGroupDetailReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partner.GetAgentGroupDetailRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner.GetAgentGroupDetailReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partner.GetAgentGroupDetailRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partner.GetAgentGroupDetailRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner.PartnerAgentGroupServiceClient.prototype.getAgentGroupDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner.PartnerAgentGroupService/GetAgentGroupDetail',
      request,
      metadata || {},
      methodDescriptor_PartnerAgentGroupService_GetAgentGroupDetail,
      callback);
};


/**
 * @param {!proto.partner.web_partner.GetAgentGroupDetailReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partner.GetAgentGroupDetailRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner.PartnerAgentGroupServicePromiseClient.prototype.getAgentGroupDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner.PartnerAgentGroupService/GetAgentGroupDetail',
      request,
      metadata || {},
      methodDescriptor_PartnerAgentGroupService_GetAgentGroupDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner.DepositGroupBalanceReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_PartnerAgentGroupService_DepositGroupBalance = new grpc.web.MethodDescriptor(
  '/partner.web_partner.PartnerAgentGroupService/DepositGroupBalance',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner.DepositGroupBalanceReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.partner.web_partner.DepositGroupBalanceReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner.DepositGroupBalanceReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner.PartnerAgentGroupServiceClient.prototype.depositGroupBalance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner.PartnerAgentGroupService/DepositGroupBalance',
      request,
      metadata || {},
      methodDescriptor_PartnerAgentGroupService_DepositGroupBalance,
      callback);
};


/**
 * @param {!proto.partner.web_partner.DepositGroupBalanceReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner.PartnerAgentGroupServicePromiseClient.prototype.depositGroupBalance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner.PartnerAgentGroupService/DepositGroupBalance',
      request,
      metadata || {},
      methodDescriptor_PartnerAgentGroupService_DepositGroupBalance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partner.GetGroupTransactionHistoriesReq,
 *   !proto.partner.web_partner.GetGroupTransactionHistoriesRes>}
 */
const methodDescriptor_PartnerAgentGroupService_GetGroupTransactionHistories = new grpc.web.MethodDescriptor(
  '/partner.web_partner.PartnerAgentGroupService/GetGroupTransactionHistories',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partner.GetGroupTransactionHistoriesReq,
  proto.partner.web_partner.GetGroupTransactionHistoriesRes,
  /**
   * @param {!proto.partner.web_partner.GetGroupTransactionHistoriesReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partner.GetGroupTransactionHistoriesRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partner.GetGroupTransactionHistoriesReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partner.GetGroupTransactionHistoriesRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partner.GetGroupTransactionHistoriesRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partner.PartnerAgentGroupServiceClient.prototype.getGroupTransactionHistories =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partner.PartnerAgentGroupService/GetGroupTransactionHistories',
      request,
      metadata || {},
      methodDescriptor_PartnerAgentGroupService_GetGroupTransactionHistories,
      callback);
};


/**
 * @param {!proto.partner.web_partner.GetGroupTransactionHistoriesReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partner.GetGroupTransactionHistoriesRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partner.PartnerAgentGroupServicePromiseClient.prototype.getGroupTransactionHistories =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partner.PartnerAgentGroupService/GetGroupTransactionHistories',
      request,
      metadata || {},
      methodDescriptor_PartnerAgentGroupService_GetGroupTransactionHistories);
};


module.exports = proto.partner.web_partner;

