import React, { forwardRef } from 'react';
import cn from 'classnames';
import { useCurrency, useTime } from '@vemaybay-admin/hooks/internals';
import { useCountry } from '@vemaybay-admin/hooks/apps';
import {
  IBookingDetail,
} from '@tixlabs/grpc-client/web-partnership';
import { Rate } from 'antd';

type Props = {
  className?: string;
  bookingData: IBookingDetail;
  orderCode: string;
};

export const PrintReceiptHotel = forwardRef<HTMLDivElement, Props>(
  ({ className, bookingData, orderCode }, ref) => {
    // const { userData } = useUserStore();

    // const { partnerShopBasicInfo, handleGetPartnerShopBasicInfo } =
    //   usePartnerShopInfo();

    // useMemo(() => {
    //   handleGetPartnerShopBasicInfo(userData?.partnerShopId || '');
    // }, [userData?.partnerShopId]);

    const { formatPrice, formatCurrency, formatCurrencyHaveDecimal } = useCurrency();


    const { formatTimeDate, formatDateDayMonthYear } = useTime();
    const countryCode = bookingData.hotelInfo?.address?.countryCode;
    const { getCountryByCode } = useCountry();
    const commission =
      bookingData?.priceInfo?.commission?.adjustmentType !== 0
        ? bookingData?.priceInfo?.commission?.adjustmentType === 1
          ? (bookingData?.priceInfo?.commission?.value *
            bookingData?.priceInfo?.payNow) /
          100
          : bookingData?.priceInfo?.commission?.value
        : 0;

    const discount =
      bookingData?.priceInfo?.discount?.adjustmentType !== 0
        ? bookingData?.priceInfo?.discount?.adjustmentType === 1
          ? (bookingData?.priceInfo?.discount?.value *
            (bookingData?.priceInfo?.payNow + (commission || 0))) /
          100
          : bookingData?.priceInfo?.discount?.value
        : 0;

    const averageRate = bookingData?.priceInfo?.totalRateAmount
      ? (bookingData.priceInfo.totalRateAmount) /
      ((bookingData.roomsList.length || 1) * (bookingData.totalNights || 1))
      : 0;

    // have commission
    // const averageRate = bookingData?.priceInfo?.totalRateAmount
    //   ? (bookingData.priceInfo.totalRateAmount + (commission || 0)) /
    //   ((bookingData.roomsList.length || 1) * (bookingData.totalNights || 1))
    //   : 0;

    const countryName = countryCode
      ? getCountryByCode(countryCode)?.country
      : 'Unknown Country';

    return (
      <div className={cn('flex flex-col bg-white ', className)} ref={ref}>
        {/* Header */}
        <div className='bg-[#00264E] flex items-center justify-between gap-2 bg-header px-5 py-6'>
          <img
            style={{ width: '106px', height: '28px' }}
            src='assets/images/logo-vemaybay_white.png'
            alt='logo'
          />
          <div className='text-white text-end'>
            {/* <div className='text-neutral-1 mb-3'>
              {partnerShopBasicInfo?.name}
            </div> */}
            <div className='text-neutral-1 text-base'>
              Hotline: (+84) 932 126 988
            </div>
            <div className='text-neutral-1 text-base'>team@vemaybay.vn</div>
          </div>
        </div>

        {/* Title */}
        <div className='flex flex-col gap-2 bg-[#F2F9FA] px-4 py-3'>
          <div className='text-lg font-semibold'>XÁC NHẬN THANH TOÁN</div>
          <div className='text-base'>
            Mã đơn hàng: <strong>{orderCode}</strong>
          </div>
          <div className='text-base'>
            Ngày đặt: <strong>{formatTimeDate(bookingData?.createdAt)}</strong>
          </div>
        </div>

        {/* Hotel Information */}
        <div className='border-neutral-200 px-4 py-3 flex flex-col gap-[6px]'>
          <div className='text-lg font-semibold'>
            {bookingData.hotelInfo?.name}
          </div>

          <Rate
            style={{ fontSize: 14 }}
            className='custome-star'
            disabled
            allowHalf
            defaultValue={bookingData.hotelInfo?.rating || 0}
          />

          <div className='text-base font-medium'>
            Địa chỉ:{' '}
            {[
              bookingData.hotelInfo?.address?.line1,
              bookingData.hotelInfo?.address?.line2,
              bookingData.hotelInfo?.address?.city,
              countryName,
            ]
              .filter((item) => item)
              .join(', ')}{' '}
          </div>

          <div className='flex gap-1 text-base font-medium'>
            <span>Số điện thoại</span>
            <span>
              {bookingData.roomsList[0]?.holder?.phoneCode && bookingData.roomsList[0]?.holder?.phoneNumber
                ? `(${bookingData.roomsList[0]?.holder?.phoneCode}) ${bookingData.roomsList[0]?.holder?.phoneNumber}`
                : null}
            </span>
          </div>
        </div>

        {/* Booking Information */}
        {/* <div className='border-b border-neutral-200 px-4 py-3'>
          <h4 className='mb-2 text-md font-semibold'>Thông tin đặt phòng</h4>

          <div className='flex flex-col gap-4 text-base'>
            <div className='flex '>
              <div className='w-1/3 text-custom-gray sm:w-1/4'>Đặt phòng:</div>
              <div className='w-2/3 font-semibold sm:w-3/4'>
                {`${bookingData?.roomsList.length} phòng, ${bookingData?.totalNights} đêm`}{' '}
              </div>
            </div>

            <div className='flex '>
              <div className='w-1/3 text-custom-gray sm:w-1/4'>Loại phòng:</div>
              <div className='w-2/3 font-semibold sm:w-3/4'>
                {bookingData.roomsList[0]?.name}
              </div>
            </div>

            <div className='flex'>
              <div className='w-1/3 text-custom-gray sm:w-1/4'>
                Loại giường:
              </div>
              <div className='w-2/3 font-semibold sm:w-3/4'>
                {bookingData.roomsList[0]?.bedOption?.name || ''}
              </div>
            </div>

            <div className='flex '>
              <div className='w-1/3 text-custom-gray sm:w-1/4'>Nhận phòng:</div>
              <div className='flex gap-3'>
                <span className='col-span-10 font-semibold sm:col-span-4 md:col-span-3'>
                  {bookingData.checkInDate &&
                    bookingData.checkInDate !== '' &&
                    formatDateDayMonthYear(bookingData.checkInDate)}
                </span>
                <span className='col-span-5 font-normal text-custom-gray sm:col-span-6 md:col-span-2'>
                  {bookingData.checkInTime && `Từ ${bookingData.checkInTime}`}
                </span>
              </div>
            </div>

            <div className='flex'>
              <div className='w-1/3 text-custom-gray sm:w-1/4'>Trả phòng:</div>
              <div className='flex gap-3'>
                <span className='col-span-10 font-semibold sm:col-span-4 md:col-span-3'>
                  {bookingData.checkOutDate &&
                    bookingData.checkOutDate !== '' &&
                    formatDateDayMonthYear(bookingData.checkOutDate)}
                </span>
                <span className='col-span-5 font-normal text-custom-gray sm:col-span-6 md:col-span-2'>
                  {bookingData.checkOutTime &&
                    `Đến ${bookingData.checkOutTime}`}
                </span>
              </div>
            </div>

            <div className='flex'>
              <div className='w-1/3 text-custom-gray  sm:w-1/4'>
                Đặt chỗ cho:
              </div>
              <div className='w-2/3 font-semibold sm:w-3/4'>
                <div className='text-base font-semibold'>
                  {bookingData.roomsList &&
                    (() => {
                      const totals = bookingData.roomsList.reduce(
                        (acc, room) => {
                          acc.adt += room?.occupancy?.adt || 0;
                          acc.chd += room?.occupancy?.chd || 0;
                          return acc;
                        },
                        { adt: 0, chd: 0 }
                      );

                      const displayText = [
                        totals.adt > 0 ? `${totals.adt} người lớn` : '',
                        totals.chd > 0 ? `${totals.chd} trẻ em` : '',
                      ]

                        .filter((item) => item)
                        .join(', ');

                      return displayText;
                    })()}
                </div>
              </div>
            </div>
          </div>

          {bookingData.roomsList.map((room, index) => (
            <div key={index} className='flex flex-col gap-3'>
              <h4 className='mb-2 mt-4 text-md font-semibold'>
                Phòng {index + 1}: {room.name}
              </h4>

              <div className='flex flex-col gap-4 text-base'>
                <div className='flex'>
                  <div className='w-1/3 text-custom-gray sm:w-1/4'>
                    Tên khách:
                  </div>
                  <div className='w-2/3 font-semibold sm:w-3/4'>
                    {room.holder?.surname} {room.holder?.givenName}
                  </div>
                </div>

                <div className='flex'>
                  <div className='w-1/3 text-custom-gray  sm:w-1/4'>
                    Mã đặt chỗ:
                  </div>
                  <div className='w-2/3 font-semibold sm:w-3/4'>
                    {room.confirmationId}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div> */}

        {/* Payment Information */}
        <div className='border-b border-neutral-200 px-4 pt-3 pb-4'>
          <h4 className='mb-2 text-lg font-semibold'>Thông tin thanh toán</h4>

          <h4 className='mb-2 text-md font-semibold'>Chi tiết giá</h4>

          <div className='flex flex-col gap-[6px] text-[15px]'>
            <div className='flex justify-between'>
              <span>{`${bookingData.roomsList.length} phòng x ${bookingData.totalNights} đêm`}</span>
              {formatCurrency(
                (bookingData?.priceInfo?.totalRateAmount || 0)
                // + (commission || 0)
              )}{' '}
            </div>
            <div className='flex justify-between'>
              <div className='text-custom-gray'>
                {formatPrice(averageRate)} ₫/đêm
              </div>
            </div>
            <div className='flex justify-between'>
              <div>Thuế & phí</div>
              <div>
                {formatCurrency(
                  bookingData?.priceInfo?.totalTaxesAndFees || 0
                )}
              </div>
            </div>

            <div className='flex justify-between'>
              <span>Phí tiện ích</span>
              <span>{formatCurrency(bookingData?.paymentInfo?.feeAmount || 0)}</span>
            </div>

            {/* tạm ẩn --> BA  (21/01/2025 Huyền) */}
            {/* {<div className='flex justify-between'>
              <div>Giảm giá</div>
              <div>{formatCurrency(discount || 0)}</div>
            </div>} */}

          </div>
        </div>

        {/* Total Payment */}
        <div className='pt-4 pb-3 px-4'>
          <div className='flex justify-between font-semibold'>
            <div className='text-lg'>Đã thanh toán</div>
            <div className='text-2xl'>{bookingData?.paymentInfo?.totalPrice
              ? formatCurrency(bookingData?.paymentInfo.totalPrice || 0)
              : formatCurrency(bookingData?.priceInfo?.payNow || 0, bookingData?.priceInfo?.currency)}</div>
          </div>

          <div className=' flex justify-between text-base'>
            <div>Phương thức thanh toán</div>
            <div>{bookingData?.paymentInfo?.method?.name}</div>
          </div>

          {(bookingData?.priceInfo?.totalPayAtHotel && bookingData?.priceInfo?.totalPayAtHotel > 0)
            ? <>
              <div className='mt-3 mb-1 flex justify-between text-[15px]'>
                <div>Thanh toán tại nơi lưu trú</div>
                <div>
                  {`${formatCurrencyHaveDecimal(bookingData?.priceInfo.totalPayAtHotel,
                    bookingData?.priceInfo.payAtHotelCurrency)}`}
                </div>
              </div>

              <div className='flex flex-col gap-1 text-[15px] text-custom-gray'>
                {bookingData?.priceInfo?.payAtHotelList?.map((item, index) => (
                  <div key={index} className='flex justify-between'>
                    <span>{item.description}</span>
                    <span>
                      {`${formatCurrencyHaveDecimal(item.amount, item.currency)}`}
                    </span>
                  </div>
                ))}
              </div>
            </>
            : null}

          {/* <div className='flex justify-between text-[15px]'>
            <span>Tổng thanh toán</span>
            <span>{formatCurrency(bookingData?.priceInfo?.totalPrice || 0)}</span>
          </div> */}
        </div>

        {/* Footer Note */}
        <div className='p-3 text-sm bg-[#F2F2F2]'>
          <i>Hóa đơn này được chiết xuất tự động dành cho khách hàng.</i>
        </div>
      </div>
    );
  }
);

PrintReceiptHotel.displayName = 'PrintReceiptHotel';

export default PrintReceiptHotel;
