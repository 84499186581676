import { Breadcrumb } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StatusOrder } from './StatusOrder';
import {
  IRetrieveBookingFlightData,
  flightApiService,
} from '@tixlabs/grpc-client/web-partnership';
import { useAppMutation } from '@vemaybay-admin/hooks/internals';
import { LoadingLogoFullPage } from '@common-ui';
import {
  ContactInfo,
  InvoiceInfoView,
  ItineraryList,
  PassengerInfoList,
  PaymentInfo,
} from '@vemaybay-admin/components/Flight';
import useAirport from '@vemaybay-admin/hooks/stores/useAirport';

export const DetailOrder = () => {
  const { bookingCode } = useParams();
  const { fetchAirports } = useAirport();
  const [bookingFlight, setBookingFlight] =
    useState<IRetrieveBookingFlightData>();
  const navigate = useNavigate();
  
  const { mutateAsync: retrieveBookingFlight, isLoading } = useAppMutation({
    mutationKey: ['flightApiService', 'retrieveBookingFlight'],
    mutationFn: flightApiService.retrieveBookingFlight,
    onSuccess: async ({ isSuccess, data, errorCode }) => {
      if (isSuccess && data) {
        // filter order has itinerariesList empty
        setBookingFlight(data);
        const tempSetListAirport = new Set<string>();
        data.itinerariesList.forEach((itinerary) => {
          itinerary.segmentsList.forEach((segment) => {
            tempSetListAirport.add(segment.departPlace);
            tempSetListAirport.add(segment.arrivalPlace);
          });
        });
        await fetchAirports([...Array.from(tempSetListAirport)]);
      } else {
        console.log('error', errorCode);
      }
    },
    onError: (err) => {
      console.log('oops...', err);
    },
  });

  const handleRefundBookingSuccess = async () => {
    await getBookingFlightInfo();
  };

  const getBookingFlightInfo = useCallback(async () => {
    await retrieveBookingFlight({
      bookingCode: bookingCode || '',
    });
  }, []);

  useEffect(() => {
    getBookingFlightInfo();
  }, []);

  return isLoading ? (
    <LoadingLogoFullPage />
  ) : (
    <div className='flex flex-col gap-2.5'>
      <div className='flex flex-col gap-1 5 py-3'>
        <h3 className='text-xl font-bold text-neutral-black mb-0'>
          Chi tiết đặt chỗ
        </h3>
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className='text-primary-6 cursor-pointer hover:text-primary-5'
                  onClick={() => {
                    navigate('/');
                  }}>
                  Trang chủ
                </span>
              ),
            },
            {
              title: (
                <span
                  className='text-primary-6 font-semibold cursor-pointer hover:text-primary-5'
                  onClick={() => {
                    navigate('/order-management');
                  }}>
                  Quản lý đặt chỗ
                </span>
              ),
            },
          ]}
        />
      </div>
      <div className='flex flex-col gap-2 lg:max-w-[70%]'>
        {bookingFlight && (
          <>
            <StatusOrder
              bookingFlight={bookingFlight}
              handleRefundBookingSuccess={handleRefundBookingSuccess}
            />
            <ItineraryList bookingFlight={bookingFlight} />
            <PassengerInfoList bookingFlight={bookingFlight} />
            <ContactInfo bookingFlight={bookingFlight} />

            {bookingFlight.invoicingInformation && (
              <InvoiceInfoView data={bookingFlight.invoicingInformation} />
            )}

            <PaymentInfo bookingFlight={bookingFlight} />
          </>
        )}
      </div>
    </div>
  );
};
