export const CURRENCY_DEFAULT = 'VND';

export const getPayAtHotelPrice = (
  formatCurrency: (value: number) => string,
  payAtHotel = 0,
  currency = ''
) => {
  if (currency === CURRENCY_DEFAULT || payAtHotel === 0) {
    return formatCurrency(payAtHotel);
  } else {
    return payAtHotel + ' ' + currency;
  }
};
