import { IRetrieveBookingFlightData } from '@tixlabs/grpc-client/web-partnership';
import { ICustomerDetailInfo, IPaginationRes } from '@tixlabs/grpc-client/';
import TableContent from '@vemaybay-admin/containers/OrderManagement/TableContent';

type Props = {
  customerInfo: ICustomerDetailInfo;
  listOrder: IRetrieveBookingFlightData[];
  isLoading?: boolean;
  pagination?: IPaginationRes;
  handlePagination?: (page: number) => void;
};

export const RecentBooking = ({
  listOrder,
  pagination,
  isLoading = false,
  handlePagination,
}: Props) => {
  return (
    <div className='flex flex-col rounded-lg bg-white'>
      <h3 className='text-lg font-semibold mb-0 py-3 px-4'>Đặt chỗ gần đây</h3>
      <div className='px-4 py-3'>
        <TableContent
          listOrder={listOrder}
          isLoading={isLoading}
          pagination={pagination}
          onPageChange={handlePagination}
        />
      </div>
    </div>
  );
};
