// source: partner/web_partnership/partner.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var partner_base_pb = require('../../partner/base_pb.js');
goog.object.extend(proto, partner_base_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var base_enum_pb = require('../../base/enum_pb.js');
goog.object.extend(proto, base_enum_pb);
var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
goog.exportSymbol('proto.partner.web_partnership.ChangeOfficePasswordReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.ChangeOfficePasswordRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.CreateOfficeReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.CreateOfficeRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.CreatePartnerReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.CreatePartnerRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.DCPAmadeus', null, global);
goog.exportSymbol('proto.partner.web_partnership.DCPEV', null, global);
goog.exportSymbol('proto.partner.web_partnership.DCPInternationalEV', null, global);
goog.exportSymbol('proto.partner.web_partnership.DCPTravelFusion', null, global);
goog.exportSymbol('proto.partner.web_partnership.DCPVietjetAir', null, global);
goog.exportSymbol('proto.partner.web_partnership.DCPVietnamAirlines', null, global);
goog.exportSymbol('proto.partner.web_partnership.GetDomainsReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.GetDomainsRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.GetOfficeDetailReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.GetOfficeDetailRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.GetOfficesRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.GetOfficesRes.OfficeInfo', null, global);
goog.exportSymbol('proto.partner.web_partnership.GetProvidersReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.GetProvidersRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.GetUnassignedOfficesReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.GetUnassignedOfficesRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.ListMyOfficesReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.ListMyOfficesReq.Filter', null, global);
goog.exportSymbol('proto.partner.web_partnership.ListMyOfficesRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.Partner', null, global);
goog.exportSymbol('proto.partner.web_partnership.PartnerType', null, global);
goog.exportSymbol('proto.partner.web_partnership.UpdateOfficeHotelInfoReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.UpdateOfficeHotelInfoRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.UpdateOfficeProvidersReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.UpdateOfficeProvidersRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.UpdateOfficeReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.UpdateOfficeRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.WebHook', null, global);
goog.exportSymbol('proto.partner.web_partnership.WebhookURLConfig', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.ChangeOfficePasswordRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.ChangeOfficePasswordRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.ChangeOfficePasswordRes.displayName = 'proto.partner.web_partnership.ChangeOfficePasswordRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.ChangeOfficePasswordReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.ChangeOfficePasswordReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.ChangeOfficePasswordReq.displayName = 'proto.partner.web_partnership.ChangeOfficePasswordReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.UpdateOfficeProvidersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partnership.UpdateOfficeProvidersReq.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partnership.UpdateOfficeProvidersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.UpdateOfficeProvidersReq.displayName = 'proto.partner.web_partnership.UpdateOfficeProvidersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.UpdateOfficeProvidersRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.UpdateOfficeProvidersRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.UpdateOfficeProvidersRes.displayName = 'proto.partner.web_partnership.UpdateOfficeProvidersRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.CreateOfficeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.CreateOfficeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.CreateOfficeRes.displayName = 'proto.partner.web_partnership.CreateOfficeRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.UpdateOfficeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.UpdateOfficeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.UpdateOfficeRes.displayName = 'proto.partner.web_partnership.UpdateOfficeRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.DCPTravelFusion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.DCPTravelFusion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.DCPTravelFusion.displayName = 'proto.partner.web_partnership.DCPTravelFusion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.DCPAmadeus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.DCPAmadeus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.DCPAmadeus.displayName = 'proto.partner.web_partnership.DCPAmadeus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.DCPVietnamAirlines = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.DCPVietnamAirlines, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.DCPVietnamAirlines.displayName = 'proto.partner.web_partnership.DCPVietnamAirlines';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.DCPInternationalEV = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.DCPInternationalEV, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.DCPInternationalEV.displayName = 'proto.partner.web_partnership.DCPInternationalEV';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.DCPEV = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.DCPEV, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.DCPEV.displayName = 'proto.partner.web_partnership.DCPEV';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.DCPVietjetAir = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.DCPVietjetAir, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.DCPVietjetAir.displayName = 'proto.partner.web_partnership.DCPVietjetAir';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.GetDomainsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.GetDomainsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.GetDomainsReq.displayName = 'proto.partner.web_partnership.GetDomainsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.GetProvidersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.GetProvidersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.GetProvidersReq.displayName = 'proto.partner.web_partnership.GetProvidersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.GetUnassignedOfficesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.GetUnassignedOfficesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.GetUnassignedOfficesReq.displayName = 'proto.partner.web_partnership.GetUnassignedOfficesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.CreateOfficeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partnership.CreateOfficeReq.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partnership.CreateOfficeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.CreateOfficeReq.displayName = 'proto.partner.web_partnership.CreateOfficeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.UpdateOfficeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.UpdateOfficeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.UpdateOfficeReq.displayName = 'proto.partner.web_partnership.UpdateOfficeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.ListMyOfficesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.ListMyOfficesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.ListMyOfficesReq.displayName = 'proto.partner.web_partnership.ListMyOfficesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.ListMyOfficesReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.ListMyOfficesReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.ListMyOfficesReq.Filter.displayName = 'proto.partner.web_partnership.ListMyOfficesReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.GetOfficeDetailReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.GetOfficeDetailReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.GetOfficeDetailReq.displayName = 'proto.partner.web_partnership.GetOfficeDetailReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.CreatePartnerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partnership.CreatePartnerReq.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partnership.CreatePartnerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.CreatePartnerReq.displayName = 'proto.partner.web_partnership.CreatePartnerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.WebhookURLConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.WebhookURLConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.WebhookURLConfig.displayName = 'proto.partner.web_partnership.WebhookURLConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.WebHook = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.WebHook, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.WebHook.displayName = 'proto.partner.web_partnership.WebHook';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.GetDomainsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partnership.GetDomainsRes.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partnership.GetDomainsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.GetDomainsRes.displayName = 'proto.partner.web_partnership.GetDomainsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.GetProvidersRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partnership.GetProvidersRes.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partnership.GetProvidersRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.GetProvidersRes.displayName = 'proto.partner.web_partnership.GetProvidersRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.GetUnassignedOfficesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partnership.GetUnassignedOfficesRes.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partnership.GetUnassignedOfficesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.GetUnassignedOfficesRes.displayName = 'proto.partner.web_partnership.GetUnassignedOfficesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.ListMyOfficesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partnership.ListMyOfficesRes.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partnership.ListMyOfficesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.ListMyOfficesRes.displayName = 'proto.partner.web_partnership.ListMyOfficesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.GetOfficeDetailRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.GetOfficeDetailRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.GetOfficeDetailRes.displayName = 'proto.partner.web_partnership.GetOfficeDetailRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.Partner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.Partner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.Partner.displayName = 'proto.partner.web_partnership.Partner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.CreatePartnerRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.CreatePartnerRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.CreatePartnerRes.displayName = 'proto.partner.web_partnership.CreatePartnerRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.GetOfficesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partnership.GetOfficesRes.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partnership.GetOfficesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.GetOfficesRes.displayName = 'proto.partner.web_partnership.GetOfficesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partnership.GetOfficesRes.OfficeInfo.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partnership.GetOfficesRes.OfficeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.GetOfficesRes.OfficeInfo.displayName = 'proto.partner.web_partnership.GetOfficesRes.OfficeInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partnership.UpdateOfficeHotelInfoReq.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partnership.UpdateOfficeHotelInfoReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.UpdateOfficeHotelInfoReq.displayName = 'proto.partner.web_partnership.UpdateOfficeHotelInfoReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.UpdateOfficeHotelInfoRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.UpdateOfficeHotelInfoRes.displayName = 'proto.partner.web_partnership.UpdateOfficeHotelInfoRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.ChangeOfficePasswordRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.ChangeOfficePasswordRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.ChangeOfficePasswordRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ChangeOfficePasswordRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.ChangeOfficePasswordRes}
 */
proto.partner.web_partnership.ChangeOfficePasswordRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.ChangeOfficePasswordRes;
  return proto.partner.web_partnership.ChangeOfficePasswordRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.ChangeOfficePasswordRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.ChangeOfficePasswordRes}
 */
proto.partner.web_partnership.ChangeOfficePasswordRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.ChangeOfficePasswordRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.ChangeOfficePasswordRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.ChangeOfficePasswordRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ChangeOfficePasswordRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partnership.ChangeOfficePasswordRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.ChangeOfficePasswordRes} returns this
 */
proto.partner.web_partnership.ChangeOfficePasswordRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partnership.ChangeOfficePasswordRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ChangeOfficePasswordRes} returns this
 */
proto.partner.web_partnership.ChangeOfficePasswordRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.ChangeOfficePasswordReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.ChangeOfficePasswordReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.ChangeOfficePasswordReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ChangeOfficePasswordReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    newPassword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shopId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.ChangeOfficePasswordReq}
 */
proto.partner.web_partnership.ChangeOfficePasswordReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.ChangeOfficePasswordReq;
  return proto.partner.web_partnership.ChangeOfficePasswordReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.ChangeOfficePasswordReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.ChangeOfficePasswordReq}
 */
proto.partner.web_partnership.ChangeOfficePasswordReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.ChangeOfficePasswordReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.ChangeOfficePasswordReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.ChangeOfficePasswordReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ChangeOfficePasswordReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShopId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string new_password = 1;
 * @return {string}
 */
proto.partner.web_partnership.ChangeOfficePasswordReq.prototype.getNewPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ChangeOfficePasswordReq} returns this
 */
proto.partner.web_partnership.ChangeOfficePasswordReq.prototype.setNewPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.partner.web_partnership.ChangeOfficePasswordReq.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ChangeOfficePasswordReq} returns this
 */
proto.partner.web_partnership.ChangeOfficePasswordReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string shop_id = 3;
 * @return {string}
 */
proto.partner.web_partnership.ChangeOfficePasswordReq.prototype.getShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ChangeOfficePasswordReq} returns this
 */
proto.partner.web_partnership.ChangeOfficePasswordReq.prototype.setShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partnership.UpdateOfficeProvidersReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.UpdateOfficeProvidersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.UpdateOfficeProvidersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.UpdateOfficeProvidersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateOfficeProvidersReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enabledProvidersList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.UpdateOfficeProvidersReq}
 */
proto.partner.web_partnership.UpdateOfficeProvidersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.UpdateOfficeProvidersReq;
  return proto.partner.web_partnership.UpdateOfficeProvidersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.UpdateOfficeProvidersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.UpdateOfficeProvidersReq}
 */
proto.partner.web_partnership.UpdateOfficeProvidersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEnabledProviders(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.UpdateOfficeProvidersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.UpdateOfficeProvidersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.UpdateOfficeProvidersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateOfficeProvidersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnabledProvidersList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partner.web_partnership.UpdateOfficeProvidersReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateOfficeProvidersReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeProvidersReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int64 enabled_providers = 2;
 * @return {!Array<number>}
 */
proto.partner.web_partnership.UpdateOfficeProvidersReq.prototype.getEnabledProvidersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.partner.web_partnership.UpdateOfficeProvidersReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeProvidersReq.prototype.setEnabledProvidersList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.partner.web_partnership.UpdateOfficeProvidersReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeProvidersReq.prototype.addEnabledProviders = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.UpdateOfficeProvidersReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeProvidersReq.prototype.clearEnabledProvidersList = function() {
  return this.setEnabledProvidersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.UpdateOfficeProvidersRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.UpdateOfficeProvidersRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.UpdateOfficeProvidersRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateOfficeProvidersRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.UpdateOfficeProvidersRes}
 */
proto.partner.web_partnership.UpdateOfficeProvidersRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.UpdateOfficeProvidersRes;
  return proto.partner.web_partnership.UpdateOfficeProvidersRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.UpdateOfficeProvidersRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.UpdateOfficeProvidersRes}
 */
proto.partner.web_partnership.UpdateOfficeProvidersRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.UpdateOfficeProvidersRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.UpdateOfficeProvidersRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.UpdateOfficeProvidersRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateOfficeProvidersRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partnership.UpdateOfficeProvidersRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.UpdateOfficeProvidersRes} returns this
 */
proto.partner.web_partnership.UpdateOfficeProvidersRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partnership.UpdateOfficeProvidersRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateOfficeProvidersRes} returns this
 */
proto.partner.web_partnership.UpdateOfficeProvidersRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.CreateOfficeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.CreateOfficeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.CreateOfficeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreateOfficeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.CreateOfficeRes}
 */
proto.partner.web_partnership.CreateOfficeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.CreateOfficeRes;
  return proto.partner.web_partnership.CreateOfficeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.CreateOfficeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.CreateOfficeRes}
 */
proto.partner.web_partnership.CreateOfficeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.CreateOfficeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.CreateOfficeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.CreateOfficeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreateOfficeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partnership.CreateOfficeRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.CreateOfficeRes} returns this
 */
proto.partner.web_partnership.CreateOfficeRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partnership.CreateOfficeRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreateOfficeRes} returns this
 */
proto.partner.web_partnership.CreateOfficeRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.partner.web_partnership.CreateOfficeRes.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreateOfficeRes} returns this
 */
proto.partner.web_partnership.CreateOfficeRes.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.UpdateOfficeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.UpdateOfficeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.UpdateOfficeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateOfficeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.UpdateOfficeRes}
 */
proto.partner.web_partnership.UpdateOfficeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.UpdateOfficeRes;
  return proto.partner.web_partnership.UpdateOfficeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.UpdateOfficeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.UpdateOfficeRes}
 */
proto.partner.web_partnership.UpdateOfficeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.UpdateOfficeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.UpdateOfficeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.UpdateOfficeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateOfficeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partnership.UpdateOfficeRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.UpdateOfficeRes} returns this
 */
proto.partner.web_partnership.UpdateOfficeRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partnership.UpdateOfficeRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateOfficeRes} returns this
 */
proto.partner.web_partnership.UpdateOfficeRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.DCPTravelFusion.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.DCPTravelFusion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.DCPTravelFusion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DCPTravelFusion.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    baseUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    level: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.DCPTravelFusion}
 */
proto.partner.web_partnership.DCPTravelFusion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.DCPTravelFusion;
  return proto.partner.web_partnership.DCPTravelFusion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.DCPTravelFusion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.DCPTravelFusion}
 */
proto.partner.web_partnership.DCPTravelFusion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseUrl(value);
      break;
    case 4:
      var value = /** @type {!proto.partner.DCPLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.DCPTravelFusion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.DCPTravelFusion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.DCPTravelFusion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DCPTravelFusion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBaseUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.partner.web_partnership.DCPTravelFusion.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPTravelFusion} returns this
 */
proto.partner.web_partnership.DCPTravelFusion.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partner.web_partnership.DCPTravelFusion.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPTravelFusion} returns this
 */
proto.partner.web_partnership.DCPTravelFusion.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string base_url = 3;
 * @return {string}
 */
proto.partner.web_partnership.DCPTravelFusion.prototype.getBaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPTravelFusion} returns this
 */
proto.partner.web_partnership.DCPTravelFusion.prototype.setBaseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional partner.DCPLevel level = 4;
 * @return {!proto.partner.DCPLevel}
 */
proto.partner.web_partnership.DCPTravelFusion.prototype.getLevel = function() {
  return /** @type {!proto.partner.DCPLevel} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.partner.DCPLevel} value
 * @return {!proto.partner.web_partnership.DCPTravelFusion} returns this
 */
proto.partner.web_partnership.DCPTravelFusion.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.DCPAmadeus.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.DCPAmadeus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.DCPAmadeus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DCPAmadeus.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pseudoCityCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    agentDutyCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    requestorType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    posType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    baseUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    level: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.DCPAmadeus}
 */
proto.partner.web_partnership.DCPAmadeus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.DCPAmadeus;
  return proto.partner.web_partnership.DCPAmadeus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.DCPAmadeus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.DCPAmadeus}
 */
proto.partner.web_partnership.DCPAmadeus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPseudoCityCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentDutyCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestorType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseUrl(value);
      break;
    case 8:
      var value = /** @type {!proto.partner.DCPLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.DCPAmadeus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.DCPAmadeus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.DCPAmadeus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DCPAmadeus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPseudoCityCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAgentDutyCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRequestorType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPosType();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getBaseUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.partner.web_partnership.DCPAmadeus.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPAmadeus} returns this
 */
proto.partner.web_partnership.DCPAmadeus.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partner.web_partnership.DCPAmadeus.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPAmadeus} returns this
 */
proto.partner.web_partnership.DCPAmadeus.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string pseudo_city_code = 3;
 * @return {string}
 */
proto.partner.web_partnership.DCPAmadeus.prototype.getPseudoCityCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPAmadeus} returns this
 */
proto.partner.web_partnership.DCPAmadeus.prototype.setPseudoCityCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string agent_duty_code = 4;
 * @return {string}
 */
proto.partner.web_partnership.DCPAmadeus.prototype.getAgentDutyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPAmadeus} returns this
 */
proto.partner.web_partnership.DCPAmadeus.prototype.setAgentDutyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string requestor_type = 5;
 * @return {string}
 */
proto.partner.web_partnership.DCPAmadeus.prototype.getRequestorType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPAmadeus} returns this
 */
proto.partner.web_partnership.DCPAmadeus.prototype.setRequestorType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 pos_type = 6;
 * @return {number}
 */
proto.partner.web_partnership.DCPAmadeus.prototype.getPosType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.partner.web_partnership.DCPAmadeus} returns this
 */
proto.partner.web_partnership.DCPAmadeus.prototype.setPosType = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string base_url = 7;
 * @return {string}
 */
proto.partner.web_partnership.DCPAmadeus.prototype.getBaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPAmadeus} returns this
 */
proto.partner.web_partnership.DCPAmadeus.prototype.setBaseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional partner.DCPLevel level = 8;
 * @return {!proto.partner.DCPLevel}
 */
proto.partner.web_partnership.DCPAmadeus.prototype.getLevel = function() {
  return /** @type {!proto.partner.DCPLevel} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.partner.DCPLevel} value
 * @return {!proto.partner.web_partnership.DCPAmadeus} returns this
 */
proto.partner.web_partnership.DCPAmadeus.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.DCPVietnamAirlines.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.DCPVietnamAirlines} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DCPVietnamAirlines.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    password: jspb.Message.getFieldWithDefault(msg, 3, ""),
    stationNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pcc: jspb.Message.getFieldWithDefault(msg, 5, ""),
    printerLniata: jspb.Message.getFieldWithDefault(msg, 6, ""),
    printerCountry: jspb.Message.getFieldWithDefault(msg, 7, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 8, ""),
    organization: jspb.Message.getFieldWithDefault(msg, 9, ""),
    baseUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    level: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.DCPVietnamAirlines}
 */
proto.partner.web_partnership.DCPVietnamAirlines.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.DCPVietnamAirlines;
  return proto.partner.web_partnership.DCPVietnamAirlines.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.DCPVietnamAirlines} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.DCPVietnamAirlines}
 */
proto.partner.web_partnership.DCPVietnamAirlines.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPcc(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrinterLniata(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrinterCountry(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganization(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseUrl(value);
      break;
    case 11:
      var value = /** @type {!proto.partner.DCPLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.DCPVietnamAirlines.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.DCPVietnamAirlines} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DCPVietnamAirlines.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStationNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPcc();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPrinterLniata();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPrinterCountry();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOrganization();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBaseUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
};


/**
 * optional string account_number = 1;
 * @return {string}
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPVietnamAirlines} returns this
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_name = 2;
 * @return {string}
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPVietnamAirlines} returns this
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string password = 3;
 * @return {string}
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPVietnamAirlines} returns this
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string station_number = 4;
 * @return {string}
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.getStationNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPVietnamAirlines} returns this
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.setStationNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string pcc = 5;
 * @return {string}
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.getPcc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPVietnamAirlines} returns this
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.setPcc = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string printer_lniata = 6;
 * @return {string}
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.getPrinterLniata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPVietnamAirlines} returns this
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.setPrinterLniata = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string printer_country = 7;
 * @return {string}
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.getPrinterCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPVietnamAirlines} returns this
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.setPrinterCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string domain = 8;
 * @return {string}
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPVietnamAirlines} returns this
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string organization = 9;
 * @return {string}
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.getOrganization = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPVietnamAirlines} returns this
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.setOrganization = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string base_url = 10;
 * @return {string}
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.getBaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPVietnamAirlines} returns this
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.setBaseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional partner.DCPLevel level = 11;
 * @return {!proto.partner.DCPLevel}
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.getLevel = function() {
  return /** @type {!proto.partner.DCPLevel} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.partner.DCPLevel} value
 * @return {!proto.partner.web_partnership.DCPVietnamAirlines} returns this
 */
proto.partner.web_partnership.DCPVietnamAirlines.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.DCPInternationalEV.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.DCPInternationalEV} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DCPInternationalEV.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseUrl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loginUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    username: jspb.Message.getFieldWithDefault(msg, 3, ""),
    password: jspb.Message.getFieldWithDefault(msg, 4, ""),
    clientSecret: jspb.Message.getFieldWithDefault(msg, 5, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    grantType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    level: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.DCPInternationalEV}
 */
proto.partner.web_partnership.DCPInternationalEV.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.DCPInternationalEV;
  return proto.partner.web_partnership.DCPInternationalEV.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.DCPInternationalEV} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.DCPInternationalEV}
 */
proto.partner.web_partnership.DCPInternationalEV.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientSecret(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrantType(value);
      break;
    case 8:
      var value = /** @type {!proto.partner.DCPLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.DCPInternationalEV.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.DCPInternationalEV} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DCPInternationalEV.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoginUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClientSecret();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getGrantType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string base_url = 1;
 * @return {string}
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.getBaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPInternationalEV} returns this
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.setBaseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string login_url = 2;
 * @return {string}
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.getLoginUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPInternationalEV} returns this
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.setLoginUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string username = 3;
 * @return {string}
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPInternationalEV} returns this
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string password = 4;
 * @return {string}
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPInternationalEV} returns this
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string client_secret = 5;
 * @return {string}
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.getClientSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPInternationalEV} returns this
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.setClientSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string client_id = 6;
 * @return {string}
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPInternationalEV} returns this
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string grant_type = 7;
 * @return {string}
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.getGrantType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPInternationalEV} returns this
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.setGrantType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional partner.DCPLevel level = 8;
 * @return {!proto.partner.DCPLevel}
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.getLevel = function() {
  return /** @type {!proto.partner.DCPLevel} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.partner.DCPLevel} value
 * @return {!proto.partner.web_partnership.DCPInternationalEV} returns this
 */
proto.partner.web_partnership.DCPInternationalEV.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.DCPEV.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.DCPEV.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.DCPEV} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DCPEV.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    baseUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    level: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.DCPEV}
 */
proto.partner.web_partnership.DCPEV.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.DCPEV;
  return proto.partner.web_partnership.DCPEV.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.DCPEV} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.DCPEV}
 */
proto.partner.web_partnership.DCPEV.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseUrl(value);
      break;
    case 4:
      var value = /** @type {!proto.partner.DCPLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.DCPEV.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.DCPEV.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.DCPEV} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DCPEV.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBaseUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.partner.web_partnership.DCPEV.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPEV} returns this
 */
proto.partner.web_partnership.DCPEV.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partner.web_partnership.DCPEV.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPEV} returns this
 */
proto.partner.web_partnership.DCPEV.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string base_url = 3;
 * @return {string}
 */
proto.partner.web_partnership.DCPEV.prototype.getBaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPEV} returns this
 */
proto.partner.web_partnership.DCPEV.prototype.setBaseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional partner.DCPLevel level = 4;
 * @return {!proto.partner.DCPLevel}
 */
proto.partner.web_partnership.DCPEV.prototype.getLevel = function() {
  return /** @type {!proto.partner.DCPLevel} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.partner.DCPLevel} value
 * @return {!proto.partner.web_partnership.DCPEV} returns this
 */
proto.partner.web_partnership.DCPEV.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.DCPVietjetAir.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.DCPVietjetAir.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.DCPVietjetAir} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DCPVietjetAir.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    baseUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    level: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.DCPVietjetAir}
 */
proto.partner.web_partnership.DCPVietjetAir.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.DCPVietjetAir;
  return proto.partner.web_partnership.DCPVietjetAir.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.DCPVietjetAir} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.DCPVietjetAir}
 */
proto.partner.web_partnership.DCPVietjetAir.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseUrl(value);
      break;
    case 4:
      var value = /** @type {!proto.partner.DCPLevel} */ (reader.readEnum());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.DCPVietjetAir.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.DCPVietjetAir.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.DCPVietjetAir} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DCPVietjetAir.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBaseUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.partner.web_partnership.DCPVietjetAir.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPVietjetAir} returns this
 */
proto.partner.web_partnership.DCPVietjetAir.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partner.web_partnership.DCPVietjetAir.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPVietjetAir} returns this
 */
proto.partner.web_partnership.DCPVietjetAir.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string base_url = 3;
 * @return {string}
 */
proto.partner.web_partnership.DCPVietjetAir.prototype.getBaseUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DCPVietjetAir} returns this
 */
proto.partner.web_partnership.DCPVietjetAir.prototype.setBaseUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional partner.DCPLevel level = 4;
 * @return {!proto.partner.DCPLevel}
 */
proto.partner.web_partnership.DCPVietjetAir.prototype.getLevel = function() {
  return /** @type {!proto.partner.DCPLevel} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.partner.DCPLevel} value
 * @return {!proto.partner.web_partnership.DCPVietjetAir} returns this
 */
proto.partner.web_partnership.DCPVietjetAir.prototype.setLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.GetDomainsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.GetDomainsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.GetDomainsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetDomainsReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.GetDomainsReq}
 */
proto.partner.web_partnership.GetDomainsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.GetDomainsReq;
  return proto.partner.web_partnership.GetDomainsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.GetDomainsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.GetDomainsReq}
 */
proto.partner.web_partnership.GetDomainsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.GetDomainsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.GetDomainsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.GetDomainsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetDomainsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.GetProvidersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.GetProvidersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.GetProvidersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetProvidersReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.GetProvidersReq}
 */
proto.partner.web_partnership.GetProvidersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.GetProvidersReq;
  return proto.partner.web_partnership.GetProvidersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.GetProvidersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.GetProvidersReq}
 */
proto.partner.web_partnership.GetProvidersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.GetProvidersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.GetProvidersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.GetProvidersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetProvidersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.GetUnassignedOfficesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.GetUnassignedOfficesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.GetUnassignedOfficesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetUnassignedOfficesReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.GetUnassignedOfficesReq}
 */
proto.partner.web_partnership.GetUnassignedOfficesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.GetUnassignedOfficesReq;
  return proto.partner.web_partnership.GetUnassignedOfficesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.GetUnassignedOfficesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.GetUnassignedOfficesReq}
 */
proto.partner.web_partnership.GetUnassignedOfficesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.GetUnassignedOfficesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.GetUnassignedOfficesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.GetUnassignedOfficesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetUnassignedOfficesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partnership.CreateOfficeReq.repeatedFields_ = [7,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.CreateOfficeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.CreateOfficeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreateOfficeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    shopName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    enabledProvidersList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    hotelProvidersList: jspb.Message.toObjectList(msg.getHotelProvidersList(),
    partner_base_pb.HotelProvider.toObject, includeInstance),
    defaultLanguage: jspb.Message.getFieldWithDefault(msg, 10, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.CreateOfficeReq}
 */
proto.partner.web_partnership.CreateOfficeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.CreateOfficeReq;
  return proto.partner.web_partnership.CreateOfficeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.CreateOfficeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.CreateOfficeReq}
 */
proto.partner.web_partnership.CreateOfficeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopName(value);
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEnabledProviders(values[i]);
      }
      break;
    case 8:
      var value = /** @type {!proto.partner.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 9:
      var value = new partner_base_pb.HotelProvider;
      reader.readMessage(value,partner_base_pb.HotelProvider.deserializeBinaryFromReader);
      msg.addHotelProviders(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultLanguage(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.CreateOfficeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.CreateOfficeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreateOfficeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getShopName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEnabledProvidersList();
  if (f.length > 0) {
    writer.writePackedInt64(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getHotelProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      partner_base_pb.HotelProvider.serializeBinaryToWriter
    );
  }
  f = message.getDefaultLanguage();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string user_name = 1;
 * @return {string}
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreateOfficeReq} returns this
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreateOfficeReq} returns this
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreateOfficeReq} returns this
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone = 4;
 * @return {string}
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreateOfficeReq} returns this
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreateOfficeReq} returns this
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string shop_name = 6;
 * @return {string}
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.getShopName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreateOfficeReq} returns this
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.setShopName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated int64 enabled_providers = 7;
 * @return {!Array<number>}
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.getEnabledProvidersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.partner.web_partnership.CreateOfficeReq} returns this
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.setEnabledProvidersList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.partner.web_partnership.CreateOfficeReq} returns this
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.addEnabledProviders = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.CreateOfficeReq} returns this
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.clearEnabledProvidersList = function() {
  return this.setEnabledProvidersList([]);
};


/**
 * optional partner.UserStatus status = 8;
 * @return {!proto.partner.UserStatus}
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.getStatus = function() {
  return /** @type {!proto.partner.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.partner.UserStatus} value
 * @return {!proto.partner.web_partnership.CreateOfficeReq} returns this
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated partner.HotelProvider hotel_providers = 9;
 * @return {!Array<!proto.partner.HotelProvider>}
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.getHotelProvidersList = function() {
  return /** @type{!Array<!proto.partner.HotelProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, partner_base_pb.HotelProvider, 9));
};


/**
 * @param {!Array<!proto.partner.HotelProvider>} value
 * @return {!proto.partner.web_partnership.CreateOfficeReq} returns this
*/
proto.partner.web_partnership.CreateOfficeReq.prototype.setHotelProvidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.partner.HotelProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.HotelProvider}
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.addHotelProviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.partner.HotelProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.CreateOfficeReq} returns this
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.clearHotelProvidersList = function() {
  return this.setHotelProvidersList([]);
};


/**
 * optional string default_language = 10;
 * @return {string}
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.getDefaultLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreateOfficeReq} returns this
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.setDefaultLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string logo = 11;
 * @return {string}
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreateOfficeReq} returns this
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.setLogo = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.CreateOfficeReq} returns this
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.clearLogo = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.CreateOfficeReq.prototype.hasLogo = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.UpdateOfficeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.UpdateOfficeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateOfficeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    shopName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    logo: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.UpdateOfficeReq}
 */
proto.partner.web_partnership.UpdateOfficeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.UpdateOfficeReq;
  return proto.partner.web_partnership.UpdateOfficeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.UpdateOfficeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.UpdateOfficeReq}
 */
proto.partner.web_partnership.UpdateOfficeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopName(value);
      break;
    case 7:
      var value = /** @type {!proto.partner.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.UpdateOfficeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.UpdateOfficeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateOfficeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getShopName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateOfficeReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateOfficeReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone = 3;
 * @return {string}
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateOfficeReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateOfficeReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string shop_name = 5;
 * @return {string}
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.getShopName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateOfficeReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.setShopName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional partner.UserStatus status = 7;
 * @return {!proto.partner.UserStatus}
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.getStatus = function() {
  return /** @type {!proto.partner.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.partner.UserStatus} value
 * @return {!proto.partner.web_partnership.UpdateOfficeReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string logo = 8;
 * @return {string}
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateOfficeReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.setLogo = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.UpdateOfficeReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.clearLogo = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.UpdateOfficeReq.prototype.hasLogo = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.ListMyOfficesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.ListMyOfficesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.ListMyOfficesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListMyOfficesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.partner.web_partnership.ListMyOfficesReq.Filter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.ListMyOfficesReq}
 */
proto.partner.web_partnership.ListMyOfficesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.ListMyOfficesReq;
  return proto.partner.web_partnership.ListMyOfficesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.ListMyOfficesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.ListMyOfficesReq}
 */
proto.partner.web_partnership.ListMyOfficesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.partner.web_partnership.ListMyOfficesReq.Filter;
      reader.readMessage(value,proto.partner.web_partnership.ListMyOfficesReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.ListMyOfficesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.ListMyOfficesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.ListMyOfficesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListMyOfficesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.partner.web_partnership.ListMyOfficesReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.ListMyOfficesReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.ListMyOfficesReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.ListMyOfficesReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListMyOfficesReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.ListMyOfficesReq.Filter}
 */
proto.partner.web_partnership.ListMyOfficesReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.ListMyOfficesReq.Filter;
  return proto.partner.web_partnership.ListMyOfficesReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.ListMyOfficesReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.ListMyOfficesReq.Filter}
 */
proto.partner.web_partnership.ListMyOfficesReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.ListMyOfficesReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.ListMyOfficesReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.ListMyOfficesReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListMyOfficesReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string agent_code = 1;
 * @return {string}
 */
proto.partner.web_partnership.ListMyOfficesReq.Filter.prototype.getAgentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ListMyOfficesReq.Filter} returns this
 */
proto.partner.web_partnership.ListMyOfficesReq.Filter.prototype.setAgentCode = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.ListMyOfficesReq.Filter} returns this
 */
proto.partner.web_partnership.ListMyOfficesReq.Filter.prototype.clearAgentCode = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListMyOfficesReq.Filter.prototype.hasAgentCode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 provider = 2;
 * @return {number}
 */
proto.partner.web_partnership.ListMyOfficesReq.Filter.prototype.getProvider = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.partner.web_partnership.ListMyOfficesReq.Filter} returns this
 */
proto.partner.web_partnership.ListMyOfficesReq.Filter.prototype.setProvider = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.ListMyOfficesReq.Filter} returns this
 */
proto.partner.web_partnership.ListMyOfficesReq.Filter.prototype.clearProvider = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListMyOfficesReq.Filter.prototype.hasProvider = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Filter filter = 1;
 * @return {?proto.partner.web_partnership.ListMyOfficesReq.Filter}
 */
proto.partner.web_partnership.ListMyOfficesReq.prototype.getFilter = function() {
  return /** @type{?proto.partner.web_partnership.ListMyOfficesReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partnership.ListMyOfficesReq.Filter, 1));
};


/**
 * @param {?proto.partner.web_partnership.ListMyOfficesReq.Filter|undefined} value
 * @return {!proto.partner.web_partnership.ListMyOfficesReq} returns this
*/
proto.partner.web_partnership.ListMyOfficesReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.ListMyOfficesReq} returns this
 */
proto.partner.web_partnership.ListMyOfficesReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListMyOfficesReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.partner.web_partnership.ListMyOfficesReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.partner.web_partnership.ListMyOfficesReq} returns this
*/
proto.partner.web_partnership.ListMyOfficesReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.ListMyOfficesReq} returns this
 */
proto.partner.web_partnership.ListMyOfficesReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListMyOfficesReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.GetOfficeDetailReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.GetOfficeDetailReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.GetOfficeDetailReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetOfficeDetailReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.GetOfficeDetailReq}
 */
proto.partner.web_partnership.GetOfficeDetailReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.GetOfficeDetailReq;
  return proto.partner.web_partnership.GetOfficeDetailReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.GetOfficeDetailReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.GetOfficeDetailReq}
 */
proto.partner.web_partnership.GetOfficeDetailReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.GetOfficeDetailReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.GetOfficeDetailReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.GetOfficeDetailReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetOfficeDetailReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partner.web_partnership.GetOfficeDetailReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.GetOfficeDetailReq} returns this
 */
proto.partner.web_partnership.GetOfficeDetailReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partnership.CreatePartnerReq.repeatedFields_ = [9,16,17,18,19,20,21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.CreatePartnerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.CreatePartnerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreatePartnerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profilePicture: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    shopName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    shopLogo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    shopBanner: jspb.Message.getFieldWithDefault(msg, 8, ""),
    documentsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    type: jspb.Message.getFieldWithDefault(msg, 10, 0),
    shopCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    officeId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    apiKey: jspb.Message.getFieldWithDefault(msg, 14, ""),
    webhook: (f = msg.getWebhook()) && proto.partner.web_partnership.WebHook.toObject(includeInstance, f),
    dcpsAmadeusList: jspb.Message.toObjectList(msg.getDcpsAmadeusList(),
    proto.partner.web_partnership.DCPAmadeus.toObject, includeInstance),
    dcpsTravelFusionList: jspb.Message.toObjectList(msg.getDcpsTravelFusionList(),
    proto.partner.web_partnership.DCPTravelFusion.toObject, includeInstance),
    dcpsVietnamAirlinesList: jspb.Message.toObjectList(msg.getDcpsVietnamAirlinesList(),
    proto.partner.web_partnership.DCPVietnamAirlines.toObject, includeInstance),
    dcpsVietjetAirList: jspb.Message.toObjectList(msg.getDcpsVietjetAirList(),
    proto.partner.web_partnership.DCPVietjetAir.toObject, includeInstance),
    dcpsEvList: jspb.Message.toObjectList(msg.getDcpsEvList(),
    proto.partner.web_partnership.DCPEV.toObject, includeInstance),
    dcpsInternationalEvList: jspb.Message.toObjectList(msg.getDcpsInternationalEvList(),
    proto.partner.web_partnership.DCPInternationalEV.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.CreatePartnerReq}
 */
proto.partner.web_partnership.CreatePartnerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.CreatePartnerReq;
  return proto.partner.web_partnership.CreatePartnerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.CreatePartnerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.CreatePartnerReq}
 */
proto.partner.web_partnership.CreatePartnerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicture(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopLogo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopBanner(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addDocuments(value);
      break;
    case 10:
      var value = /** @type {!proto.partner.web_partnership.PartnerType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKey(value);
      break;
    case 15:
      var value = new proto.partner.web_partnership.WebHook;
      reader.readMessage(value,proto.partner.web_partnership.WebHook.deserializeBinaryFromReader);
      msg.setWebhook(value);
      break;
    case 16:
      var value = new proto.partner.web_partnership.DCPAmadeus;
      reader.readMessage(value,proto.partner.web_partnership.DCPAmadeus.deserializeBinaryFromReader);
      msg.addDcpsAmadeus(value);
      break;
    case 17:
      var value = new proto.partner.web_partnership.DCPTravelFusion;
      reader.readMessage(value,proto.partner.web_partnership.DCPTravelFusion.deserializeBinaryFromReader);
      msg.addDcpsTravelFusion(value);
      break;
    case 18:
      var value = new proto.partner.web_partnership.DCPVietnamAirlines;
      reader.readMessage(value,proto.partner.web_partnership.DCPVietnamAirlines.deserializeBinaryFromReader);
      msg.addDcpsVietnamAirlines(value);
      break;
    case 19:
      var value = new proto.partner.web_partnership.DCPVietjetAir;
      reader.readMessage(value,proto.partner.web_partnership.DCPVietjetAir.deserializeBinaryFromReader);
      msg.addDcpsVietjetAir(value);
      break;
    case 20:
      var value = new proto.partner.web_partnership.DCPEV;
      reader.readMessage(value,proto.partner.web_partnership.DCPEV.deserializeBinaryFromReader);
      msg.addDcpsEv(value);
      break;
    case 21:
      var value = new proto.partner.web_partnership.DCPInternationalEV;
      reader.readMessage(value,proto.partner.web_partnership.DCPInternationalEV.deserializeBinaryFromReader);
      msg.addDcpsInternationalEv(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.CreatePartnerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.CreatePartnerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreatePartnerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfilePicture();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getShopName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getShopLogo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getShopBanner();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getShopCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getApiKey();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getWebhook();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.partner.web_partnership.WebHook.serializeBinaryToWriter
    );
  }
  f = message.getDcpsAmadeusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.partner.web_partnership.DCPAmadeus.serializeBinaryToWriter
    );
  }
  f = message.getDcpsTravelFusionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.partner.web_partnership.DCPTravelFusion.serializeBinaryToWriter
    );
  }
  f = message.getDcpsVietnamAirlinesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.partner.web_partnership.DCPVietnamAirlines.serializeBinaryToWriter
    );
  }
  f = message.getDcpsVietjetAirList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.partner.web_partnership.DCPVietjetAir.serializeBinaryToWriter
    );
  }
  f = message.getDcpsEvList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.partner.web_partnership.DCPEV.serializeBinaryToWriter
    );
  }
  f = message.getDcpsInternationalEvList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.partner.web_partnership.DCPInternationalEV.serializeBinaryToWriter
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profile_picture = 4;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getProfilePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.setProfilePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone = 5;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string shop_name = 6;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getShopName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.setShopName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string shop_logo = 7;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getShopLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.setShopLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string shop_banner = 8;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getShopBanner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.setShopBanner = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string documents = 9;
 * @return {!Array<string>}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getDocumentsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.setDocumentsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.addDocuments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};


/**
 * optional PartnerType type = 10;
 * @return {!proto.partner.web_partnership.PartnerType}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getType = function() {
  return /** @type {!proto.partner.web_partnership.PartnerType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.partner.web_partnership.PartnerType} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string shop_code = 11;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getShopCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.setShopCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string user_name = 12;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string office_id = 13;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string api_key = 14;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.setApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional WebHook webhook = 15;
 * @return {?proto.partner.web_partnership.WebHook}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getWebhook = function() {
  return /** @type{?proto.partner.web_partnership.WebHook} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partnership.WebHook, 15));
};


/**
 * @param {?proto.partner.web_partnership.WebHook|undefined} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
*/
proto.partner.web_partnership.CreatePartnerReq.prototype.setWebhook = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.clearWebhook = function() {
  return this.setWebhook(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.hasWebhook = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated DCPAmadeus dcps_amadeus = 16;
 * @return {!Array<!proto.partner.web_partnership.DCPAmadeus>}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getDcpsAmadeusList = function() {
  return /** @type{!Array<!proto.partner.web_partnership.DCPAmadeus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.web_partnership.DCPAmadeus, 16));
};


/**
 * @param {!Array<!proto.partner.web_partnership.DCPAmadeus>} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
*/
proto.partner.web_partnership.CreatePartnerReq.prototype.setDcpsAmadeusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.partner.web_partnership.DCPAmadeus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.web_partnership.DCPAmadeus}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.addDcpsAmadeus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.partner.web_partnership.DCPAmadeus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.clearDcpsAmadeusList = function() {
  return this.setDcpsAmadeusList([]);
};


/**
 * repeated DCPTravelFusion dcps_travel_fusion = 17;
 * @return {!Array<!proto.partner.web_partnership.DCPTravelFusion>}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getDcpsTravelFusionList = function() {
  return /** @type{!Array<!proto.partner.web_partnership.DCPTravelFusion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.web_partnership.DCPTravelFusion, 17));
};


/**
 * @param {!Array<!proto.partner.web_partnership.DCPTravelFusion>} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
*/
proto.partner.web_partnership.CreatePartnerReq.prototype.setDcpsTravelFusionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.partner.web_partnership.DCPTravelFusion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.web_partnership.DCPTravelFusion}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.addDcpsTravelFusion = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.partner.web_partnership.DCPTravelFusion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.clearDcpsTravelFusionList = function() {
  return this.setDcpsTravelFusionList([]);
};


/**
 * repeated DCPVietnamAirlines dcps_vietnam_airlines = 18;
 * @return {!Array<!proto.partner.web_partnership.DCPVietnamAirlines>}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getDcpsVietnamAirlinesList = function() {
  return /** @type{!Array<!proto.partner.web_partnership.DCPVietnamAirlines>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.web_partnership.DCPVietnamAirlines, 18));
};


/**
 * @param {!Array<!proto.partner.web_partnership.DCPVietnamAirlines>} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
*/
proto.partner.web_partnership.CreatePartnerReq.prototype.setDcpsVietnamAirlinesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.partner.web_partnership.DCPVietnamAirlines=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.web_partnership.DCPVietnamAirlines}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.addDcpsVietnamAirlines = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.partner.web_partnership.DCPVietnamAirlines, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.clearDcpsVietnamAirlinesList = function() {
  return this.setDcpsVietnamAirlinesList([]);
};


/**
 * repeated DCPVietjetAir dcps_vietjet_air = 19;
 * @return {!Array<!proto.partner.web_partnership.DCPVietjetAir>}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getDcpsVietjetAirList = function() {
  return /** @type{!Array<!proto.partner.web_partnership.DCPVietjetAir>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.web_partnership.DCPVietjetAir, 19));
};


/**
 * @param {!Array<!proto.partner.web_partnership.DCPVietjetAir>} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
*/
proto.partner.web_partnership.CreatePartnerReq.prototype.setDcpsVietjetAirList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.partner.web_partnership.DCPVietjetAir=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.web_partnership.DCPVietjetAir}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.addDcpsVietjetAir = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.partner.web_partnership.DCPVietjetAir, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.clearDcpsVietjetAirList = function() {
  return this.setDcpsVietjetAirList([]);
};


/**
 * repeated DCPEV dcps_ev = 20;
 * @return {!Array<!proto.partner.web_partnership.DCPEV>}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getDcpsEvList = function() {
  return /** @type{!Array<!proto.partner.web_partnership.DCPEV>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.web_partnership.DCPEV, 20));
};


/**
 * @param {!Array<!proto.partner.web_partnership.DCPEV>} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
*/
proto.partner.web_partnership.CreatePartnerReq.prototype.setDcpsEvList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.partner.web_partnership.DCPEV=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.web_partnership.DCPEV}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.addDcpsEv = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.partner.web_partnership.DCPEV, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.clearDcpsEvList = function() {
  return this.setDcpsEvList([]);
};


/**
 * repeated DCPInternationalEV dcps_international_ev = 21;
 * @return {!Array<!proto.partner.web_partnership.DCPInternationalEV>}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.getDcpsInternationalEvList = function() {
  return /** @type{!Array<!proto.partner.web_partnership.DCPInternationalEV>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.web_partnership.DCPInternationalEV, 21));
};


/**
 * @param {!Array<!proto.partner.web_partnership.DCPInternationalEV>} value
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
*/
proto.partner.web_partnership.CreatePartnerReq.prototype.setDcpsInternationalEvList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.partner.web_partnership.DCPInternationalEV=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.web_partnership.DCPInternationalEV}
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.addDcpsInternationalEv = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.partner.web_partnership.DCPInternationalEV, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.CreatePartnerReq} returns this
 */
proto.partner.web_partnership.CreatePartnerReq.prototype.clearDcpsInternationalEvList = function() {
  return this.setDcpsInternationalEvList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.WebhookURLConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.WebhookURLConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.WebhookURLConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.WebhookURLConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    transaction: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastTktDate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.WebhookURLConfig}
 */
proto.partner.web_partnership.WebhookURLConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.WebhookURLConfig;
  return proto.partner.web_partnership.WebhookURLConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.WebhookURLConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.WebhookURLConfig}
 */
proto.partner.web_partnership.WebhookURLConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransaction(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastTktDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.WebhookURLConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.WebhookURLConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.WebhookURLConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.WebhookURLConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransaction();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastTktDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string transaction = 1;
 * @return {string}
 */
proto.partner.web_partnership.WebhookURLConfig.prototype.getTransaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.WebhookURLConfig} returns this
 */
proto.partner.web_partnership.WebhookURLConfig.prototype.setTransaction = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_tkt_date = 2;
 * @return {string}
 */
proto.partner.web_partnership.WebhookURLConfig.prototype.getLastTktDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.WebhookURLConfig} returns this
 */
proto.partner.web_partnership.WebhookURLConfig.prototype.setLastTktDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.WebHook.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.WebHook.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.WebHook} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.WebHook.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    urlConfig: (f = msg.getUrlConfig()) && proto.partner.web_partnership.WebhookURLConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.WebHook}
 */
proto.partner.web_partnership.WebHook.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.WebHook;
  return proto.partner.web_partnership.WebHook.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.WebHook} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.WebHook}
 */
proto.partner.web_partnership.WebHook.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = new proto.partner.web_partnership.WebhookURLConfig;
      reader.readMessage(value,proto.partner.web_partnership.WebhookURLConfig.deserializeBinaryFromReader);
      msg.setUrlConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.WebHook.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.WebHook.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.WebHook} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.WebHook.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUrlConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partner.web_partnership.WebhookURLConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.partner.web_partnership.WebHook.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.WebHook} returns this
 */
proto.partner.web_partnership.WebHook.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.partner.web_partnership.WebHook.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.WebHook} returns this
 */
proto.partner.web_partnership.WebHook.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional WebhookURLConfig url_config = 3;
 * @return {?proto.partner.web_partnership.WebhookURLConfig}
 */
proto.partner.web_partnership.WebHook.prototype.getUrlConfig = function() {
  return /** @type{?proto.partner.web_partnership.WebhookURLConfig} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partnership.WebhookURLConfig, 3));
};


/**
 * @param {?proto.partner.web_partnership.WebhookURLConfig|undefined} value
 * @return {!proto.partner.web_partnership.WebHook} returns this
*/
proto.partner.web_partnership.WebHook.prototype.setUrlConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.WebHook} returns this
 */
proto.partner.web_partnership.WebHook.prototype.clearUrlConfig = function() {
  return this.setUrlConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.WebHook.prototype.hasUrlConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partnership.GetDomainsRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.GetDomainsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.GetDomainsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.GetDomainsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetDomainsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    partner_base_pb.Domain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.GetDomainsRes}
 */
proto.partner.web_partnership.GetDomainsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.GetDomainsRes;
  return proto.partner.web_partnership.GetDomainsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.GetDomainsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.GetDomainsRes}
 */
proto.partner.web_partnership.GetDomainsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new partner_base_pb.Domain;
      reader.readMessage(value,partner_base_pb.Domain.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.GetDomainsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.GetDomainsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.GetDomainsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetDomainsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      partner_base_pb.Domain.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partnership.GetDomainsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.GetDomainsRes} returns this
 */
proto.partner.web_partnership.GetDomainsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partnership.GetDomainsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.GetDomainsRes} returns this
 */
proto.partner.web_partnership.GetDomainsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated partner.Domain items = 3;
 * @return {!Array<!proto.partner.Domain>}
 */
proto.partner.web_partnership.GetDomainsRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.partner.Domain>} */ (
    jspb.Message.getRepeatedWrapperField(this, partner_base_pb.Domain, 3));
};


/**
 * @param {!Array<!proto.partner.Domain>} value
 * @return {!proto.partner.web_partnership.GetDomainsRes} returns this
*/
proto.partner.web_partnership.GetDomainsRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.partner.Domain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.Domain}
 */
proto.partner.web_partnership.GetDomainsRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.partner.Domain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.GetDomainsRes} returns this
 */
proto.partner.web_partnership.GetDomainsRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partnership.GetProvidersRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.GetProvidersRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.GetProvidersRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.GetProvidersRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetProvidersRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    partner_base_pb.Provider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.GetProvidersRes}
 */
proto.partner.web_partnership.GetProvidersRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.GetProvidersRes;
  return proto.partner.web_partnership.GetProvidersRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.GetProvidersRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.GetProvidersRes}
 */
proto.partner.web_partnership.GetProvidersRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new partner_base_pb.Provider;
      reader.readMessage(value,partner_base_pb.Provider.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.GetProvidersRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.GetProvidersRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.GetProvidersRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetProvidersRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      partner_base_pb.Provider.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partnership.GetProvidersRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.GetProvidersRes} returns this
 */
proto.partner.web_partnership.GetProvidersRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partnership.GetProvidersRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.GetProvidersRes} returns this
 */
proto.partner.web_partnership.GetProvidersRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated partner.Provider items = 3;
 * @return {!Array<!proto.partner.Provider>}
 */
proto.partner.web_partnership.GetProvidersRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.partner.Provider>} */ (
    jspb.Message.getRepeatedWrapperField(this, partner_base_pb.Provider, 3));
};


/**
 * @param {!Array<!proto.partner.Provider>} value
 * @return {!proto.partner.web_partnership.GetProvidersRes} returns this
*/
proto.partner.web_partnership.GetProvidersRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.partner.Provider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.Provider}
 */
proto.partner.web_partnership.GetProvidersRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.partner.Provider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.GetProvidersRes} returns this
 */
proto.partner.web_partnership.GetProvidersRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partnership.GetUnassignedOfficesRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.GetUnassignedOfficesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.GetUnassignedOfficesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.GetUnassignedOfficesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetUnassignedOfficesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    partner_base_pb.ListItem.toObject, includeInstance),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.GetUnassignedOfficesRes}
 */
proto.partner.web_partnership.GetUnassignedOfficesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.GetUnassignedOfficesRes;
  return proto.partner.web_partnership.GetUnassignedOfficesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.GetUnassignedOfficesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.GetUnassignedOfficesRes}
 */
proto.partner.web_partnership.GetUnassignedOfficesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new partner_base_pb.ListItem;
      reader.readMessage(value,partner_base_pb.ListItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.GetUnassignedOfficesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.GetUnassignedOfficesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.GetUnassignedOfficesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetUnassignedOfficesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      partner_base_pb.ListItem.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated partner.ListItem items = 1;
 * @return {!Array<!proto.partner.ListItem>}
 */
proto.partner.web_partnership.GetUnassignedOfficesRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.partner.ListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, partner_base_pb.ListItem, 1));
};


/**
 * @param {!Array<!proto.partner.ListItem>} value
 * @return {!proto.partner.web_partnership.GetUnassignedOfficesRes} returns this
*/
proto.partner.web_partnership.GetUnassignedOfficesRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.partner.ListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.ListItem}
 */
proto.partner.web_partnership.GetUnassignedOfficesRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.partner.ListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.GetUnassignedOfficesRes} returns this
 */
proto.partner.web_partnership.GetUnassignedOfficesRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional bool is_success = 2;
 * @return {boolean}
 */
proto.partner.web_partnership.GetUnassignedOfficesRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.GetUnassignedOfficesRes} returns this
 */
proto.partner.web_partnership.GetUnassignedOfficesRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string error_code = 3;
 * @return {string}
 */
proto.partner.web_partnership.GetUnassignedOfficesRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.GetUnassignedOfficesRes} returns this
 */
proto.partner.web_partnership.GetUnassignedOfficesRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partnership.ListMyOfficesRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.ListMyOfficesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.ListMyOfficesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.ListMyOfficesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListMyOfficesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    partner_base_pb.OfficeInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.ListMyOfficesRes}
 */
proto.partner.web_partnership.ListMyOfficesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.ListMyOfficesRes;
  return proto.partner.web_partnership.ListMyOfficesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.ListMyOfficesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.ListMyOfficesRes}
 */
proto.partner.web_partnership.ListMyOfficesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new partner_base_pb.OfficeInfo;
      reader.readMessage(value,partner_base_pb.OfficeInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.ListMyOfficesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.ListMyOfficesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.ListMyOfficesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListMyOfficesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      partner_base_pb.OfficeInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partnership.ListMyOfficesRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.ListMyOfficesRes} returns this
 */
proto.partner.web_partnership.ListMyOfficesRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partnership.ListMyOfficesRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ListMyOfficesRes} returns this
 */
proto.partner.web_partnership.ListMyOfficesRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated partner.OfficeInfo items = 3;
 * @return {!Array<!proto.partner.OfficeInfo>}
 */
proto.partner.web_partnership.ListMyOfficesRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.partner.OfficeInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, partner_base_pb.OfficeInfo, 3));
};


/**
 * @param {!Array<!proto.partner.OfficeInfo>} value
 * @return {!proto.partner.web_partnership.ListMyOfficesRes} returns this
*/
proto.partner.web_partnership.ListMyOfficesRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.partner.OfficeInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.OfficeInfo}
 */
proto.partner.web_partnership.ListMyOfficesRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.partner.OfficeInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.ListMyOfficesRes} returns this
 */
proto.partner.web_partnership.ListMyOfficesRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.partner.web_partnership.ListMyOfficesRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.partner.web_partnership.ListMyOfficesRes} returns this
*/
proto.partner.web_partnership.ListMyOfficesRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.ListMyOfficesRes} returns this
 */
proto.partner.web_partnership.ListMyOfficesRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListMyOfficesRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.GetOfficeDetailRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.GetOfficeDetailRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.GetOfficeDetailRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetOfficeDetailRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: (f = msg.getData()) && partner_base_pb.OfficeInfo.toObject(includeInstance, f),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.GetOfficeDetailRes}
 */
proto.partner.web_partnership.GetOfficeDetailRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.GetOfficeDetailRes;
  return proto.partner.web_partnership.GetOfficeDetailRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.GetOfficeDetailRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.GetOfficeDetailRes}
 */
proto.partner.web_partnership.GetOfficeDetailRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new partner_base_pb.OfficeInfo;
      reader.readMessage(value,partner_base_pb.OfficeInfo.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.GetOfficeDetailRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.GetOfficeDetailRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.GetOfficeDetailRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetOfficeDetailRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      partner_base_pb.OfficeInfo.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional partner.OfficeInfo data = 1;
 * @return {?proto.partner.OfficeInfo}
 */
proto.partner.web_partnership.GetOfficeDetailRes.prototype.getData = function() {
  return /** @type{?proto.partner.OfficeInfo} */ (
    jspb.Message.getWrapperField(this, partner_base_pb.OfficeInfo, 1));
};


/**
 * @param {?proto.partner.OfficeInfo|undefined} value
 * @return {!proto.partner.web_partnership.GetOfficeDetailRes} returns this
*/
proto.partner.web_partnership.GetOfficeDetailRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.GetOfficeDetailRes} returns this
 */
proto.partner.web_partnership.GetOfficeDetailRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.GetOfficeDetailRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_success = 2;
 * @return {boolean}
 */
proto.partner.web_partnership.GetOfficeDetailRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.GetOfficeDetailRes} returns this
 */
proto.partner.web_partnership.GetOfficeDetailRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string error_code = 3;
 * @return {string}
 */
proto.partner.web_partnership.GetOfficeDetailRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.GetOfficeDetailRes} returns this
 */
proto.partner.web_partnership.GetOfficeDetailRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.Partner.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.Partner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.Partner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.Partner.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shopId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.Partner}
 */
proto.partner.web_partnership.Partner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.Partner;
  return proto.partner.web_partnership.Partner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.Partner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.Partner}
 */
proto.partner.web_partnership.Partner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShopId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.Partner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.Partner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.Partner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.Partner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShopId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string owner_id = 1;
 * @return {string}
 */
proto.partner.web_partnership.Partner.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.Partner} returns this
 */
proto.partner.web_partnership.Partner.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string shop_id = 2;
 * @return {string}
 */
proto.partner.web_partnership.Partner.prototype.getShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.Partner} returns this
 */
proto.partner.web_partnership.Partner.prototype.setShopId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.CreatePartnerRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.CreatePartnerRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.CreatePartnerRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreatePartnerRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.partner.web_partnership.Partner.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.CreatePartnerRes}
 */
proto.partner.web_partnership.CreatePartnerRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.CreatePartnerRes;
  return proto.partner.web_partnership.CreatePartnerRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.CreatePartnerRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.CreatePartnerRes}
 */
proto.partner.web_partnership.CreatePartnerRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.partner.web_partnership.Partner;
      reader.readMessage(value,proto.partner.web_partnership.Partner.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.CreatePartnerRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.CreatePartnerRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.CreatePartnerRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreatePartnerRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partner.web_partnership.Partner.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partnership.CreatePartnerRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.CreatePartnerRes} returns this
 */
proto.partner.web_partnership.CreatePartnerRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerRes} returns this
 */
proto.partner.web_partnership.CreatePartnerRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Partner data = 3;
 * @return {?proto.partner.web_partnership.Partner}
 */
proto.partner.web_partnership.CreatePartnerRes.prototype.getData = function() {
  return /** @type{?proto.partner.web_partnership.Partner} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partnership.Partner, 3));
};


/**
 * @param {?proto.partner.web_partnership.Partner|undefined} value
 * @return {!proto.partner.web_partnership.CreatePartnerRes} returns this
*/
proto.partner.web_partnership.CreatePartnerRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.CreatePartnerRes} returns this
 */
proto.partner.web_partnership.CreatePartnerRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.CreatePartnerRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partnership.GetOfficesRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.GetOfficesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.GetOfficesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.GetOfficesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetOfficesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.partner.web_partnership.GetOfficesRes.OfficeInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.GetOfficesRes}
 */
proto.partner.web_partnership.GetOfficesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.GetOfficesRes;
  return proto.partner.web_partnership.GetOfficesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.GetOfficesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.GetOfficesRes}
 */
proto.partner.web_partnership.GetOfficesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new proto.partner.web_partnership.GetOfficesRes.OfficeInfo;
      reader.readMessage(value,proto.partner.web_partnership.GetOfficesRes.OfficeInfo.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.GetOfficesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.GetOfficesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.GetOfficesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetOfficesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.partner.web_partnership.GetOfficesRes.OfficeInfo.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.GetOfficesRes.OfficeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.GetOfficesRes.OfficeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    officeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    officeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    agentCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    hotelProvidersList: jspb.Message.toObjectList(msg.getHotelProvidersList(),
    partner_base_pb.HotelProvider.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.GetOfficesRes.OfficeInfo}
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.GetOfficesRes.OfficeInfo;
  return proto.partner.web_partnership.GetOfficesRes.OfficeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.GetOfficesRes.OfficeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.GetOfficesRes.OfficeInfo}
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentCode(value);
      break;
    case 4:
      var value = new partner_base_pb.HotelProvider;
      reader.readMessage(value,partner_base_pb.HotelProvider.deserializeBinaryFromReader);
      msg.addHotelProviders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.GetOfficesRes.OfficeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.GetOfficesRes.OfficeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOfficeName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAgentCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHotelProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      partner_base_pb.HotelProvider.serializeBinaryToWriter
    );
  }
};


/**
 * optional string office_id = 1;
 * @return {string}
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.GetOfficesRes.OfficeInfo} returns this
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string office_name = 2;
 * @return {string}
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.prototype.getOfficeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.GetOfficesRes.OfficeInfo} returns this
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.prototype.setOfficeName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string agent_code = 3;
 * @return {string}
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.prototype.getAgentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.GetOfficesRes.OfficeInfo} returns this
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.prototype.setAgentCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated partner.HotelProvider hotel_providers = 4;
 * @return {!Array<!proto.partner.HotelProvider>}
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.prototype.getHotelProvidersList = function() {
  return /** @type{!Array<!proto.partner.HotelProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, partner_base_pb.HotelProvider, 4));
};


/**
 * @param {!Array<!proto.partner.HotelProvider>} value
 * @return {!proto.partner.web_partnership.GetOfficesRes.OfficeInfo} returns this
*/
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.prototype.setHotelProvidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.partner.HotelProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.HotelProvider}
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.prototype.addHotelProviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.partner.HotelProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.GetOfficesRes.OfficeInfo} returns this
 */
proto.partner.web_partnership.GetOfficesRes.OfficeInfo.prototype.clearHotelProvidersList = function() {
  return this.setHotelProvidersList([]);
};


/**
 * repeated OfficeInfo data = 3;
 * @return {!Array<!proto.partner.web_partnership.GetOfficesRes.OfficeInfo>}
 */
proto.partner.web_partnership.GetOfficesRes.prototype.getDataList = function() {
  return /** @type{!Array<!proto.partner.web_partnership.GetOfficesRes.OfficeInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partner.web_partnership.GetOfficesRes.OfficeInfo, 3));
};


/**
 * @param {!Array<!proto.partner.web_partnership.GetOfficesRes.OfficeInfo>} value
 * @return {!proto.partner.web_partnership.GetOfficesRes} returns this
*/
proto.partner.web_partnership.GetOfficesRes.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.partner.web_partnership.GetOfficesRes.OfficeInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.web_partnership.GetOfficesRes.OfficeInfo}
 */
proto.partner.web_partnership.GetOfficesRes.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.partner.web_partnership.GetOfficesRes.OfficeInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.GetOfficesRes} returns this
 */
proto.partner.web_partnership.GetOfficesRes.prototype.clearDataList = function() {
  return this.setDataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.UpdateOfficeHotelInfoReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.UpdateOfficeHotelInfoReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    providersList: jspb.Message.toObjectList(msg.getProvidersList(),
    partner_base_pb.HotelProvider.toObject, includeInstance),
    isUpdateHotelProviders: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    defaultLanguage: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.UpdateOfficeHotelInfoReq}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.UpdateOfficeHotelInfoReq;
  return proto.partner.web_partnership.UpdateOfficeHotelInfoReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.UpdateOfficeHotelInfoReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.UpdateOfficeHotelInfoReq}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new partner_base_pb.HotelProvider;
      reader.readMessage(value,partner_base_pb.HotelProvider.deserializeBinaryFromReader);
      msg.addProviders(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsUpdateHotelProviders(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.UpdateOfficeHotelInfoReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.UpdateOfficeHotelInfoReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProvidersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      partner_base_pb.HotelProvider.serializeBinaryToWriter
    );
  }
  f = message.getIsUpdateHotelProviders();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDefaultLanguage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateOfficeHotelInfoReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated partner.HotelProvider providers = 2;
 * @return {!Array<!proto.partner.HotelProvider>}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.prototype.getProvidersList = function() {
  return /** @type{!Array<!proto.partner.HotelProvider>} */ (
    jspb.Message.getRepeatedWrapperField(this, partner_base_pb.HotelProvider, 2));
};


/**
 * @param {!Array<!proto.partner.HotelProvider>} value
 * @return {!proto.partner.web_partnership.UpdateOfficeHotelInfoReq} returns this
*/
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.prototype.setProvidersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.partner.HotelProvider=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.HotelProvider}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.prototype.addProviders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.partner.HotelProvider, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.UpdateOfficeHotelInfoReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.prototype.clearProvidersList = function() {
  return this.setProvidersList([]);
};


/**
 * optional bool is_update_hotel_providers = 3;
 * @return {boolean}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.prototype.getIsUpdateHotelProviders = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.UpdateOfficeHotelInfoReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.prototype.setIsUpdateHotelProviders = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool enable = 4;
 * @return {boolean}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.UpdateOfficeHotelInfoReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string default_language = 5;
 * @return {string}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.prototype.getDefaultLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateOfficeHotelInfoReq} returns this
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoReq.prototype.setDefaultLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.UpdateOfficeHotelInfoRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.UpdateOfficeHotelInfoRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.UpdateOfficeHotelInfoRes}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.UpdateOfficeHotelInfoRes;
  return proto.partner.web_partnership.UpdateOfficeHotelInfoRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.UpdateOfficeHotelInfoRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.UpdateOfficeHotelInfoRes}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.UpdateOfficeHotelInfoRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.UpdateOfficeHotelInfoRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.UpdateOfficeHotelInfoRes} returns this
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateOfficeHotelInfoRes} returns this
 */
proto.partner.web_partnership.UpdateOfficeHotelInfoRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.partner.web_partnership.PartnerType = {
  PARTNER_TYPE_INVALID: 0,
  PARTNER_TYPE_PERSONAL: 1,
  PARTNER_TYPE_ORGANIZATION: 2
};

goog.object.extend(exports, proto.partner.web_partnership);
