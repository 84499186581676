// source: hotel/web_partnership/hotel.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var hotel_booking_pb = require('../../hotel/booking_pb.js');
goog.object.extend(proto, hotel_booking_pb);
var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var hotel_base_pb = require('../../hotel/base_pb.js');
goog.object.extend(proto, hotel_base_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.hotel.web_partnership.ExportBookingsReq', null, global);
goog.exportSymbol('proto.hotel.web_partnership.ExportBookingsRes', null, global);
goog.exportSymbol('proto.hotel.web_partnership.GetBookingDetailRequest', null, global);
goog.exportSymbol('proto.hotel.web_partnership.GetBookingDetailResponse', null, global);
goog.exportSymbol('proto.hotel.web_partnership.ListBookingsFilter', null, global);
goog.exportSymbol('proto.hotel.web_partnership.ListBookingsRequest', null, global);
goog.exportSymbol('proto.hotel.web_partnership.ListBookingsResponse', null, global);
goog.exportSymbol('proto.hotel.web_partnership.OccupancyUpdateInfo', null, global);
goog.exportSymbol('proto.hotel.web_partnership.UpdateBookingStatusRequest', null, global);
goog.exportSymbol('proto.hotel.web_partnership.UpdateBookingStatusResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partnership.UpdateBookingStatusRequest.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partnership.UpdateBookingStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partnership.UpdateBookingStatusRequest.displayName = 'proto.hotel.web_partnership.UpdateBookingStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partnership.OccupancyUpdateInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partnership.OccupancyUpdateInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partnership.OccupancyUpdateInfo.displayName = 'proto.hotel.web_partnership.OccupancyUpdateInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partnership.UpdateBookingStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partnership.UpdateBookingStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partnership.UpdateBookingStatusResponse.displayName = 'proto.hotel.web_partnership.UpdateBookingStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partnership.GetBookingDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partnership.GetBookingDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partnership.GetBookingDetailRequest.displayName = 'proto.hotel.web_partnership.GetBookingDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partnership.GetBookingDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partnership.GetBookingDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partnership.GetBookingDetailResponse.displayName = 'proto.hotel.web_partnership.GetBookingDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partnership.ListBookingsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partnership.ListBookingsFilter.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partnership.ListBookingsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partnership.ListBookingsFilter.displayName = 'proto.hotel.web_partnership.ListBookingsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partnership.ListBookingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partnership.ListBookingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partnership.ListBookingsRequest.displayName = 'proto.hotel.web_partnership.ListBookingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partnership.ListBookingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partnership.ListBookingsResponse.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partnership.ListBookingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partnership.ListBookingsResponse.displayName = 'proto.hotel.web_partnership.ListBookingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partnership.ExportBookingsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.web_partnership.ExportBookingsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partnership.ExportBookingsReq.displayName = 'proto.hotel.web_partnership.ExportBookingsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.web_partnership.ExportBookingsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.web_partnership.ExportBookingsRes.repeatedFields_, null);
};
goog.inherits(proto.hotel.web_partnership.ExportBookingsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.web_partnership.ExportBookingsRes.displayName = 'proto.hotel.web_partnership.ExportBookingsRes';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partnership.UpdateBookingStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partnership.UpdateBookingStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    source: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actualAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    cancelReason: jspb.Message.getFieldWithDefault(msg, 5, ""),
    occupancyUpdatesList: jspb.Message.toObjectList(msg.getOccupancyUpdatesList(),
    proto.hotel.web_partnership.OccupancyUpdateInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partnership.UpdateBookingStatusRequest}
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partnership.UpdateBookingStatusRequest;
  return proto.hotel.web_partnership.UpdateBookingStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partnership.UpdateBookingStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partnership.UpdateBookingStatusRequest}
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    case 2:
      var value = /** @type {!proto.hotel.BookingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setActualAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCancelReason(value);
      break;
    case 6:
      var value = new proto.hotel.web_partnership.OccupancyUpdateInfo;
      reader.readMessage(value,proto.hotel.web_partnership.OccupancyUpdateInfo.deserializeBinaryFromReader);
      msg.addOccupancyUpdates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partnership.UpdateBookingStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partnership.UpdateBookingStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActualAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCancelReason();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOccupancyUpdatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.hotel.web_partnership.OccupancyUpdateInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string order_code = 1;
 * @return {string}
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partnership.UpdateBookingStatusRequest} returns this
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional hotel.BookingStatus status = 2;
 * @return {!proto.hotel.BookingStatus}
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.getStatus = function() {
  return /** @type {!proto.hotel.BookingStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.hotel.BookingStatus} value
 * @return {!proto.hotel.web_partnership.UpdateBookingStatusRequest} returns this
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string source = 3;
 * @return {string}
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partnership.UpdateBookingStatusRequest} returns this
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double actual_amount = 4;
 * @return {number}
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.getActualAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.web_partnership.UpdateBookingStatusRequest} returns this
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.setActualAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string cancel_reason = 5;
 * @return {string}
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.getCancelReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partnership.UpdateBookingStatusRequest} returns this
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.setCancelReason = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated OccupancyUpdateInfo occupancy_updates = 6;
 * @return {!Array<!proto.hotel.web_partnership.OccupancyUpdateInfo>}
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.getOccupancyUpdatesList = function() {
  return /** @type{!Array<!proto.hotel.web_partnership.OccupancyUpdateInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.web_partnership.OccupancyUpdateInfo, 6));
};


/**
 * @param {!Array<!proto.hotel.web_partnership.OccupancyUpdateInfo>} value
 * @return {!proto.hotel.web_partnership.UpdateBookingStatusRequest} returns this
*/
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.setOccupancyUpdatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.hotel.web_partnership.OccupancyUpdateInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.web_partnership.OccupancyUpdateInfo}
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.addOccupancyUpdates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.hotel.web_partnership.OccupancyUpdateInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partnership.UpdateBookingStatusRequest} returns this
 */
proto.hotel.web_partnership.UpdateBookingStatusRequest.prototype.clearOccupancyUpdatesList = function() {
  return this.setOccupancyUpdatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partnership.OccupancyUpdateInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partnership.OccupancyUpdateInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partnership.OccupancyUpdateInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.OccupancyUpdateInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    occupancyIndex: jspb.Message.getFieldWithDefault(msg, 1, 0),
    confirmationId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partnership.OccupancyUpdateInfo}
 */
proto.hotel.web_partnership.OccupancyUpdateInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partnership.OccupancyUpdateInfo;
  return proto.hotel.web_partnership.OccupancyUpdateInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partnership.OccupancyUpdateInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partnership.OccupancyUpdateInfo}
 */
proto.hotel.web_partnership.OccupancyUpdateInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOccupancyIndex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partnership.OccupancyUpdateInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partnership.OccupancyUpdateInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partnership.OccupancyUpdateInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.OccupancyUpdateInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOccupancyIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getConfirmationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 occupancy_index = 1;
 * @return {number}
 */
proto.hotel.web_partnership.OccupancyUpdateInfo.prototype.getOccupancyIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.web_partnership.OccupancyUpdateInfo} returns this
 */
proto.hotel.web_partnership.OccupancyUpdateInfo.prototype.setOccupancyIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string confirmation_id = 2;
 * @return {string}
 */
proto.hotel.web_partnership.OccupancyUpdateInfo.prototype.getConfirmationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partnership.OccupancyUpdateInfo} returns this
 */
proto.hotel.web_partnership.OccupancyUpdateInfo.prototype.setConfirmationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partnership.UpdateBookingStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partnership.UpdateBookingStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partnership.UpdateBookingStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.UpdateBookingStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partnership.UpdateBookingStatusResponse}
 */
proto.hotel.web_partnership.UpdateBookingStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partnership.UpdateBookingStatusResponse;
  return proto.hotel.web_partnership.UpdateBookingStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partnership.UpdateBookingStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partnership.UpdateBookingStatusResponse}
 */
proto.hotel.web_partnership.UpdateBookingStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partnership.UpdateBookingStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partnership.UpdateBookingStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partnership.UpdateBookingStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.UpdateBookingStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partnership.UpdateBookingStatusResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partnership.UpdateBookingStatusResponse} returns this
 */
proto.hotel.web_partnership.UpdateBookingStatusResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partnership.UpdateBookingStatusResponse.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partnership.UpdateBookingStatusResponse} returns this
 */
proto.hotel.web_partnership.UpdateBookingStatusResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partnership.GetBookingDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partnership.GetBookingDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partnership.GetBookingDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.GetBookingDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partnership.GetBookingDetailRequest}
 */
proto.hotel.web_partnership.GetBookingDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partnership.GetBookingDetailRequest;
  return proto.hotel.web_partnership.GetBookingDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partnership.GetBookingDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partnership.GetBookingDetailRequest}
 */
proto.hotel.web_partnership.GetBookingDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partnership.GetBookingDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partnership.GetBookingDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partnership.GetBookingDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.GetBookingDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string order_code = 1;
 * @return {string}
 */
proto.hotel.web_partnership.GetBookingDetailRequest.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partnership.GetBookingDetailRequest} returns this
 */
proto.hotel.web_partnership.GetBookingDetailRequest.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partnership.GetBookingDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partnership.GetBookingDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partnership.GetBookingDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.GetBookingDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && hotel_booking_pb.BookingDetail.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partnership.GetBookingDetailResponse}
 */
proto.hotel.web_partnership.GetBookingDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partnership.GetBookingDetailResponse;
  return proto.hotel.web_partnership.GetBookingDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partnership.GetBookingDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partnership.GetBookingDetailResponse}
 */
proto.hotel.web_partnership.GetBookingDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new hotel_booking_pb.BookingDetail;
      reader.readMessage(value,hotel_booking_pb.BookingDetail.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partnership.GetBookingDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partnership.GetBookingDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partnership.GetBookingDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.GetBookingDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      hotel_booking_pb.BookingDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partnership.GetBookingDetailResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partnership.GetBookingDetailResponse} returns this
 */
proto.hotel.web_partnership.GetBookingDetailResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partnership.GetBookingDetailResponse.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partnership.GetBookingDetailResponse} returns this
 */
proto.hotel.web_partnership.GetBookingDetailResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional hotel.BookingDetail data = 3;
 * @return {?proto.hotel.BookingDetail}
 */
proto.hotel.web_partnership.GetBookingDetailResponse.prototype.getData = function() {
  return /** @type{?proto.hotel.BookingDetail} */ (
    jspb.Message.getWrapperField(this, hotel_booking_pb.BookingDetail, 3));
};


/**
 * @param {?proto.hotel.BookingDetail|undefined} value
 * @return {!proto.hotel.web_partnership.GetBookingDetailResponse} returns this
*/
proto.hotel.web_partnership.GetBookingDetailResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partnership.GetBookingDetailResponse} returns this
 */
proto.hotel.web_partnership.GetBookingDetailResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partnership.GetBookingDetailResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partnership.ListBookingsFilter.repeatedFields_ = [1,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partnership.ListBookingsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partnership.ListBookingsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.ListBookingsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    orderCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    from: jspb.Message.getFieldWithDefault(msg, 3, 0),
    to: jspb.Message.getFieldWithDefault(msg, 4, 0),
    notInStatusesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    partnerShopId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bookerId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partnership.ListBookingsFilter}
 */
proto.hotel.web_partnership.ListBookingsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partnership.ListBookingsFilter;
  return proto.hotel.web_partnership.ListBookingsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partnership.ListBookingsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partnership.ListBookingsFilter}
 */
proto.hotel.web_partnership.ListBookingsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.hotel.BookingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatus(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFrom(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTo(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.hotel.BookingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotInStatuses(values[i]);
      }
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnerShopId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partnership.ListBookingsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partnership.ListBookingsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.ListBookingsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getOrderCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFrom();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNotInStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * repeated hotel.BookingStatus status = 1;
 * @return {!Array<!proto.hotel.BookingStatus>}
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.getStatusList = function() {
  return /** @type {!Array<!proto.hotel.BookingStatus>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.hotel.BookingStatus>} value
 * @return {!proto.hotel.web_partnership.ListBookingsFilter} returns this
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.hotel.BookingStatus} value
 * @param {number=} opt_index
 * @return {!proto.hotel.web_partnership.ListBookingsFilter} returns this
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partnership.ListBookingsFilter} returns this
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * optional string order_code = 2;
 * @return {string}
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.getOrderCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partnership.ListBookingsFilter} returns this
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.setOrderCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 from = 3;
 * @return {number}
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.web_partnership.ListBookingsFilter} returns this
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 to = 4;
 * @return {number}
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.web_partnership.ListBookingsFilter} returns this
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated hotel.BookingStatus not_in_statuses = 5;
 * @return {!Array<!proto.hotel.BookingStatus>}
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.getNotInStatusesList = function() {
  return /** @type {!Array<!proto.hotel.BookingStatus>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.hotel.BookingStatus>} value
 * @return {!proto.hotel.web_partnership.ListBookingsFilter} returns this
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.setNotInStatusesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.hotel.BookingStatus} value
 * @param {number=} opt_index
 * @return {!proto.hotel.web_partnership.ListBookingsFilter} returns this
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.addNotInStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partnership.ListBookingsFilter} returns this
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.clearNotInStatusesList = function() {
  return this.setNotInStatusesList([]);
};


/**
 * optional string partner_shop_id = 6;
 * @return {string}
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.getPartnerShopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partnership.ListBookingsFilter} returns this
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.setPartnerShopId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hotel.web_partnership.ListBookingsFilter} returns this
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.clearPartnerShopId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.hasPartnerShopId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string booker_id = 7;
 * @return {string}
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.getBookerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partnership.ListBookingsFilter} returns this
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.setBookerId = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.hotel.web_partnership.ListBookingsFilter} returns this
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.clearBookerId = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partnership.ListBookingsFilter.prototype.hasBookerId = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partnership.ListBookingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partnership.ListBookingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partnership.ListBookingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.ListBookingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.hotel.web_partnership.ListBookingsFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partnership.ListBookingsRequest}
 */
proto.hotel.web_partnership.ListBookingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partnership.ListBookingsRequest;
  return proto.hotel.web_partnership.ListBookingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partnership.ListBookingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partnership.ListBookingsRequest}
 */
proto.hotel.web_partnership.ListBookingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.hotel.web_partnership.ListBookingsFilter;
      reader.readMessage(value,proto.hotel.web_partnership.ListBookingsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partnership.ListBookingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partnership.ListBookingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partnership.ListBookingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.ListBookingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.hotel.web_partnership.ListBookingsFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional base.PaginationReq pagination = 1;
 * @return {?proto.base.PaginationReq}
 */
proto.hotel.web_partnership.ListBookingsRequest.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 1));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.hotel.web_partnership.ListBookingsRequest} returns this
*/
proto.hotel.web_partnership.ListBookingsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partnership.ListBookingsRequest} returns this
 */
proto.hotel.web_partnership.ListBookingsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partnership.ListBookingsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ListBookingsFilter filter = 2;
 * @return {?proto.hotel.web_partnership.ListBookingsFilter}
 */
proto.hotel.web_partnership.ListBookingsRequest.prototype.getFilter = function() {
  return /** @type{?proto.hotel.web_partnership.ListBookingsFilter} */ (
    jspb.Message.getWrapperField(this, proto.hotel.web_partnership.ListBookingsFilter, 2));
};


/**
 * @param {?proto.hotel.web_partnership.ListBookingsFilter|undefined} value
 * @return {!proto.hotel.web_partnership.ListBookingsRequest} returns this
*/
proto.hotel.web_partnership.ListBookingsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partnership.ListBookingsRequest} returns this
 */
proto.hotel.web_partnership.ListBookingsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partnership.ListBookingsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partnership.ListBookingsResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partnership.ListBookingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partnership.ListBookingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partnership.ListBookingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.ListBookingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    hotel_booking_pb.BookingDetail.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partnership.ListBookingsResponse}
 */
proto.hotel.web_partnership.ListBookingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partnership.ListBookingsResponse;
  return proto.hotel.web_partnership.ListBookingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partnership.ListBookingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partnership.ListBookingsResponse}
 */
proto.hotel.web_partnership.ListBookingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new hotel_booking_pb.BookingDetail;
      reader.readMessage(value,hotel_booking_pb.BookingDetail.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partnership.ListBookingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partnership.ListBookingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partnership.ListBookingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.ListBookingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      hotel_booking_pb.BookingDetail.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partnership.ListBookingsResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partnership.ListBookingsResponse} returns this
 */
proto.hotel.web_partnership.ListBookingsResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partnership.ListBookingsResponse.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partnership.ListBookingsResponse} returns this
 */
proto.hotel.web_partnership.ListBookingsResponse.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated hotel.BookingDetail items = 3;
 * @return {!Array<!proto.hotel.BookingDetail>}
 */
proto.hotel.web_partnership.ListBookingsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.hotel.BookingDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_booking_pb.BookingDetail, 3));
};


/**
 * @param {!Array<!proto.hotel.BookingDetail>} value
 * @return {!proto.hotel.web_partnership.ListBookingsResponse} returns this
*/
proto.hotel.web_partnership.ListBookingsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.hotel.BookingDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.BookingDetail}
 */
proto.hotel.web_partnership.ListBookingsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.hotel.BookingDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partnership.ListBookingsResponse} returns this
 */
proto.hotel.web_partnership.ListBookingsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.hotel.web_partnership.ListBookingsResponse.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.hotel.web_partnership.ListBookingsResponse} returns this
*/
proto.hotel.web_partnership.ListBookingsResponse.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partnership.ListBookingsResponse} returns this
 */
proto.hotel.web_partnership.ListBookingsResponse.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partnership.ListBookingsResponse.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partnership.ExportBookingsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partnership.ExportBookingsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partnership.ExportBookingsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.ExportBookingsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.hotel.web_partnership.ListBookingsFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partnership.ExportBookingsReq}
 */
proto.hotel.web_partnership.ExportBookingsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partnership.ExportBookingsReq;
  return proto.hotel.web_partnership.ExportBookingsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partnership.ExportBookingsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partnership.ExportBookingsReq}
 */
proto.hotel.web_partnership.ExportBookingsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.hotel.web_partnership.ListBookingsFilter;
      reader.readMessage(value,proto.hotel.web_partnership.ListBookingsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partnership.ExportBookingsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partnership.ExportBookingsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partnership.ExportBookingsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.ExportBookingsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.hotel.web_partnership.ListBookingsFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional ListBookingsFilter filter = 2;
 * @return {?proto.hotel.web_partnership.ListBookingsFilter}
 */
proto.hotel.web_partnership.ExportBookingsReq.prototype.getFilter = function() {
  return /** @type{?proto.hotel.web_partnership.ListBookingsFilter} */ (
    jspb.Message.getWrapperField(this, proto.hotel.web_partnership.ListBookingsFilter, 2));
};


/**
 * @param {?proto.hotel.web_partnership.ListBookingsFilter|undefined} value
 * @return {!proto.hotel.web_partnership.ExportBookingsReq} returns this
*/
proto.hotel.web_partnership.ExportBookingsReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.web_partnership.ExportBookingsReq} returns this
 */
proto.hotel.web_partnership.ExportBookingsReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.web_partnership.ExportBookingsReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.web_partnership.ExportBookingsRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.web_partnership.ExportBookingsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.web_partnership.ExportBookingsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.web_partnership.ExportBookingsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.ExportBookingsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    hotel_booking_pb.BookingDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.web_partnership.ExportBookingsRes}
 */
proto.hotel.web_partnership.ExportBookingsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.web_partnership.ExportBookingsRes;
  return proto.hotel.web_partnership.ExportBookingsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.web_partnership.ExportBookingsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.web_partnership.ExportBookingsRes}
 */
proto.hotel.web_partnership.ExportBookingsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new hotel_booking_pb.BookingDetail;
      reader.readMessage(value,hotel_booking_pb.BookingDetail.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.web_partnership.ExportBookingsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.web_partnership.ExportBookingsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.web_partnership.ExportBookingsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.web_partnership.ExportBookingsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      hotel_booking_pb.BookingDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.hotel.web_partnership.ExportBookingsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.web_partnership.ExportBookingsRes} returns this
 */
proto.hotel.web_partnership.ExportBookingsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.hotel.web_partnership.ExportBookingsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.web_partnership.ExportBookingsRes} returns this
 */
proto.hotel.web_partnership.ExportBookingsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated hotel.BookingDetail items = 3;
 * @return {!Array<!proto.hotel.BookingDetail>}
 */
proto.hotel.web_partnership.ExportBookingsRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.hotel.BookingDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_booking_pb.BookingDetail, 3));
};


/**
 * @param {!Array<!proto.hotel.BookingDetail>} value
 * @return {!proto.hotel.web_partnership.ExportBookingsRes} returns this
*/
proto.hotel.web_partnership.ExportBookingsRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.hotel.BookingDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.BookingDetail}
 */
proto.hotel.web_partnership.ExportBookingsRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.hotel.BookingDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.web_partnership.ExportBookingsRes} returns this
 */
proto.hotel.web_partnership.ExportBookingsRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


goog.object.extend(exports, proto.hotel.web_partnership);
