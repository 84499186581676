
import { IBookingDetail } from '@tixlabs/grpc-client/web-partnership';
import CollapseCard from '@vemaybay-admin/components/Common/Card/CollapseCard';
import { useCurrency } from '@vemaybay-admin/hooks/internals';
import { getPayAtHotelPrice } from '@vemaybay-admin/utils/function';
import { BookingStatus } from '@api/hotel/base_pb';
interface Props {
  bookingData: IBookingDetail;
}
export const DetailPrice = ({ bookingData }: Props) => {
  const { formatCurrency, formatCurrencyCustom, formatCurrencyHaveDecimal } = useCurrency()

  const averageRate = bookingData?.priceInfo?.totalRateAmount
    ? bookingData.priceInfo.totalRateAmount /
    ((bookingData.roomsList.length || 1) * (bookingData.totalNights || 1))
    : 0;
  return (
    <div className='bg-white border border-neutral-3 p-4 rounded text-md'>
      <h3 className='text-lg font-semibold mb-4'>Chi tiết giá:</h3>

      <div className='flex justify-between mb-2'>
        <span>{`${bookingData.roomsList.length} phòng x ${bookingData.totalNights} đêm`}</span>
        <span>
          {formatCurrency(bookingData?.priceInfo?.totalRateAmount || 0)}
        </span>
      </div>

      <div className='text-sm text-gray-500 mb-2'>
        <span>{formatCurrency(averageRate)} đ/đêm</span>
      </div>

      <div className='flex justify-between mb-2'>
        <span>Thuế & phí</span>
        <span>
          {formatCurrency(bookingData?.priceInfo?.totalTaxesAndFees || 0)}
        </span>
      </div>

      <div className='flex justify-between mb-2'>
        <span>Phí tiện ích</span>
        <span>{formatCurrency(bookingData?.paymentInfo?.feeAmount || 0)}</span>
      </div>

      {bookingData?.status !== BookingStatus.DRAFT &&
        bookingData?.status !== BookingStatus.IN_PROCESS &&
        bookingData?.status !== BookingStatus.CONFIRMED
        ? (
          <div className='flex justify-between mb-2'>
            <span>Phương thức: </span>
            <span>{bookingData?.paymentInfo?.method?.name}</span>
          </div>
        )
        : null}


      <div className='my-3 border-[1px] border-slate-300 border-dashed'></div>

      <div className='flex flex-col gap-2'>
        <div className='text-lg flex justify-between font-semibold'>
          <span>Tổng khách thanh toán</span>
          <span>
            {bookingData?.paymentInfo?.totalPrice
              ? formatCurrency(bookingData?.paymentInfo.totalPrice || 0)
              : formatCurrency(bookingData?.priceInfo?.payNow || 0, bookingData?.priceInfo?.currency)}
          </span>
        </div>

        {bookingData?.priceInfo?.payAtHotelList &&
          bookingData?.priceInfo?.payAtHotelList.length > 0 ? (
          <CollapseCard
            title='Thanh toán tại nơi lưu trú'
            price={`${formatCurrencyHaveDecimal(bookingData?.priceInfo.totalPayAtHotel,
              bookingData?.priceInfo.payAtHotelCurrency)}`}
            defaultShow={false}
            isShadow={false}>
            <div className='flex justify-between gap-2 flex-col'>
              {bookingData?.priceInfo?.payAtHotelList.map((item, index) => (
                <div key={index}>
                  <div className='flex justify-between gap-3'>
                    <span className='text-custom-gray text-xs'>
                      {item.description}
                    </span>
                    <span className='text-custom-gray text-xs'>
                      {`${formatCurrencyHaveDecimal(item.amount, item.currency)}`}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </CollapseCard>
        ) : null}
      </div>
    </div>
  );
};
