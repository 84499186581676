import { BasicRes, PaginationReq } from '@api/base/base_pb';
import { PartnerUserServiceClient } from '@api/partner/web_partner/partner_user_grpc_web_pb';
import {
  ChangePasswordReq,
  GetMeReq,
  GetMeRes,
  UpdateMeReq,
  CreatePartnerUserReq,
  RetrievePartnerUserReq,
  RetrievePartnerUserRes,
  UpdateUserReq,
  ListPartnerUserReq,
  ListPartnerUserRes,
  ListPartnerUserReqFilter,
  RetrieveBalanceReq,
  RetrieveBalanceRes,
} from '@api/partner/web_partner/partner_user_pb';

import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class PartnerUserApi extends BaseApi<PartnerUserServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new PartnerUserServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }
  getMe = (): Promise<GetMeRes.AsObject> => {
    const req = new GetMeReq();
    return this.grpc<GetMeReq, GetMeRes, GetMeRes.AsObject>(
      this.serviceClient.getMe,
      req,
      {}
    );
  };
  changePassword = ({ oldPassword, password }: ChangePasswordReq.AsObject) => {
    const req = new ChangePasswordReq();
    req.setOldPassword(oldPassword);
    req.setPassword(password);
    return this.grpc<ChangePasswordReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.changePassword,
      req,
      {}
    );
  };
  updateMe = ({ status, email, name, phone }: UpdateMeReq.AsObject) => {
    const req = new UpdateMeReq();

    email && req.setEmail(email);
    name && req.setName(name);
    phone && req.setPhone(phone);
    req.setStatus(status);

    return this.grpc<UpdateMeReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.updateMe,
      req,
      {}
    );
  };

  createPartnerUser = ({
    phone,
    password,
    name,
    userName,
    email,
    status,
    rolesList,
  }: CreatePartnerUserReq.AsObject) => {
    const req = new CreatePartnerUserReq();
    phone && req.setPhone(phone);
    password && req.setPassword(password);
    name && req.setName(name);
    userName && req.setUserName(userName);
    email && req.setEmail(email);
    status && req.setStatus(status);
    rolesList && req.setRolesList(rolesList);

    return this.grpc<CreatePartnerUserReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.createPartnerUser,
      req,
      {}
    );
  };

  retrievePartnerUser = ({
    id,
  }: RetrievePartnerUserReq.AsObject): Promise<RetrievePartnerUserRes.AsObject> => {
    const req = new RetrievePartnerUserReq();
    id && req.setId(id);

    return this.grpc<
      RetrievePartnerUserReq,
      RetrievePartnerUserRes,
      RetrievePartnerUserRes.AsObject
    >(this.serviceClient.retrievePartnerUser, req, {});
  };

  updateUser = ({
    phone,
    id,
    name,
    password,
    email,
    status,
  }: UpdateUserReq.AsObject) => {
    const req = new UpdateUserReq();
    phone && req.setPhone(phone);
    password && req.setPassword(password);
    name && req.setName(name);
    id && req.setId(id);
    email && req.setEmail(email);
    status && req.setStatus(status);

    return this.grpc<UpdateUserReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.updateUser,
      req,
      {}
    );
  };

  listPartnerUser = ({
    filter,
    pagination,
  }: ListPartnerUserReq.AsObject): Promise<ListPartnerUserRes.AsObject> => {
    const req = new ListPartnerUserReq();
    if (filter) {
      const filterObj = new ListPartnerUserReqFilter();
      filterObj.setQuery(filter.query || '');
      filterObj.setRole(filter.role || '');
      filterObj.setStatus(filter.status);
      req.setFilter(filterObj);
    }

    if (pagination) {
      const paginationObj = new PaginationReq();
      paginationObj.setPageNumber(pagination.pageNumber);
      paginationObj.setPageLimit(pagination.pageLimit);
      req.setPagination(paginationObj);
    }
    return this.grpc<
      ListPartnerUserReq,
      ListPartnerUserRes,
      ListPartnerUserRes.AsObject
    >(this.serviceClient.listPartnerUser, req, {});
  };

  retrieveBalance =
    ({}: RetrieveBalanceReq.AsObject): Promise<RetrieveBalanceRes.AsObject> => {
      const req = new RetrieveBalanceReq();
      return this.grpc<
        RetrieveBalanceReq,
        RetrieveBalanceRes,
        RetrieveBalanceRes.AsObject
      >(this.serviceClient.retrieveBalance, req, {});
    };
}

export const partnerUserServiceClient = new PartnerUserApi();
export default partnerUserServiceClient;
