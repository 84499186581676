import { useEffect, useState } from 'react';

export function useDebounceValueSearchHotel<T>(value: T, delay = 300) {
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    setIsDebouncing(true);

    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsDebouncing(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return { debouncedValue, isDebouncing };
}

export default useDebounceValueSearchHotel;
