import { WalletServiceClient } from '@api/hotel/web_partnership/wallet_grpc_web_pb';

import { PaginationReq } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import {
  ListTransactionReq,
  ListTransactionRes,
  TransactionFilter,
  ExportTransactionReq,
  ExportTransactionRes,
} from '@api/hotel/web_partnership/wallet_pb';

class WalletApi extends BaseApi<WalletServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new WalletServiceClient(this.url, null, this.optsDev);
  }

  listTransaction = ({
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListTransactionReq.AsObject): Promise<ListTransactionRes.AsObject> => {
    const req = new ListTransactionReq();
    const filterObj = new TransactionFilter();
    const paginationObj = new PaginationReq();

    if (filter) {
      filter.toDate &&
        filterObj.setToDate(new Date(filter.toDate).setHours(23, 59, 59, 999));

      filter.fromDate &&
        filterObj.setFromDate(new Date(filter.fromDate).setHours(0, 0, 0, 0));

      filter.orderCode &&
        filterObj.setOrderCode(filter.orderCode?.trim() || '');
      filterObj.setTransactionType(filter.transactionType);

      filter.transactionSource &&
        filterObj.setTransactionSource(filter.transactionSource);
      filter.bookerId && filterObj.setBookerId(filter.bookerId);
      filter.partnerShopId && filterObj.setPartnerShopId(filter.partnerShopId);
    }
    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);

    return this.grpc<
      ListTransactionReq,
      ListTransactionRes,
      ListTransactionRes.AsObject
    >(this.serviceClient?.listTransaction, req, {});
  };

  exportTransactions = ({
    filter,
  }: ExportTransactionReq.AsObject): Promise<ExportTransactionRes.AsObject> => {
    const req = new ExportTransactionReq();
    const filterObj = new TransactionFilter();

    if (filter) {
      filter.toDate && filterObj.setToDate(filter.toDate);
      filter.fromDate && filterObj.setFromDate(filter.fromDate);
      filter.orderCode &&
        filterObj.setOrderCode(filter.orderCode?.trim() || '');
      filterObj.setTransactionType(filter.transactionType);

      filter.transactionSource &&
        filterObj.setTransactionSource(filter.transactionSource);
      filter.bookerId && filterObj.setBookerId(filter.bookerId);
      filter.partnerShopId && filterObj.setPartnerShopId(filter.partnerShopId);
    }

    req.setFilter(filterObj);

    return this.grpc<
      ExportTransactionReq,
      ExportTransactionRes,
      ExportTransactionRes.AsObject
    >(this.serviceClient?.exportTransactions, req, {});
  };
}

export const hotelWalletApiService = new WalletApi();
export default hotelWalletApiService;
