import { IBookingDetail } from '@tixlabs/grpc-client/web-partnership';
import DOMPurify from 'dompurify';

interface Props {
  bookingData: IBookingDetail;
}
export const InfoAndPolicy = ({ bookingData }: Props) => {
  return (
    <div className='bg-white border-neutral-3 flex flex-col'>
      {bookingData?.amenitiesList.length > 0
        ? <div className='flex gap-3 mb-3'>
          <div className='w-1/4 text-lg font-semibold'>Tiện ích bổ sung </div>
          <div className='w-3/4 flex flex-col gap-1'>
            {bookingData?.amenitiesList
              .filter((item) => item !== '')
              .map((item, index) => {
                const cleanHTML = DOMPurify.sanitize(item);
                return (
                  <div
                    key={index}
                    className='leading-normal text-justify custom-list'
                    dangerouslySetInnerHTML={{ __html: cleanHTML }}
                  />
                );
              })}
          </div>
        </div>
        : null}

      {bookingData?.cancelPoliciesList.length > 0 && (
        <div className='flex gap-3 mb-3'>
          <div className='w-1/4 text-lg font-semibold'>Chính sách huỷ</div>
          <div className='w-3/4'>
            {bookingData?.cancelPoliciesList
              .filter((item) => item !== '')
              .map((item, index) => {
                const cleanHTML = DOMPurify.sanitize(item);
                return (
                  <div
                    key={index}
                    className='leading-normal text-justify custom-list'
                    dangerouslySetInnerHTML={{ __html: cleanHTML }}
                  />
                );
              })}
          </div>
        </div>
      )}

      {bookingData?.checkInInstructionsList.length > 0 && (
        <div className='flex gap-3'>
          <div className='w-1/4 text-lg font-semibold'>Thông tin quan trọng</div>
          <div className='w-3/4'>
            {bookingData?.checkInInstructionsList
              .filter((item) => item !== '')
              .map((item, index) => {
                const cleanHTML = DOMPurify.sanitize(item);
                return (
                  <div
                    key={index}
                    className='leading-normal text-justify custom-list'
                    dangerouslySetInnerHTML={{ __html: cleanHTML }}
                  />
                );
              })}
          </div>
        </div>)}
    </div>
  );
};
