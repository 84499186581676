// source: hotel/hotel.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var hotel_base_pb = require('../hotel/base_pb.js');
goog.object.extend(proto, hotel_base_pb);
goog.exportSymbol('proto.hotel.BreakfastOption', null, global);
goog.exportSymbol('proto.hotel.CheckIn', null, global);
goog.exportSymbol('proto.hotel.CheckOut', null, global);
goog.exportSymbol('proto.hotel.Description', null, global);
goog.exportSymbol('proto.hotel.ExtraBedOption', null, global);
goog.exportSymbol('proto.hotel.HotelDetail', null, global);
goog.exportSymbol('proto.hotel.HotelLocationInfo', null, global);
goog.exportSymbol('proto.hotel.HotelPaymentType', null, global);
goog.exportSymbol('proto.hotel.HotelPolicies', null, global);
goog.exportSymbol('proto.hotel.HubCancelPolicy', null, global);
goog.exportSymbol('proto.hotel.HubNonrefundableDateRange', null, global);
goog.exportSymbol('proto.hotel.HubOccupancyRate', null, global);
goog.exportSymbol('proto.hotel.HubPromotion', null, global);
goog.exportSymbol('proto.hotel.HubRate', null, global);
goog.exportSymbol('proto.hotel.HubRateData', null, global);
goog.exportSymbol('proto.hotel.HubRateTax', null, global);
goog.exportSymbol('proto.hotel.HubRoom', null, global);
goog.exportSymbol('proto.hotel.MinOption', null, global);
goog.exportSymbol('proto.hotel.NearbyLocations', null, global);
goog.exportSymbol('proto.hotel.PolicyOption', null, global);
goog.exportSymbol('proto.hotel.RateOption', null, global);
goog.exportSymbol('proto.hotel.RoomRate', null, global);
goog.exportSymbol('proto.hotel.ServiceRateOption', null, global);
goog.exportSymbol('proto.hotel.SmokingOption', null, global);
goog.exportSymbol('proto.hotel.TaxType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.CheckIn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.CheckIn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.CheckIn.displayName = 'proto.hotel.CheckIn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HotelPolicies = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.HotelPolicies.repeatedFields_, null);
};
goog.inherits(proto.hotel.HotelPolicies, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HotelPolicies.displayName = 'proto.hotel.HotelPolicies';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.CheckOut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.CheckOut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.CheckOut.displayName = 'proto.hotel.CheckOut';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.NearbyLocations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.NearbyLocations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.NearbyLocations.displayName = 'proto.hotel.NearbyLocations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HotelLocationInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.HotelLocationInfo.repeatedFields_, null);
};
goog.inherits(proto.hotel.HotelLocationInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HotelLocationInfo.displayName = 'proto.hotel.HotelLocationInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.Description = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.Description, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.Description.displayName = 'proto.hotel.Description';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HubRoom = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.HubRoom.repeatedFields_, null);
};
goog.inherits(proto.hotel.HubRoom, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HubRoom.displayName = 'proto.hotel.HubRoom';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.RoomRate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.RoomRate.repeatedFields_, null);
};
goog.inherits(proto.hotel.RoomRate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.RoomRate.displayName = 'proto.hotel.RoomRate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.MinOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.MinOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.MinOption.displayName = 'proto.hotel.MinOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.RateOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.RateOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.RateOption.displayName = 'proto.hotel.RateOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.BreakfastOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.BreakfastOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.BreakfastOption.displayName = 'proto.hotel.BreakfastOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.PolicyOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.PolicyOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.PolicyOption.displayName = 'proto.hotel.PolicyOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.ExtraBedOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.ExtraBedOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.ExtraBedOption.displayName = 'proto.hotel.ExtraBedOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.SmokingOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.SmokingOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.SmokingOption.displayName = 'proto.hotel.SmokingOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.ServiceRateOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.ServiceRateOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.ServiceRateOption.displayName = 'proto.hotel.ServiceRateOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HubRateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.HubRateData.repeatedFields_, null);
};
goog.inherits(proto.hotel.HubRateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HubRateData.displayName = 'proto.hotel.HubRateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HubOccupancyRate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.HubOccupancyRate.repeatedFields_, null);
};
goog.inherits(proto.hotel.HubOccupancyRate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HubOccupancyRate.displayName = 'proto.hotel.HubOccupancyRate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HubCancelPolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.HubCancelPolicy.repeatedFields_, null);
};
goog.inherits(proto.hotel.HubCancelPolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HubCancelPolicy.displayName = 'proto.hotel.HubCancelPolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HubPromotion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.HubPromotion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HubPromotion.displayName = 'proto.hotel.HubPromotion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HubNonrefundableDateRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.HubNonrefundableDateRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HubNonrefundableDateRange.displayName = 'proto.hotel.HubNonrefundableDateRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HubRate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.HubRate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HubRate.displayName = 'proto.hotel.HubRate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HubRateTax = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.HubRateTax, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HubRateTax.displayName = 'proto.hotel.HubRateTax';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.HotelDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.HotelDetail.repeatedFields_, null);
};
goog.inherits(proto.hotel.HotelDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.HotelDetail.displayName = 'proto.hotel.HotelDetail';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.CheckIn.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.CheckIn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.CheckIn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.CheckIn.toObject = function(includeInstance, msg) {
  var f, obj = {
    twentyFourHour: jspb.Message.getFieldWithDefault(msg, 1, ""),
    beginTime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    instructions: jspb.Message.getFieldWithDefault(msg, 4, ""),
    specialInstructions: jspb.Message.getFieldWithDefault(msg, 5, ""),
    minAge: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.CheckIn}
 */
proto.hotel.CheckIn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.CheckIn;
  return proto.hotel.CheckIn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.CheckIn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.CheckIn}
 */
proto.hotel.CheckIn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTwentyFourHour(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeginTime(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstructions(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialInstructions(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinAge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.CheckIn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.CheckIn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.CheckIn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.CheckIn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTwentyFourHour();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBeginTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInstructions();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSpecialInstructions();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMinAge();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional string twenty_four_hour = 1;
 * @return {string}
 */
proto.hotel.CheckIn.prototype.getTwentyFourHour = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.CheckIn} returns this
 */
proto.hotel.CheckIn.prototype.setTwentyFourHour = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string begin_time = 2;
 * @return {string}
 */
proto.hotel.CheckIn.prototype.getBeginTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.CheckIn} returns this
 */
proto.hotel.CheckIn.prototype.setBeginTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string end_time = 3;
 * @return {string}
 */
proto.hotel.CheckIn.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.CheckIn} returns this
 */
proto.hotel.CheckIn.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string instructions = 4;
 * @return {string}
 */
proto.hotel.CheckIn.prototype.getInstructions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.CheckIn} returns this
 */
proto.hotel.CheckIn.prototype.setInstructions = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string special_instructions = 5;
 * @return {string}
 */
proto.hotel.CheckIn.prototype.getSpecialInstructions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.CheckIn} returns this
 */
proto.hotel.CheckIn.prototype.setSpecialInstructions = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double min_age = 6;
 * @return {number}
 */
proto.hotel.CheckIn.prototype.getMinAge = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.CheckIn} returns this
 */
proto.hotel.CheckIn.prototype.setMinAge = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.HotelPolicies.repeatedFields_ = [1,2,3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HotelPolicies.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HotelPolicies.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HotelPolicies} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelPolicies.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkInRulesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    checkOutRulesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    specialCheckingInstructionsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    petRulesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    onsitePaymentsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HotelPolicies}
 */
proto.hotel.HotelPolicies.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HotelPolicies;
  return proto.hotel.HotelPolicies.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HotelPolicies} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HotelPolicies}
 */
proto.hotel.HotelPolicies.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCheckInRules(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCheckOutRules(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSpecialCheckingInstructions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addPetRules(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addOnsitePayments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HotelPolicies.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HotelPolicies.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HotelPolicies} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelPolicies.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckInRulesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCheckOutRulesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getSpecialCheckingInstructionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getPetRulesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getOnsitePaymentsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * repeated string check_in_rules = 1;
 * @return {!Array<string>}
 */
proto.hotel.HotelPolicies.prototype.getCheckInRulesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.HotelPolicies} returns this
 */
proto.hotel.HotelPolicies.prototype.setCheckInRulesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelPolicies} returns this
 */
proto.hotel.HotelPolicies.prototype.addCheckInRules = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelPolicies} returns this
 */
proto.hotel.HotelPolicies.prototype.clearCheckInRulesList = function() {
  return this.setCheckInRulesList([]);
};


/**
 * repeated string check_out_rules = 2;
 * @return {!Array<string>}
 */
proto.hotel.HotelPolicies.prototype.getCheckOutRulesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.HotelPolicies} returns this
 */
proto.hotel.HotelPolicies.prototype.setCheckOutRulesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelPolicies} returns this
 */
proto.hotel.HotelPolicies.prototype.addCheckOutRules = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelPolicies} returns this
 */
proto.hotel.HotelPolicies.prototype.clearCheckOutRulesList = function() {
  return this.setCheckOutRulesList([]);
};


/**
 * repeated string special_checking_instructions = 3;
 * @return {!Array<string>}
 */
proto.hotel.HotelPolicies.prototype.getSpecialCheckingInstructionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.HotelPolicies} returns this
 */
proto.hotel.HotelPolicies.prototype.setSpecialCheckingInstructionsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelPolicies} returns this
 */
proto.hotel.HotelPolicies.prototype.addSpecialCheckingInstructions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelPolicies} returns this
 */
proto.hotel.HotelPolicies.prototype.clearSpecialCheckingInstructionsList = function() {
  return this.setSpecialCheckingInstructionsList([]);
};


/**
 * repeated string pet_rules = 4;
 * @return {!Array<string>}
 */
proto.hotel.HotelPolicies.prototype.getPetRulesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.HotelPolicies} returns this
 */
proto.hotel.HotelPolicies.prototype.setPetRulesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelPolicies} returns this
 */
proto.hotel.HotelPolicies.prototype.addPetRules = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelPolicies} returns this
 */
proto.hotel.HotelPolicies.prototype.clearPetRulesList = function() {
  return this.setPetRulesList([]);
};


/**
 * repeated string onsite_payments = 5;
 * @return {!Array<string>}
 */
proto.hotel.HotelPolicies.prototype.getOnsitePaymentsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.HotelPolicies} returns this
 */
proto.hotel.HotelPolicies.prototype.setOnsitePaymentsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelPolicies} returns this
 */
proto.hotel.HotelPolicies.prototype.addOnsitePayments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelPolicies} returns this
 */
proto.hotel.HotelPolicies.prototype.clearOnsitePaymentsList = function() {
  return this.setOnsitePaymentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.CheckOut.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.CheckOut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.CheckOut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.CheckOut.toObject = function(includeInstance, msg) {
  var f, obj = {
    time: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.CheckOut}
 */
proto.hotel.CheckOut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.CheckOut;
  return proto.hotel.CheckOut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.CheckOut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.CheckOut}
 */
proto.hotel.CheckOut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.CheckOut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.CheckOut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.CheckOut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.CheckOut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string time = 1;
 * @return {string}
 */
proto.hotel.CheckOut.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.CheckOut} returns this
 */
proto.hotel.CheckOut.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.NearbyLocations.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.NearbyLocations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.NearbyLocations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.NearbyLocations.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    unit: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.NearbyLocations}
 */
proto.hotel.NearbyLocations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.NearbyLocations;
  return proto.hotel.NearbyLocations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.NearbyLocations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.NearbyLocations}
 */
proto.hotel.NearbyLocations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistance(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 4:
      var value = /** @type {!proto.hotel.RegionType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.NearbyLocations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.NearbyLocations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.NearbyLocations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.NearbyLocations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.hotel.NearbyLocations.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.NearbyLocations} returns this
 */
proto.hotel.NearbyLocations.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float distance = 2;
 * @return {number}
 */
proto.hotel.NearbyLocations.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.NearbyLocations} returns this
 */
proto.hotel.NearbyLocations.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string unit = 3;
 * @return {string}
 */
proto.hotel.NearbyLocations.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.NearbyLocations} returns this
 */
proto.hotel.NearbyLocations.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional RegionType type = 4;
 * @return {!proto.hotel.RegionType}
 */
proto.hotel.NearbyLocations.prototype.getType = function() {
  return /** @type {!proto.hotel.RegionType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.hotel.RegionType} value
 * @return {!proto.hotel.NearbyLocations} returns this
 */
proto.hotel.NearbyLocations.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.HotelLocationInfo.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HotelLocationInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HotelLocationInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HotelLocationInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelLocationInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    popularLocationsList: jspb.Message.toObjectList(msg.getPopularLocationsList(),
    proto.hotel.NearbyLocations.toObject, includeInstance),
    nearbyLocationsList: jspb.Message.toObjectList(msg.getNearbyLocationsList(),
    proto.hotel.NearbyLocations.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HotelLocationInfo}
 */
proto.hotel.HotelLocationInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HotelLocationInfo;
  return proto.hotel.HotelLocationInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HotelLocationInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HotelLocationInfo}
 */
proto.hotel.HotelLocationInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hotel.NearbyLocations;
      reader.readMessage(value,proto.hotel.NearbyLocations.deserializeBinaryFromReader);
      msg.addPopularLocations(value);
      break;
    case 2:
      var value = new proto.hotel.NearbyLocations;
      reader.readMessage(value,proto.hotel.NearbyLocations.deserializeBinaryFromReader);
      msg.addNearbyLocations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HotelLocationInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HotelLocationInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HotelLocationInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelLocationInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPopularLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.hotel.NearbyLocations.serializeBinaryToWriter
    );
  }
  f = message.getNearbyLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.hotel.NearbyLocations.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NearbyLocations popular_locations = 1;
 * @return {!Array<!proto.hotel.NearbyLocations>}
 */
proto.hotel.HotelLocationInfo.prototype.getPopularLocationsList = function() {
  return /** @type{!Array<!proto.hotel.NearbyLocations>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.NearbyLocations, 1));
};


/**
 * @param {!Array<!proto.hotel.NearbyLocations>} value
 * @return {!proto.hotel.HotelLocationInfo} returns this
*/
proto.hotel.HotelLocationInfo.prototype.setPopularLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.hotel.NearbyLocations=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.NearbyLocations}
 */
proto.hotel.HotelLocationInfo.prototype.addPopularLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.hotel.NearbyLocations, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelLocationInfo} returns this
 */
proto.hotel.HotelLocationInfo.prototype.clearPopularLocationsList = function() {
  return this.setPopularLocationsList([]);
};


/**
 * repeated NearbyLocations nearby_locations = 2;
 * @return {!Array<!proto.hotel.NearbyLocations>}
 */
proto.hotel.HotelLocationInfo.prototype.getNearbyLocationsList = function() {
  return /** @type{!Array<!proto.hotel.NearbyLocations>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.NearbyLocations, 2));
};


/**
 * @param {!Array<!proto.hotel.NearbyLocations>} value
 * @return {!proto.hotel.HotelLocationInfo} returns this
*/
proto.hotel.HotelLocationInfo.prototype.setNearbyLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hotel.NearbyLocations=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.NearbyLocations}
 */
proto.hotel.HotelLocationInfo.prototype.addNearbyLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hotel.NearbyLocations, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelLocationInfo} returns this
 */
proto.hotel.HotelLocationInfo.prototype.clearNearbyLocationsList = function() {
  return this.setNearbyLocationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.Description.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.Description.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.Description} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Description.toObject = function(includeInstance, msg) {
  var f, obj = {
    amenities: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dining: jspb.Message.getFieldWithDefault(msg, 2, ""),
    renovations: jspb.Message.getFieldWithDefault(msg, 3, ""),
    nationalRatings: jspb.Message.getFieldWithDefault(msg, 4, ""),
    businessAmenities: jspb.Message.getFieldWithDefault(msg, 5, ""),
    rooms: jspb.Message.getFieldWithDefault(msg, 6, ""),
    attractions: jspb.Message.getFieldWithDefault(msg, 7, ""),
    location: jspb.Message.getFieldWithDefault(msg, 8, ""),
    headline: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.Description}
 */
proto.hotel.Description.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.Description;
  return proto.hotel.Description.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.Description} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.Description}
 */
proto.hotel.Description.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmenities(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDining(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRenovations(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationalRatings(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessAmenities(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRooms(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttractions(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeadline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.Description.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.Description.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.Description} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.Description.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmenities();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDining();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRenovations();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNationalRatings();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBusinessAmenities();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRooms();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAttractions();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getHeadline();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string amenities = 1;
 * @return {string}
 */
proto.hotel.Description.prototype.getAmenities = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Description} returns this
 */
proto.hotel.Description.prototype.setAmenities = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dining = 2;
 * @return {string}
 */
proto.hotel.Description.prototype.getDining = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Description} returns this
 */
proto.hotel.Description.prototype.setDining = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string renovations = 3;
 * @return {string}
 */
proto.hotel.Description.prototype.getRenovations = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Description} returns this
 */
proto.hotel.Description.prototype.setRenovations = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string national_ratings = 4;
 * @return {string}
 */
proto.hotel.Description.prototype.getNationalRatings = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Description} returns this
 */
proto.hotel.Description.prototype.setNationalRatings = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string business_amenities = 5;
 * @return {string}
 */
proto.hotel.Description.prototype.getBusinessAmenities = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Description} returns this
 */
proto.hotel.Description.prototype.setBusinessAmenities = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string rooms = 6;
 * @return {string}
 */
proto.hotel.Description.prototype.getRooms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Description} returns this
 */
proto.hotel.Description.prototype.setRooms = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string attractions = 7;
 * @return {string}
 */
proto.hotel.Description.prototype.getAttractions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Description} returns this
 */
proto.hotel.Description.prototype.setAttractions = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string location = 8;
 * @return {string}
 */
proto.hotel.Description.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Description} returns this
 */
proto.hotel.Description.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string headline = 9;
 * @return {string}
 */
proto.hotel.Description.prototype.getHeadline = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.Description} returns this
 */
proto.hotel.Description.prototype.setHeadline = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.HubRoom.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HubRoom.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HubRoom.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HubRoom} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubRoom.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rateDataList: jspb.Message.toObjectList(msg.getRateDataList(),
    proto.hotel.HubRateData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HubRoom}
 */
proto.hotel.HubRoom.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HubRoom;
  return proto.hotel.HubRoom.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HubRoom} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HubRoom}
 */
proto.hotel.HubRoom.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.hotel.HubRateData;
      reader.readMessage(value,proto.hotel.HubRateData.deserializeBinaryFromReader);
      msg.addRateData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HubRoom.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HubRoom.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HubRoom} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubRoom.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRateDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.hotel.HubRateData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.hotel.HubRoom.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubRoom} returns this
 */
proto.hotel.HubRoom.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hotel.HubRoom.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubRoom} returns this
 */
proto.hotel.HubRoom.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated HubRateData rate_data = 3;
 * @return {!Array<!proto.hotel.HubRateData>}
 */
proto.hotel.HubRoom.prototype.getRateDataList = function() {
  return /** @type{!Array<!proto.hotel.HubRateData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.HubRateData, 3));
};


/**
 * @param {!Array<!proto.hotel.HubRateData>} value
 * @return {!proto.hotel.HubRoom} returns this
*/
proto.hotel.HubRoom.prototype.setRateDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.hotel.HubRateData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubRateData}
 */
proto.hotel.HubRoom.prototype.addRateData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.hotel.HubRateData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubRoom} returns this
 */
proto.hotel.HubRoom.prototype.clearRateDataList = function() {
  return this.setRateDataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.RoomRate.repeatedFields_ = [4,5,8,9,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.RoomRate.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.RoomRate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.RoomRate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.RoomRate.toObject = function(includeInstance, msg) {
  var f, obj = {
    roomId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    viewsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    hotel_base_pb.ImageData.toObject, includeInstance),
    area: (f = msg.getArea()) && hotel_base_pb.Area.toObject(includeInstance, f),
    occupancy: (f = msg.getOccupancy()) && hotel_base_pb.RoomOccupancy.toObject(includeInstance, f),
    bedOptionsList: jspb.Message.toObjectList(msg.getBedOptionsList(),
    hotel_base_pb.BedOption.toObject, includeInstance),
    amenityGroupsList: jspb.Message.toObjectList(msg.getAmenityGroupsList(),
    hotel_base_pb.HubGroupAmenity.toObject, includeInstance),
    option: (f = msg.getOption()) && proto.hotel.RateOption.toObject(includeInstance, f),
    rateDataList: jspb.Message.toObjectList(msg.getRateDataList(),
    proto.hotel.HubRateData.toObject, includeInstance),
    minOption: (f = msg.getMinOption()) && proto.hotel.MinOption.toObject(includeInstance, f),
    hasBreakfastFullRefundMinFeeName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    hasBreakfastFullRefundMaxFeeName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    nonBreakfastFullRefundMinFeeName: jspb.Message.getFieldWithDefault(msg, 15, ""),
    nonBreakfastFullRefundMaxFeeName: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.RoomRate}
 */
proto.hotel.RoomRate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.RoomRate;
  return proto.hotel.RoomRate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.RoomRate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.RoomRate}
 */
proto.hotel.RoomRate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addViews(value);
      break;
    case 5:
      var value = new hotel_base_pb.ImageData;
      reader.readMessage(value,hotel_base_pb.ImageData.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 6:
      var value = new hotel_base_pb.Area;
      reader.readMessage(value,hotel_base_pb.Area.deserializeBinaryFromReader);
      msg.setArea(value);
      break;
    case 7:
      var value = new hotel_base_pb.RoomOccupancy;
      reader.readMessage(value,hotel_base_pb.RoomOccupancy.deserializeBinaryFromReader);
      msg.setOccupancy(value);
      break;
    case 8:
      var value = new hotel_base_pb.BedOption;
      reader.readMessage(value,hotel_base_pb.BedOption.deserializeBinaryFromReader);
      msg.addBedOptions(value);
      break;
    case 9:
      var value = new hotel_base_pb.HubGroupAmenity;
      reader.readMessage(value,hotel_base_pb.HubGroupAmenity.deserializeBinaryFromReader);
      msg.addAmenityGroups(value);
      break;
    case 10:
      var value = new proto.hotel.RateOption;
      reader.readMessage(value,proto.hotel.RateOption.deserializeBinaryFromReader);
      msg.setOption(value);
      break;
    case 11:
      var value = new proto.hotel.HubRateData;
      reader.readMessage(value,proto.hotel.HubRateData.deserializeBinaryFromReader);
      msg.addRateData(value);
      break;
    case 12:
      var value = new proto.hotel.MinOption;
      reader.readMessage(value,proto.hotel.MinOption.deserializeBinaryFromReader);
      msg.setMinOption(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setHasBreakfastFullRefundMinFeeName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setHasBreakfastFullRefundMaxFeeName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setNonBreakfastFullRefundMinFeeName(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setNonBreakfastFullRefundMaxFeeName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.RoomRate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.RoomRate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.RoomRate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.RoomRate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getViewsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      hotel_base_pb.ImageData.serializeBinaryToWriter
    );
  }
  f = message.getArea();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      hotel_base_pb.Area.serializeBinaryToWriter
    );
  }
  f = message.getOccupancy();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      hotel_base_pb.RoomOccupancy.serializeBinaryToWriter
    );
  }
  f = message.getBedOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      hotel_base_pb.BedOption.serializeBinaryToWriter
    );
  }
  f = message.getAmenityGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      hotel_base_pb.HubGroupAmenity.serializeBinaryToWriter
    );
  }
  f = message.getOption();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.hotel.RateOption.serializeBinaryToWriter
    );
  }
  f = message.getRateDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.hotel.HubRateData.serializeBinaryToWriter
    );
  }
  f = message.getMinOption();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.hotel.MinOption.serializeBinaryToWriter
    );
  }
  f = message.getHasBreakfastFullRefundMinFeeName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHasBreakfastFullRefundMaxFeeName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getNonBreakfastFullRefundMinFeeName();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getNonBreakfastFullRefundMaxFeeName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string room_id = 1;
 * @return {string}
 */
proto.hotel.RoomRate.prototype.getRoomId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.setRoomId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.hotel.RoomRate.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.hotel.RoomRate.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string views = 4;
 * @return {!Array<string>}
 */
proto.hotel.RoomRate.prototype.getViewsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.setViewsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.addViews = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.clearViewsList = function() {
  return this.setViewsList([]);
};


/**
 * repeated ImageData images = 5;
 * @return {!Array<!proto.hotel.ImageData>}
 */
proto.hotel.RoomRate.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.hotel.ImageData>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.ImageData, 5));
};


/**
 * @param {!Array<!proto.hotel.ImageData>} value
 * @return {!proto.hotel.RoomRate} returns this
*/
proto.hotel.RoomRate.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.hotel.ImageData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.ImageData}
 */
proto.hotel.RoomRate.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.hotel.ImageData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional Area area = 6;
 * @return {?proto.hotel.Area}
 */
proto.hotel.RoomRate.prototype.getArea = function() {
  return /** @type{?proto.hotel.Area} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.Area, 6));
};


/**
 * @param {?proto.hotel.Area|undefined} value
 * @return {!proto.hotel.RoomRate} returns this
*/
proto.hotel.RoomRate.prototype.setArea = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.clearArea = function() {
  return this.setArea(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.RoomRate.prototype.hasArea = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional RoomOccupancy occupancy = 7;
 * @return {?proto.hotel.RoomOccupancy}
 */
proto.hotel.RoomRate.prototype.getOccupancy = function() {
  return /** @type{?proto.hotel.RoomOccupancy} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.RoomOccupancy, 7));
};


/**
 * @param {?proto.hotel.RoomOccupancy|undefined} value
 * @return {!proto.hotel.RoomRate} returns this
*/
proto.hotel.RoomRate.prototype.setOccupancy = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.clearOccupancy = function() {
  return this.setOccupancy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.RoomRate.prototype.hasOccupancy = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated BedOption bed_options = 8;
 * @return {!Array<!proto.hotel.BedOption>}
 */
proto.hotel.RoomRate.prototype.getBedOptionsList = function() {
  return /** @type{!Array<!proto.hotel.BedOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.BedOption, 8));
};


/**
 * @param {!Array<!proto.hotel.BedOption>} value
 * @return {!proto.hotel.RoomRate} returns this
*/
proto.hotel.RoomRate.prototype.setBedOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.hotel.BedOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.BedOption}
 */
proto.hotel.RoomRate.prototype.addBedOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.hotel.BedOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.clearBedOptionsList = function() {
  return this.setBedOptionsList([]);
};


/**
 * repeated HubGroupAmenity amenity_groups = 9;
 * @return {!Array<!proto.hotel.HubGroupAmenity>}
 */
proto.hotel.RoomRate.prototype.getAmenityGroupsList = function() {
  return /** @type{!Array<!proto.hotel.HubGroupAmenity>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.HubGroupAmenity, 9));
};


/**
 * @param {!Array<!proto.hotel.HubGroupAmenity>} value
 * @return {!proto.hotel.RoomRate} returns this
*/
proto.hotel.RoomRate.prototype.setAmenityGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.hotel.HubGroupAmenity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubGroupAmenity}
 */
proto.hotel.RoomRate.prototype.addAmenityGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.hotel.HubGroupAmenity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.clearAmenityGroupsList = function() {
  return this.setAmenityGroupsList([]);
};


/**
 * optional RateOption option = 10;
 * @return {?proto.hotel.RateOption}
 */
proto.hotel.RoomRate.prototype.getOption = function() {
  return /** @type{?proto.hotel.RateOption} */ (
    jspb.Message.getWrapperField(this, proto.hotel.RateOption, 10));
};


/**
 * @param {?proto.hotel.RateOption|undefined} value
 * @return {!proto.hotel.RoomRate} returns this
*/
proto.hotel.RoomRate.prototype.setOption = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.clearOption = function() {
  return this.setOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.RoomRate.prototype.hasOption = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated HubRateData rate_data = 11;
 * @return {!Array<!proto.hotel.HubRateData>}
 */
proto.hotel.RoomRate.prototype.getRateDataList = function() {
  return /** @type{!Array<!proto.hotel.HubRateData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.HubRateData, 11));
};


/**
 * @param {!Array<!proto.hotel.HubRateData>} value
 * @return {!proto.hotel.RoomRate} returns this
*/
proto.hotel.RoomRate.prototype.setRateDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.hotel.HubRateData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubRateData}
 */
proto.hotel.RoomRate.prototype.addRateData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.hotel.HubRateData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.clearRateDataList = function() {
  return this.setRateDataList([]);
};


/**
 * optional MinOption min_option = 12;
 * @return {?proto.hotel.MinOption}
 */
proto.hotel.RoomRate.prototype.getMinOption = function() {
  return /** @type{?proto.hotel.MinOption} */ (
    jspb.Message.getWrapperField(this, proto.hotel.MinOption, 12));
};


/**
 * @param {?proto.hotel.MinOption|undefined} value
 * @return {!proto.hotel.RoomRate} returns this
*/
proto.hotel.RoomRate.prototype.setMinOption = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.clearMinOption = function() {
  return this.setMinOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.RoomRate.prototype.hasMinOption = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string has_breakfast_full_refund_min_fee_name = 13;
 * @return {string}
 */
proto.hotel.RoomRate.prototype.getHasBreakfastFullRefundMinFeeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.setHasBreakfastFullRefundMinFeeName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string has_breakfast_full_refund_max_fee_name = 14;
 * @return {string}
 */
proto.hotel.RoomRate.prototype.getHasBreakfastFullRefundMaxFeeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.setHasBreakfastFullRefundMaxFeeName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string non_breakfast_full_refund_min_fee_name = 15;
 * @return {string}
 */
proto.hotel.RoomRate.prototype.getNonBreakfastFullRefundMinFeeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.setNonBreakfastFullRefundMinFeeName = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string non_breakfast_full_refund_max_fee_name = 16;
 * @return {string}
 */
proto.hotel.RoomRate.prototype.getNonBreakfastFullRefundMaxFeeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.RoomRate} returns this
 */
proto.hotel.RoomRate.prototype.setNonBreakfastFullRefundMaxFeeName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.MinOption.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.MinOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.MinOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.MinOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasBreakfast: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    cancelPolicy: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hasExtraBed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    hasSmoking: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.MinOption}
 */
proto.hotel.MinOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.MinOption;
  return proto.hotel.MinOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.MinOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.MinOption}
 */
proto.hotel.MinOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasBreakfast(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCancelPolicy(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasExtraBed(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSmoking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.MinOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.MinOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.MinOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.MinOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasBreakfast();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCancelPolicy();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getHasExtraBed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHasSmoking();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool has_breakfast = 1;
 * @return {boolean}
 */
proto.hotel.MinOption.prototype.getHasBreakfast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.MinOption} returns this
 */
proto.hotel.MinOption.prototype.setHasBreakfast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 cancel_policy = 2;
 * @return {number}
 */
proto.hotel.MinOption.prototype.getCancelPolicy = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.MinOption} returns this
 */
proto.hotel.MinOption.prototype.setCancelPolicy = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool has_extra_bed = 3;
 * @return {boolean}
 */
proto.hotel.MinOption.prototype.getHasExtraBed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.MinOption} returns this
 */
proto.hotel.MinOption.prototype.setHasExtraBed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool has_smoking = 4;
 * @return {boolean}
 */
proto.hotel.MinOption.prototype.getHasSmoking = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.MinOption} returns this
 */
proto.hotel.MinOption.prototype.setHasSmoking = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.RateOption.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.RateOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.RateOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.RateOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasBreakfast: (f = msg.getHasBreakfast()) && proto.hotel.BreakfastOption.toObject(includeInstance, f),
    nonBreakfast: (f = msg.getNonBreakfast()) && proto.hotel.BreakfastOption.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.RateOption}
 */
proto.hotel.RateOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.RateOption;
  return proto.hotel.RateOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.RateOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.RateOption}
 */
proto.hotel.RateOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hotel.BreakfastOption;
      reader.readMessage(value,proto.hotel.BreakfastOption.deserializeBinaryFromReader);
      msg.setHasBreakfast(value);
      break;
    case 2:
      var value = new proto.hotel.BreakfastOption;
      reader.readMessage(value,proto.hotel.BreakfastOption.deserializeBinaryFromReader);
      msg.setNonBreakfast(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.RateOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.RateOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.RateOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.RateOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasBreakfast();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hotel.BreakfastOption.serializeBinaryToWriter
    );
  }
  f = message.getNonBreakfast();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.hotel.BreakfastOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional BreakfastOption has_breakfast = 1;
 * @return {?proto.hotel.BreakfastOption}
 */
proto.hotel.RateOption.prototype.getHasBreakfast = function() {
  return /** @type{?proto.hotel.BreakfastOption} */ (
    jspb.Message.getWrapperField(this, proto.hotel.BreakfastOption, 1));
};


/**
 * @param {?proto.hotel.BreakfastOption|undefined} value
 * @return {!proto.hotel.RateOption} returns this
*/
proto.hotel.RateOption.prototype.setHasBreakfast = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.RateOption} returns this
 */
proto.hotel.RateOption.prototype.clearHasBreakfast = function() {
  return this.setHasBreakfast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.RateOption.prototype.hasHasBreakfast = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional BreakfastOption non_breakfast = 2;
 * @return {?proto.hotel.BreakfastOption}
 */
proto.hotel.RateOption.prototype.getNonBreakfast = function() {
  return /** @type{?proto.hotel.BreakfastOption} */ (
    jspb.Message.getWrapperField(this, proto.hotel.BreakfastOption, 2));
};


/**
 * @param {?proto.hotel.BreakfastOption|undefined} value
 * @return {!proto.hotel.RateOption} returns this
*/
proto.hotel.RateOption.prototype.setNonBreakfast = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.RateOption} returns this
 */
proto.hotel.RateOption.prototype.clearNonBreakfast = function() {
  return this.setNonBreakfast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.RateOption.prototype.hasNonBreakfast = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.BreakfastOption.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.BreakfastOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.BreakfastOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BreakfastOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    nonRefund: (f = msg.getNonRefund()) && proto.hotel.PolicyOption.toObject(includeInstance, f),
    fullRefundMinFee: (f = msg.getFullRefundMinFee()) && proto.hotel.PolicyOption.toObject(includeInstance, f),
    fullRefundMaxFee: (f = msg.getFullRefundMaxFee()) && proto.hotel.PolicyOption.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.BreakfastOption}
 */
proto.hotel.BreakfastOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.BreakfastOption;
  return proto.hotel.BreakfastOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.BreakfastOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.BreakfastOption}
 */
proto.hotel.BreakfastOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hotel.PolicyOption;
      reader.readMessage(value,proto.hotel.PolicyOption.deserializeBinaryFromReader);
      msg.setNonRefund(value);
      break;
    case 2:
      var value = new proto.hotel.PolicyOption;
      reader.readMessage(value,proto.hotel.PolicyOption.deserializeBinaryFromReader);
      msg.setFullRefundMinFee(value);
      break;
    case 3:
      var value = new proto.hotel.PolicyOption;
      reader.readMessage(value,proto.hotel.PolicyOption.deserializeBinaryFromReader);
      msg.setFullRefundMaxFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.BreakfastOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.BreakfastOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.BreakfastOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BreakfastOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNonRefund();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hotel.PolicyOption.serializeBinaryToWriter
    );
  }
  f = message.getFullRefundMinFee();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.hotel.PolicyOption.serializeBinaryToWriter
    );
  }
  f = message.getFullRefundMaxFee();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.hotel.PolicyOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional PolicyOption non_refund = 1;
 * @return {?proto.hotel.PolicyOption}
 */
proto.hotel.BreakfastOption.prototype.getNonRefund = function() {
  return /** @type{?proto.hotel.PolicyOption} */ (
    jspb.Message.getWrapperField(this, proto.hotel.PolicyOption, 1));
};


/**
 * @param {?proto.hotel.PolicyOption|undefined} value
 * @return {!proto.hotel.BreakfastOption} returns this
*/
proto.hotel.BreakfastOption.prototype.setNonRefund = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BreakfastOption} returns this
 */
proto.hotel.BreakfastOption.prototype.clearNonRefund = function() {
  return this.setNonRefund(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BreakfastOption.prototype.hasNonRefund = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PolicyOption full_refund_min_fee = 2;
 * @return {?proto.hotel.PolicyOption}
 */
proto.hotel.BreakfastOption.prototype.getFullRefundMinFee = function() {
  return /** @type{?proto.hotel.PolicyOption} */ (
    jspb.Message.getWrapperField(this, proto.hotel.PolicyOption, 2));
};


/**
 * @param {?proto.hotel.PolicyOption|undefined} value
 * @return {!proto.hotel.BreakfastOption} returns this
*/
proto.hotel.BreakfastOption.prototype.setFullRefundMinFee = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BreakfastOption} returns this
 */
proto.hotel.BreakfastOption.prototype.clearFullRefundMinFee = function() {
  return this.setFullRefundMinFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BreakfastOption.prototype.hasFullRefundMinFee = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PolicyOption full_refund_max_fee = 3;
 * @return {?proto.hotel.PolicyOption}
 */
proto.hotel.BreakfastOption.prototype.getFullRefundMaxFee = function() {
  return /** @type{?proto.hotel.PolicyOption} */ (
    jspb.Message.getWrapperField(this, proto.hotel.PolicyOption, 3));
};


/**
 * @param {?proto.hotel.PolicyOption|undefined} value
 * @return {!proto.hotel.BreakfastOption} returns this
*/
proto.hotel.BreakfastOption.prototype.setFullRefundMaxFee = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BreakfastOption} returns this
 */
proto.hotel.BreakfastOption.prototype.clearFullRefundMaxFee = function() {
  return this.setFullRefundMaxFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BreakfastOption.prototype.hasFullRefundMaxFee = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.PolicyOption.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.PolicyOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.PolicyOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PolicyOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasExtraBeds: (f = msg.getHasExtraBeds()) && proto.hotel.ExtraBedOption.toObject(includeInstance, f),
    nonExtraBeds: (f = msg.getNonExtraBeds()) && proto.hotel.ExtraBedOption.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.PolicyOption}
 */
proto.hotel.PolicyOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.PolicyOption;
  return proto.hotel.PolicyOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.PolicyOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.PolicyOption}
 */
proto.hotel.PolicyOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hotel.ExtraBedOption;
      reader.readMessage(value,proto.hotel.ExtraBedOption.deserializeBinaryFromReader);
      msg.setHasExtraBeds(value);
      break;
    case 2:
      var value = new proto.hotel.ExtraBedOption;
      reader.readMessage(value,proto.hotel.ExtraBedOption.deserializeBinaryFromReader);
      msg.setNonExtraBeds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.PolicyOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.PolicyOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.PolicyOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.PolicyOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasExtraBeds();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hotel.ExtraBedOption.serializeBinaryToWriter
    );
  }
  f = message.getNonExtraBeds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.hotel.ExtraBedOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExtraBedOption has_extra_beds = 1;
 * @return {?proto.hotel.ExtraBedOption}
 */
proto.hotel.PolicyOption.prototype.getHasExtraBeds = function() {
  return /** @type{?proto.hotel.ExtraBedOption} */ (
    jspb.Message.getWrapperField(this, proto.hotel.ExtraBedOption, 1));
};


/**
 * @param {?proto.hotel.ExtraBedOption|undefined} value
 * @return {!proto.hotel.PolicyOption} returns this
*/
proto.hotel.PolicyOption.prototype.setHasExtraBeds = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.PolicyOption} returns this
 */
proto.hotel.PolicyOption.prototype.clearHasExtraBeds = function() {
  return this.setHasExtraBeds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.PolicyOption.prototype.hasHasExtraBeds = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ExtraBedOption non_extra_beds = 2;
 * @return {?proto.hotel.ExtraBedOption}
 */
proto.hotel.PolicyOption.prototype.getNonExtraBeds = function() {
  return /** @type{?proto.hotel.ExtraBedOption} */ (
    jspb.Message.getWrapperField(this, proto.hotel.ExtraBedOption, 2));
};


/**
 * @param {?proto.hotel.ExtraBedOption|undefined} value
 * @return {!proto.hotel.PolicyOption} returns this
*/
proto.hotel.PolicyOption.prototype.setNonExtraBeds = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.PolicyOption} returns this
 */
proto.hotel.PolicyOption.prototype.clearNonExtraBeds = function() {
  return this.setNonExtraBeds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.PolicyOption.prototype.hasNonExtraBeds = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.ExtraBedOption.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.ExtraBedOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.ExtraBedOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.ExtraBedOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasSmoking: (f = msg.getHasSmoking()) && proto.hotel.SmokingOption.toObject(includeInstance, f),
    nonSmoking: (f = msg.getNonSmoking()) && proto.hotel.SmokingOption.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.ExtraBedOption}
 */
proto.hotel.ExtraBedOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.ExtraBedOption;
  return proto.hotel.ExtraBedOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.ExtraBedOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.ExtraBedOption}
 */
proto.hotel.ExtraBedOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hotel.SmokingOption;
      reader.readMessage(value,proto.hotel.SmokingOption.deserializeBinaryFromReader);
      msg.setHasSmoking(value);
      break;
    case 2:
      var value = new proto.hotel.SmokingOption;
      reader.readMessage(value,proto.hotel.SmokingOption.deserializeBinaryFromReader);
      msg.setNonSmoking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.ExtraBedOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.ExtraBedOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.ExtraBedOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.ExtraBedOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasSmoking();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hotel.SmokingOption.serializeBinaryToWriter
    );
  }
  f = message.getNonSmoking();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.hotel.SmokingOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional SmokingOption has_smoking = 1;
 * @return {?proto.hotel.SmokingOption}
 */
proto.hotel.ExtraBedOption.prototype.getHasSmoking = function() {
  return /** @type{?proto.hotel.SmokingOption} */ (
    jspb.Message.getWrapperField(this, proto.hotel.SmokingOption, 1));
};


/**
 * @param {?proto.hotel.SmokingOption|undefined} value
 * @return {!proto.hotel.ExtraBedOption} returns this
*/
proto.hotel.ExtraBedOption.prototype.setHasSmoking = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.ExtraBedOption} returns this
 */
proto.hotel.ExtraBedOption.prototype.clearHasSmoking = function() {
  return this.setHasSmoking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.ExtraBedOption.prototype.hasHasSmoking = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SmokingOption non_smoking = 2;
 * @return {?proto.hotel.SmokingOption}
 */
proto.hotel.ExtraBedOption.prototype.getNonSmoking = function() {
  return /** @type{?proto.hotel.SmokingOption} */ (
    jspb.Message.getWrapperField(this, proto.hotel.SmokingOption, 2));
};


/**
 * @param {?proto.hotel.SmokingOption|undefined} value
 * @return {!proto.hotel.ExtraBedOption} returns this
*/
proto.hotel.ExtraBedOption.prototype.setNonSmoking = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.ExtraBedOption} returns this
 */
proto.hotel.ExtraBedOption.prototype.clearNonSmoking = function() {
  return this.setNonSmoking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.ExtraBedOption.prototype.hasNonSmoking = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.SmokingOption.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.SmokingOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.SmokingOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.SmokingOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    rateData: (f = msg.getRateData()) && proto.hotel.HubRateData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.SmokingOption}
 */
proto.hotel.SmokingOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.SmokingOption;
  return proto.hotel.SmokingOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.SmokingOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.SmokingOption}
 */
proto.hotel.SmokingOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.hotel.HubRateData;
      reader.readMessage(value,proto.hotel.HubRateData.deserializeBinaryFromReader);
      msg.setRateData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.SmokingOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.SmokingOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.SmokingOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.SmokingOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRateData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.hotel.HubRateData.serializeBinaryToWriter
    );
  }
};


/**
 * optional HubRateData rate_data = 1;
 * @return {?proto.hotel.HubRateData}
 */
proto.hotel.SmokingOption.prototype.getRateData = function() {
  return /** @type{?proto.hotel.HubRateData} */ (
    jspb.Message.getWrapperField(this, proto.hotel.HubRateData, 1));
};


/**
 * @param {?proto.hotel.HubRateData|undefined} value
 * @return {!proto.hotel.SmokingOption} returns this
*/
proto.hotel.SmokingOption.prototype.setRateData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.SmokingOption} returns this
 */
proto.hotel.SmokingOption.prototype.clearRateData = function() {
  return this.setRateData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.SmokingOption.prototype.hasRateData = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.ServiceRateOption.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.ServiceRateOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.ServiceRateOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.ServiceRateOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    rateData: (f = msg.getRateData()) && proto.hotel.HubRateData.toObject(includeInstance, f),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.ServiceRateOption}
 */
proto.hotel.ServiceRateOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.ServiceRateOption;
  return proto.hotel.ServiceRateOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.ServiceRateOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.ServiceRateOption}
 */
proto.hotel.ServiceRateOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAmount(value);
      break;
    case 3:
      var value = new proto.hotel.HubRateData;
      reader.readMessage(value,proto.hotel.HubRateData.deserializeBinaryFromReader);
      msg.setRateData(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.ServiceRateOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.ServiceRateOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.ServiceRateOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.ServiceRateOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getRateData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.hotel.HubRateData.serializeBinaryToWriter
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.hotel.ServiceRateOption.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.ServiceRateOption} returns this
 */
proto.hotel.ServiceRateOption.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float amount = 2;
 * @return {number}
 */
proto.hotel.ServiceRateOption.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.ServiceRateOption} returns this
 */
proto.hotel.ServiceRateOption.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional HubRateData rate_data = 3;
 * @return {?proto.hotel.HubRateData}
 */
proto.hotel.ServiceRateOption.prototype.getRateData = function() {
  return /** @type{?proto.hotel.HubRateData} */ (
    jspb.Message.getWrapperField(this, proto.hotel.HubRateData, 3));
};


/**
 * @param {?proto.hotel.HubRateData|undefined} value
 * @return {!proto.hotel.ServiceRateOption} returns this
*/
proto.hotel.ServiceRateOption.prototype.setRateData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.ServiceRateOption} returns this
 */
proto.hotel.ServiceRateOption.prototype.clearRateData = function() {
  return this.setRateData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.ServiceRateOption.prototype.hasRateData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool enabled = 4;
 * @return {boolean}
 */
proto.hotel.ServiceRateOption.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.ServiceRateOption} returns this
 */
proto.hotel.ServiceRateOption.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.HubRateData.repeatedFields_ = [5,6,7,8,12,13,15,18,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HubRateData.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HubRateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HubRateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubRateData.toObject = function(includeInstance, msg) {
  var f, obj = {
    rateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    boardCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    boardName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    available: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cancelPoliciesList: jspb.Message.toObjectList(msg.getCancelPoliciesList(),
    proto.hotel.HubCancelPolicy.toObject, includeInstance),
    paymentTypesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    promotionsList: jspb.Message.toObjectList(msg.getPromotionsList(),
    proto.hotel.HubPromotion.toObject, includeInstance),
    occupancyRateList: jspb.Message.toObjectList(msg.getOccupancyRateList(),
    proto.hotel.HubOccupancyRate.toObject, includeInstance),
    currency: jspb.Message.getFieldWithDefault(msg, 9, ""),
    payNow: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    totalPayAtHotel: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    bedOptionsList: jspb.Message.toObjectList(msg.getBedOptionsList(),
    hotel_base_pb.BedOption.toObject, includeInstance),
    amenitiesList: jspb.Message.toObjectList(msg.getAmenitiesList(),
    hotel_base_pb.HubAmenity.toObject, includeInstance),
    refundable: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    payAtHotelList: jspb.Message.toObjectList(msg.getPayAtHotelList(),
    hotel_base_pb.PayAtHotel.toObject, includeInstance),
    totalRateBasic: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    totalTaxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    displayCancelPoliciesList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
    displayAmenitiesList: (f = jspb.Message.getRepeatedField(msg, 19)) == null ? undefined : f,
    commission: (f = msg.getCommission()) && hotel_base_pb.PriceAdjustmentResponse.toObject(includeInstance, f),
    discount: (f = msg.getDiscount()) && hotel_base_pb.PriceAdjustmentResponse.toObject(includeInstance, f),
    payAtHotelCurrency: jspb.Message.getFieldWithDefault(msg, 22, ""),
    optionCancelPolicy: jspb.Message.getFieldWithDefault(msg, 23, ""),
    optionService: jspb.Message.getFieldWithDefault(msg, 24, ""),
    isHotelPackage: jspb.Message.getBooleanFieldWithDefault(msg, 25, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HubRateData}
 */
proto.hotel.HubRateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HubRateData;
  return proto.hotel.HubRateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HubRateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HubRateData}
 */
proto.hotel.HubRateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRateId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBoardCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBoardName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailable(value);
      break;
    case 5:
      var value = new proto.hotel.HubCancelPolicy;
      reader.readMessage(value,proto.hotel.HubCancelPolicy.deserializeBinaryFromReader);
      msg.addCancelPolicies(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.hotel.HotelPaymentType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPaymentTypes(values[i]);
      }
      break;
    case 7:
      var value = new proto.hotel.HubPromotion;
      reader.readMessage(value,proto.hotel.HubPromotion.deserializeBinaryFromReader);
      msg.addPromotions(value);
      break;
    case 8:
      var value = new proto.hotel.HubOccupancyRate;
      reader.readMessage(value,proto.hotel.HubOccupancyRate.deserializeBinaryFromReader);
      msg.addOccupancyRate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPayNow(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPayAtHotel(value);
      break;
    case 12:
      var value = new hotel_base_pb.BedOption;
      reader.readMessage(value,hotel_base_pb.BedOption.deserializeBinaryFromReader);
      msg.addBedOptions(value);
      break;
    case 13:
      var value = new hotel_base_pb.HubAmenity;
      reader.readMessage(value,hotel_base_pb.HubAmenity.deserializeBinaryFromReader);
      msg.addAmenities(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRefundable(value);
      break;
    case 15:
      var value = new hotel_base_pb.PayAtHotel;
      reader.readMessage(value,hotel_base_pb.PayAtHotel.deserializeBinaryFromReader);
      msg.addPayAtHotel(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalRateBasic(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalTaxAmount(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.addDisplayCancelPolicies(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.addDisplayAmenities(value);
      break;
    case 20:
      var value = new hotel_base_pb.PriceAdjustmentResponse;
      reader.readMessage(value,hotel_base_pb.PriceAdjustmentResponse.deserializeBinaryFromReader);
      msg.setCommission(value);
      break;
    case 21:
      var value = new hotel_base_pb.PriceAdjustmentResponse;
      reader.readMessage(value,hotel_base_pb.PriceAdjustmentResponse.deserializeBinaryFromReader);
      msg.setDiscount(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayAtHotelCurrency(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionCancelPolicy(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionService(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHotelPackage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HubRateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HubRateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HubRateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubRateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBoardCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBoardName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAvailable();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCancelPoliciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.hotel.HubCancelPolicy.serializeBinaryToWriter
    );
  }
  f = message.getPaymentTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getPromotionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.hotel.HubPromotion.serializeBinaryToWriter
    );
  }
  f = message.getOccupancyRateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.hotel.HubOccupancyRate.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPayNow();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getTotalPayAtHotel();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getBedOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      hotel_base_pb.BedOption.serializeBinaryToWriter
    );
  }
  f = message.getAmenitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      hotel_base_pb.HubAmenity.serializeBinaryToWriter
    );
  }
  f = message.getRefundable();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getPayAtHotelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      hotel_base_pb.PayAtHotel.serializeBinaryToWriter
    );
  }
  f = message.getTotalRateBasic();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getTotalTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getDisplayCancelPoliciesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      18,
      f
    );
  }
  f = message.getDisplayAmenitiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      19,
      f
    );
  }
  f = message.getCommission();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      hotel_base_pb.PriceAdjustmentResponse.serializeBinaryToWriter
    );
  }
  f = message.getDiscount();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      hotel_base_pb.PriceAdjustmentResponse.serializeBinaryToWriter
    );
  }
  f = message.getPayAtHotelCurrency();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getOptionCancelPolicy();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getOptionService();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getIsHotelPackage();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
};


/**
 * optional string rate_id = 1;
 * @return {string}
 */
proto.hotel.HubRateData.prototype.getRateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setRateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string board_code = 2;
 * @return {string}
 */
proto.hotel.HubRateData.prototype.getBoardCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setBoardCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string board_name = 3;
 * @return {string}
 */
proto.hotel.HubRateData.prototype.getBoardName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setBoardName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string available = 4;
 * @return {string}
 */
proto.hotel.HubRateData.prototype.getAvailable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setAvailable = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated HubCancelPolicy cancel_policies = 5;
 * @return {!Array<!proto.hotel.HubCancelPolicy>}
 */
proto.hotel.HubRateData.prototype.getCancelPoliciesList = function() {
  return /** @type{!Array<!proto.hotel.HubCancelPolicy>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.HubCancelPolicy, 5));
};


/**
 * @param {!Array<!proto.hotel.HubCancelPolicy>} value
 * @return {!proto.hotel.HubRateData} returns this
*/
proto.hotel.HubRateData.prototype.setCancelPoliciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.hotel.HubCancelPolicy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubCancelPolicy}
 */
proto.hotel.HubRateData.prototype.addCancelPolicies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.hotel.HubCancelPolicy, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.clearCancelPoliciesList = function() {
  return this.setCancelPoliciesList([]);
};


/**
 * repeated HotelPaymentType payment_types = 6;
 * @return {!Array<!proto.hotel.HotelPaymentType>}
 */
proto.hotel.HubRateData.prototype.getPaymentTypesList = function() {
  return /** @type {!Array<!proto.hotel.HotelPaymentType>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.hotel.HotelPaymentType>} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setPaymentTypesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.hotel.HotelPaymentType} value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.addPaymentTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.clearPaymentTypesList = function() {
  return this.setPaymentTypesList([]);
};


/**
 * repeated HubPromotion promotions = 7;
 * @return {!Array<!proto.hotel.HubPromotion>}
 */
proto.hotel.HubRateData.prototype.getPromotionsList = function() {
  return /** @type{!Array<!proto.hotel.HubPromotion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.HubPromotion, 7));
};


/**
 * @param {!Array<!proto.hotel.HubPromotion>} value
 * @return {!proto.hotel.HubRateData} returns this
*/
proto.hotel.HubRateData.prototype.setPromotionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.hotel.HubPromotion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubPromotion}
 */
proto.hotel.HubRateData.prototype.addPromotions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.hotel.HubPromotion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.clearPromotionsList = function() {
  return this.setPromotionsList([]);
};


/**
 * repeated HubOccupancyRate occupancy_rate = 8;
 * @return {!Array<!proto.hotel.HubOccupancyRate>}
 */
proto.hotel.HubRateData.prototype.getOccupancyRateList = function() {
  return /** @type{!Array<!proto.hotel.HubOccupancyRate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.HubOccupancyRate, 8));
};


/**
 * @param {!Array<!proto.hotel.HubOccupancyRate>} value
 * @return {!proto.hotel.HubRateData} returns this
*/
proto.hotel.HubRateData.prototype.setOccupancyRateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.hotel.HubOccupancyRate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubOccupancyRate}
 */
proto.hotel.HubRateData.prototype.addOccupancyRate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.hotel.HubOccupancyRate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.clearOccupancyRateList = function() {
  return this.setOccupancyRateList([]);
};


/**
 * optional string currency = 9;
 * @return {string}
 */
proto.hotel.HubRateData.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional double pay_now = 10;
 * @return {number}
 */
proto.hotel.HubRateData.prototype.getPayNow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setPayNow = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double total_pay_at_hotel = 11;
 * @return {number}
 */
proto.hotel.HubRateData.prototype.getTotalPayAtHotel = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setTotalPayAtHotel = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * repeated BedOption bed_options = 12;
 * @return {!Array<!proto.hotel.BedOption>}
 */
proto.hotel.HubRateData.prototype.getBedOptionsList = function() {
  return /** @type{!Array<!proto.hotel.BedOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.BedOption, 12));
};


/**
 * @param {!Array<!proto.hotel.BedOption>} value
 * @return {!proto.hotel.HubRateData} returns this
*/
proto.hotel.HubRateData.prototype.setBedOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.hotel.BedOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.BedOption}
 */
proto.hotel.HubRateData.prototype.addBedOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.hotel.BedOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.clearBedOptionsList = function() {
  return this.setBedOptionsList([]);
};


/**
 * repeated HubAmenity amenities = 13;
 * @return {!Array<!proto.hotel.HubAmenity>}
 */
proto.hotel.HubRateData.prototype.getAmenitiesList = function() {
  return /** @type{!Array<!proto.hotel.HubAmenity>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.HubAmenity, 13));
};


/**
 * @param {!Array<!proto.hotel.HubAmenity>} value
 * @return {!proto.hotel.HubRateData} returns this
*/
proto.hotel.HubRateData.prototype.setAmenitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.hotel.HubAmenity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubAmenity}
 */
proto.hotel.HubRateData.prototype.addAmenities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.hotel.HubAmenity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.clearAmenitiesList = function() {
  return this.setAmenitiesList([]);
};


/**
 * optional bool refundable = 14;
 * @return {boolean}
 */
proto.hotel.HubRateData.prototype.getRefundable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setRefundable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * repeated PayAtHotel pay_at_hotel = 15;
 * @return {!Array<!proto.hotel.PayAtHotel>}
 */
proto.hotel.HubRateData.prototype.getPayAtHotelList = function() {
  return /** @type{!Array<!proto.hotel.PayAtHotel>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.PayAtHotel, 15));
};


/**
 * @param {!Array<!proto.hotel.PayAtHotel>} value
 * @return {!proto.hotel.HubRateData} returns this
*/
proto.hotel.HubRateData.prototype.setPayAtHotelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.hotel.PayAtHotel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.PayAtHotel}
 */
proto.hotel.HubRateData.prototype.addPayAtHotel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.hotel.PayAtHotel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.clearPayAtHotelList = function() {
  return this.setPayAtHotelList([]);
};


/**
 * optional double total_rate_basic = 16;
 * @return {number}
 */
proto.hotel.HubRateData.prototype.getTotalRateBasic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setTotalRateBasic = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double total_tax_amount = 17;
 * @return {number}
 */
proto.hotel.HubRateData.prototype.getTotalTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setTotalTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * repeated string display_cancel_policies = 18;
 * @return {!Array<string>}
 */
proto.hotel.HubRateData.prototype.getDisplayCancelPoliciesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setDisplayCancelPoliciesList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.addDisplayCancelPolicies = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.clearDisplayCancelPoliciesList = function() {
  return this.setDisplayCancelPoliciesList([]);
};


/**
 * repeated string display_amenities = 19;
 * @return {!Array<string>}
 */
proto.hotel.HubRateData.prototype.getDisplayAmenitiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 19));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setDisplayAmenitiesList = function(value) {
  return jspb.Message.setField(this, 19, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.addDisplayAmenities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 19, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.clearDisplayAmenitiesList = function() {
  return this.setDisplayAmenitiesList([]);
};


/**
 * optional PriceAdjustmentResponse commission = 20;
 * @return {?proto.hotel.PriceAdjustmentResponse}
 */
proto.hotel.HubRateData.prototype.getCommission = function() {
  return /** @type{?proto.hotel.PriceAdjustmentResponse} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.PriceAdjustmentResponse, 20));
};


/**
 * @param {?proto.hotel.PriceAdjustmentResponse|undefined} value
 * @return {!proto.hotel.HubRateData} returns this
*/
proto.hotel.HubRateData.prototype.setCommission = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.clearCommission = function() {
  return this.setCommission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.HubRateData.prototype.hasCommission = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional PriceAdjustmentResponse discount = 21;
 * @return {?proto.hotel.PriceAdjustmentResponse}
 */
proto.hotel.HubRateData.prototype.getDiscount = function() {
  return /** @type{?proto.hotel.PriceAdjustmentResponse} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.PriceAdjustmentResponse, 21));
};


/**
 * @param {?proto.hotel.PriceAdjustmentResponse|undefined} value
 * @return {!proto.hotel.HubRateData} returns this
*/
proto.hotel.HubRateData.prototype.setDiscount = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.clearDiscount = function() {
  return this.setDiscount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.HubRateData.prototype.hasDiscount = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string pay_at_hotel_currency = 22;
 * @return {string}
 */
proto.hotel.HubRateData.prototype.getPayAtHotelCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setPayAtHotelCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string option_cancel_policy = 23;
 * @return {string}
 */
proto.hotel.HubRateData.prototype.getOptionCancelPolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setOptionCancelPolicy = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string option_service = 24;
 * @return {string}
 */
proto.hotel.HubRateData.prototype.getOptionService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setOptionService = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional bool is_hotel_package = 25;
 * @return {boolean}
 */
proto.hotel.HubRateData.prototype.getIsHotelPackage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.HubRateData} returns this
 */
proto.hotel.HubRateData.prototype.setIsHotelPackage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.HubOccupancyRate.repeatedFields_ = [2,3,4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HubOccupancyRate.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HubOccupancyRate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HubOccupancyRate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubOccupancyRate.toObject = function(includeInstance, msg) {
  var f, obj = {
    occupancyType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalNightlyRateList: jspb.Message.toObjectList(msg.getTotalNightlyRateList(),
    proto.hotel.HubRate.toObject, includeInstance),
    rateTaxesList: jspb.Message.toObjectList(msg.getRateTaxesList(),
    proto.hotel.HubRateTax.toObject, includeInstance),
    payAtHotelList: jspb.Message.toObjectList(msg.getPayAtHotelList(),
    hotel_base_pb.PayAtHotel.toObject, includeInstance),
    surChanges: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    rateDiscountsList: jspb.Message.toObjectList(msg.getRateDiscountsList(),
    proto.hotel.HubPromotion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HubOccupancyRate}
 */
proto.hotel.HubOccupancyRate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HubOccupancyRate;
  return proto.hotel.HubOccupancyRate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HubOccupancyRate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HubOccupancyRate}
 */
proto.hotel.HubOccupancyRate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOccupancyType(value);
      break;
    case 2:
      var value = new proto.hotel.HubRate;
      reader.readMessage(value,proto.hotel.HubRate.deserializeBinaryFromReader);
      msg.addTotalNightlyRate(value);
      break;
    case 3:
      var value = new proto.hotel.HubRateTax;
      reader.readMessage(value,proto.hotel.HubRateTax.deserializeBinaryFromReader);
      msg.addRateTaxes(value);
      break;
    case 4:
      var value = new hotel_base_pb.PayAtHotel;
      reader.readMessage(value,hotel_base_pb.PayAtHotel.deserializeBinaryFromReader);
      msg.addPayAtHotel(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSurChanges(value);
      break;
    case 6:
      var value = new proto.hotel.HubPromotion;
      reader.readMessage(value,proto.hotel.HubPromotion.deserializeBinaryFromReader);
      msg.addRateDiscounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HubOccupancyRate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HubOccupancyRate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HubOccupancyRate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubOccupancyRate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOccupancyType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalNightlyRateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.hotel.HubRate.serializeBinaryToWriter
    );
  }
  f = message.getRateTaxesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.hotel.HubRateTax.serializeBinaryToWriter
    );
  }
  f = message.getPayAtHotelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      hotel_base_pb.PayAtHotel.serializeBinaryToWriter
    );
  }
  f = message.getSurChanges();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getRateDiscountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.hotel.HubPromotion.serializeBinaryToWriter
    );
  }
};


/**
 * optional string occupancy_type = 1;
 * @return {string}
 */
proto.hotel.HubOccupancyRate.prototype.getOccupancyType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubOccupancyRate} returns this
 */
proto.hotel.HubOccupancyRate.prototype.setOccupancyType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated HubRate total_nightly_rate = 2;
 * @return {!Array<!proto.hotel.HubRate>}
 */
proto.hotel.HubOccupancyRate.prototype.getTotalNightlyRateList = function() {
  return /** @type{!Array<!proto.hotel.HubRate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.HubRate, 2));
};


/**
 * @param {!Array<!proto.hotel.HubRate>} value
 * @return {!proto.hotel.HubOccupancyRate} returns this
*/
proto.hotel.HubOccupancyRate.prototype.setTotalNightlyRateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.hotel.HubRate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubRate}
 */
proto.hotel.HubOccupancyRate.prototype.addTotalNightlyRate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.hotel.HubRate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubOccupancyRate} returns this
 */
proto.hotel.HubOccupancyRate.prototype.clearTotalNightlyRateList = function() {
  return this.setTotalNightlyRateList([]);
};


/**
 * repeated HubRateTax rate_taxes = 3;
 * @return {!Array<!proto.hotel.HubRateTax>}
 */
proto.hotel.HubOccupancyRate.prototype.getRateTaxesList = function() {
  return /** @type{!Array<!proto.hotel.HubRateTax>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.HubRateTax, 3));
};


/**
 * @param {!Array<!proto.hotel.HubRateTax>} value
 * @return {!proto.hotel.HubOccupancyRate} returns this
*/
proto.hotel.HubOccupancyRate.prototype.setRateTaxesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.hotel.HubRateTax=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubRateTax}
 */
proto.hotel.HubOccupancyRate.prototype.addRateTaxes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.hotel.HubRateTax, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubOccupancyRate} returns this
 */
proto.hotel.HubOccupancyRate.prototype.clearRateTaxesList = function() {
  return this.setRateTaxesList([]);
};


/**
 * repeated PayAtHotel pay_at_hotel = 4;
 * @return {!Array<!proto.hotel.PayAtHotel>}
 */
proto.hotel.HubOccupancyRate.prototype.getPayAtHotelList = function() {
  return /** @type{!Array<!proto.hotel.PayAtHotel>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.PayAtHotel, 4));
};


/**
 * @param {!Array<!proto.hotel.PayAtHotel>} value
 * @return {!proto.hotel.HubOccupancyRate} returns this
*/
proto.hotel.HubOccupancyRate.prototype.setPayAtHotelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.hotel.PayAtHotel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.PayAtHotel}
 */
proto.hotel.HubOccupancyRate.prototype.addPayAtHotel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.hotel.PayAtHotel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubOccupancyRate} returns this
 */
proto.hotel.HubOccupancyRate.prototype.clearPayAtHotelList = function() {
  return this.setPayAtHotelList([]);
};


/**
 * optional double sur_changes = 5;
 * @return {number}
 */
proto.hotel.HubOccupancyRate.prototype.getSurChanges = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubOccupancyRate} returns this
 */
proto.hotel.HubOccupancyRate.prototype.setSurChanges = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * repeated HubPromotion rate_discounts = 6;
 * @return {!Array<!proto.hotel.HubPromotion>}
 */
proto.hotel.HubOccupancyRate.prototype.getRateDiscountsList = function() {
  return /** @type{!Array<!proto.hotel.HubPromotion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.HubPromotion, 6));
};


/**
 * @param {!Array<!proto.hotel.HubPromotion>} value
 * @return {!proto.hotel.HubOccupancyRate} returns this
*/
proto.hotel.HubOccupancyRate.prototype.setRateDiscountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.hotel.HubPromotion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubPromotion}
 */
proto.hotel.HubOccupancyRate.prototype.addRateDiscounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.hotel.HubPromotion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubOccupancyRate} returns this
 */
proto.hotel.HubOccupancyRate.prototype.clearRateDiscountsList = function() {
  return this.setRateDiscountsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.HubCancelPolicy.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HubCancelPolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HubCancelPolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HubCancelPolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubCancelPolicy.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    percent: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    startDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    numberOfNights: jspb.Message.getFieldWithDefault(msg, 6, 0),
    nonrefundableDateRangesList: jspb.Message.toObjectList(msg.getNonrefundableDateRangesList(),
    proto.hotel.HubNonrefundableDateRange.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HubCancelPolicy}
 */
proto.hotel.HubCancelPolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HubCancelPolicy;
  return proto.hotel.HubCancelPolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HubCancelPolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HubCancelPolicy}
 */
proto.hotel.HubCancelPolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumberOfNights(value);
      break;
    case 7:
      var value = new proto.hotel.HubNonrefundableDateRange;
      reader.readMessage(value,proto.hotel.HubNonrefundableDateRange.deserializeBinaryFromReader);
      msg.addNonrefundableDateRanges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HubCancelPolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HubCancelPolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HubCancelPolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubCancelPolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNumberOfNights();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getNonrefundableDateRangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.hotel.HubNonrefundableDateRange.serializeBinaryToWriter
    );
  }
};


/**
 * optional double amount = 1;
 * @return {number}
 */
proto.hotel.HubCancelPolicy.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubCancelPolicy} returns this
 */
proto.hotel.HubCancelPolicy.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double percent = 2;
 * @return {number}
 */
proto.hotel.HubCancelPolicy.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubCancelPolicy} returns this
 */
proto.hotel.HubCancelPolicy.prototype.setPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string start_date = 3;
 * @return {string}
 */
proto.hotel.HubCancelPolicy.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubCancelPolicy} returns this
 */
proto.hotel.HubCancelPolicy.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string end_date = 4;
 * @return {string}
 */
proto.hotel.HubCancelPolicy.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubCancelPolicy} returns this
 */
proto.hotel.HubCancelPolicy.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.hotel.HubCancelPolicy.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubCancelPolicy} returns this
 */
proto.hotel.HubCancelPolicy.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 number_of_nights = 6;
 * @return {number}
 */
proto.hotel.HubCancelPolicy.prototype.getNumberOfNights = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubCancelPolicy} returns this
 */
proto.hotel.HubCancelPolicy.prototype.setNumberOfNights = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated HubNonrefundableDateRange nonrefundable_date_ranges = 7;
 * @return {!Array<!proto.hotel.HubNonrefundableDateRange>}
 */
proto.hotel.HubCancelPolicy.prototype.getNonrefundableDateRangesList = function() {
  return /** @type{!Array<!proto.hotel.HubNonrefundableDateRange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.HubNonrefundableDateRange, 7));
};


/**
 * @param {!Array<!proto.hotel.HubNonrefundableDateRange>} value
 * @return {!proto.hotel.HubCancelPolicy} returns this
*/
proto.hotel.HubCancelPolicy.prototype.setNonrefundableDateRangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.hotel.HubNonrefundableDateRange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubNonrefundableDateRange}
 */
proto.hotel.HubCancelPolicy.prototype.addNonrefundableDateRanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.hotel.HubNonrefundableDateRange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HubCancelPolicy} returns this
 */
proto.hotel.HubCancelPolicy.prototype.clearNonrefundableDateRangesList = function() {
  return this.setNonrefundableDateRangesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HubPromotion.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HubPromotion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HubPromotion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubPromotion.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    code: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    remark: jspb.Message.getFieldWithDefault(msg, 6, ""),
    category: jspb.Message.getFieldWithDefault(msg, 7, ""),
    offerType: jspb.Message.getFieldWithDefault(msg, 8, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HubPromotion}
 */
proto.hotel.HubPromotion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HubPromotion;
  return proto.hotel.HubPromotion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HubPromotion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HubPromotion}
 */
proto.hotel.HubPromotion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemark(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HubPromotion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HubPromotion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HubPromotion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubPromotion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRemark();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOfferType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.hotel.HubPromotion.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubPromotion} returns this
 */
proto.hotel.HubPromotion.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.hotel.HubPromotion.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubPromotion} returns this
 */
proto.hotel.HubPromotion.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string currency = 3;
 * @return {string}
 */
proto.hotel.HubPromotion.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubPromotion} returns this
 */
proto.hotel.HubPromotion.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string code = 4;
 * @return {string}
 */
proto.hotel.HubPromotion.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubPromotion} returns this
 */
proto.hotel.HubPromotion.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.hotel.HubPromotion.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubPromotion} returns this
 */
proto.hotel.HubPromotion.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string remark = 6;
 * @return {string}
 */
proto.hotel.HubPromotion.prototype.getRemark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubPromotion} returns this
 */
proto.hotel.HubPromotion.prototype.setRemark = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string category = 7;
 * @return {string}
 */
proto.hotel.HubPromotion.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubPromotion} returns this
 */
proto.hotel.HubPromotion.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string offer_type = 8;
 * @return {string}
 */
proto.hotel.HubPromotion.prototype.getOfferType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubPromotion} returns this
 */
proto.hotel.HubPromotion.prototype.setOfferType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string frequency = 9;
 * @return {string}
 */
proto.hotel.HubPromotion.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubPromotion} returns this
 */
proto.hotel.HubPromotion.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HubNonrefundableDateRange.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HubNonrefundableDateRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HubNonrefundableDateRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubNonrefundableDateRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    startDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HubNonrefundableDateRange}
 */
proto.hotel.HubNonrefundableDateRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HubNonrefundableDateRange;
  return proto.hotel.HubNonrefundableDateRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HubNonrefundableDateRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HubNonrefundableDateRange}
 */
proto.hotel.HubNonrefundableDateRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HubNonrefundableDateRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HubNonrefundableDateRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HubNonrefundableDateRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubNonrefundableDateRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string start_date = 1;
 * @return {string}
 */
proto.hotel.HubNonrefundableDateRange.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubNonrefundableDateRange} returns this
 */
proto.hotel.HubNonrefundableDateRange.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string end_date = 2;
 * @return {string}
 */
proto.hotel.HubNonrefundableDateRange.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubNonrefundableDateRange} returns this
 */
proto.hotel.HubNonrefundableDateRange.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HubRate.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HubRate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HubRate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubRate.toObject = function(includeInstance, msg) {
  var f, obj = {
    rateAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    rateBasic: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    taxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HubRate}
 */
proto.hotel.HubRate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HubRate;
  return proto.hotel.HubRate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HubRate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HubRate}
 */
proto.hotel.HubRate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRateAmount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRateBasic(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTaxAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HubRate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HubRate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HubRate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubRate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRateAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getRateBasic();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional double rate_amount = 1;
 * @return {number}
 */
proto.hotel.HubRate.prototype.getRateAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubRate} returns this
 */
proto.hotel.HubRate.prototype.setRateAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double rate_basic = 2;
 * @return {number}
 */
proto.hotel.HubRate.prototype.getRateBasic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubRate} returns this
 */
proto.hotel.HubRate.prototype.setRateBasic = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double tax_amount = 3;
 * @return {number}
 */
proto.hotel.HubRate.prototype.getTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubRate} returns this
 */
proto.hotel.HubRate.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.hotel.HubRate.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubRate} returns this
 */
proto.hotel.HubRate.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HubRateTax.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HubRateTax.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HubRateTax} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubRateTax.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    clientAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    clientCurrency: jspb.Message.getFieldWithDefault(msg, 3, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 4, ""),
    included: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    percent: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    type: jspb.Message.getFieldWithDefault(msg, 7, 0),
    description: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HubRateTax}
 */
proto.hotel.HubRateTax.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HubRateTax;
  return proto.hotel.HubRateTax.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HubRateTax} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HubRateTax}
 */
proto.hotel.HubRateTax.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClientAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncluded(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercent(value);
      break;
    case 7:
      var value = /** @type {!proto.hotel.TaxType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HubRateTax.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HubRateTax.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HubRateTax} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HubRateTax.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getClientAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getClientCurrency();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIncluded();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional double amount = 1;
 * @return {number}
 */
proto.hotel.HubRateTax.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubRateTax} returns this
 */
proto.hotel.HubRateTax.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double client_amount = 2;
 * @return {number}
 */
proto.hotel.HubRateTax.prototype.getClientAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubRateTax} returns this
 */
proto.hotel.HubRateTax.prototype.setClientAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string client_currency = 3;
 * @return {string}
 */
proto.hotel.HubRateTax.prototype.getClientCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubRateTax} returns this
 */
proto.hotel.HubRateTax.prototype.setClientCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.hotel.HubRateTax.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubRateTax} returns this
 */
proto.hotel.HubRateTax.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool included = 5;
 * @return {boolean}
 */
proto.hotel.HubRateTax.prototype.getIncluded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.HubRateTax} returns this
 */
proto.hotel.HubRateTax.prototype.setIncluded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional double percent = 6;
 * @return {number}
 */
proto.hotel.HubRateTax.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HubRateTax} returns this
 */
proto.hotel.HubRateTax.prototype.setPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional TaxType type = 7;
 * @return {!proto.hotel.TaxType}
 */
proto.hotel.HubRateTax.prototype.getType = function() {
  return /** @type {!proto.hotel.TaxType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.hotel.TaxType} value
 * @return {!proto.hotel.HubRateTax} returns this
 */
proto.hotel.HubRateTax.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.hotel.HubRateTax.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HubRateTax} returns this
 */
proto.hotel.HubRateTax.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.HotelDetail.repeatedFields_ = [5,9,13,14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.HotelDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.HotelDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.HotelDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    basicInfo: (f = msg.getBasicInfo()) && hotel_base_pb.HotelBasicInfo.toObject(includeInstance, f),
    policies: (f = msg.getPolicies()) && proto.hotel.HotelPolicies.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && proto.hotel.Description.toObject(includeInstance, f),
    categoryType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    hotel_base_pb.ImageData.toObject, includeInstance),
    minPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    centerInfo: (f = msg.getCenterInfo()) && hotel_base_pb.CenterInfo.toObject(includeInstance, f),
    isPremium: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    languagesSpokenList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    review: (f = msg.getReview()) && hotel_base_pb.HotelReview.toObject(includeInstance, f),
    importantInfo: (f = msg.getImportantInfo()) && hotel_base_pb.ImportantInfo.toObject(includeInstance, f),
    locationInfo: (f = msg.getLocationInfo()) && proto.hotel.HotelLocationInfo.toObject(includeInstance, f),
    hubRoomsList: jspb.Message.toObjectList(msg.getHubRoomsList(),
    proto.hotel.RoomRate.toObject, includeInstance),
    recommendedHotelsList: jspb.Message.toObjectList(msg.getRecommendedHotelsList(),
    hotel_base_pb.HotelSummary.toObject, includeInstance),
    amenityGroupsList: jspb.Message.toObjectList(msg.getAmenityGroupsList(),
    hotel_base_pb.HubGroupAmenity.toObject, includeInstance),
    currency: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.HotelDetail}
 */
proto.hotel.HotelDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.HotelDetail;
  return proto.hotel.HotelDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.HotelDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.HotelDetail}
 */
proto.hotel.HotelDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_base_pb.HotelBasicInfo;
      reader.readMessage(value,hotel_base_pb.HotelBasicInfo.deserializeBinaryFromReader);
      msg.setBasicInfo(value);
      break;
    case 2:
      var value = new proto.hotel.HotelPolicies;
      reader.readMessage(value,proto.hotel.HotelPolicies.deserializeBinaryFromReader);
      msg.setPolicies(value);
      break;
    case 3:
      var value = new proto.hotel.Description;
      reader.readMessage(value,proto.hotel.Description.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryType(value);
      break;
    case 5:
      var value = new hotel_base_pb.ImageData;
      reader.readMessage(value,hotel_base_pb.ImageData.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMinPrice(value);
      break;
    case 7:
      var value = new hotel_base_pb.CenterInfo;
      reader.readMessage(value,hotel_base_pb.CenterInfo.deserializeBinaryFromReader);
      msg.setCenterInfo(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPremium(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addLanguagesSpoken(value);
      break;
    case 10:
      var value = new hotel_base_pb.HotelReview;
      reader.readMessage(value,hotel_base_pb.HotelReview.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    case 11:
      var value = new hotel_base_pb.ImportantInfo;
      reader.readMessage(value,hotel_base_pb.ImportantInfo.deserializeBinaryFromReader);
      msg.setImportantInfo(value);
      break;
    case 12:
      var value = new proto.hotel.HotelLocationInfo;
      reader.readMessage(value,proto.hotel.HotelLocationInfo.deserializeBinaryFromReader);
      msg.setLocationInfo(value);
      break;
    case 13:
      var value = new proto.hotel.RoomRate;
      reader.readMessage(value,proto.hotel.RoomRate.deserializeBinaryFromReader);
      msg.addHubRooms(value);
      break;
    case 14:
      var value = new hotel_base_pb.HotelSummary;
      reader.readMessage(value,hotel_base_pb.HotelSummary.deserializeBinaryFromReader);
      msg.addRecommendedHotels(value);
      break;
    case 15:
      var value = new hotel_base_pb.HubGroupAmenity;
      reader.readMessage(value,hotel_base_pb.HubGroupAmenity.deserializeBinaryFromReader);
      msg.addAmenityGroups(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.HotelDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.HotelDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.HotelDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.HotelDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasicInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      hotel_base_pb.HotelBasicInfo.serializeBinaryToWriter
    );
  }
  f = message.getPolicies();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.hotel.HotelPolicies.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.hotel.Description.serializeBinaryToWriter
    );
  }
  f = message.getCategoryType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      hotel_base_pb.ImageData.serializeBinaryToWriter
    );
  }
  f = message.getMinPrice();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getCenterInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      hotel_base_pb.CenterInfo.serializeBinaryToWriter
    );
  }
  f = message.getIsPremium();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getLanguagesSpokenList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      hotel_base_pb.HotelReview.serializeBinaryToWriter
    );
  }
  f = message.getImportantInfo();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      hotel_base_pb.ImportantInfo.serializeBinaryToWriter
    );
  }
  f = message.getLocationInfo();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.hotel.HotelLocationInfo.serializeBinaryToWriter
    );
  }
  f = message.getHubRoomsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.hotel.RoomRate.serializeBinaryToWriter
    );
  }
  f = message.getRecommendedHotelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      hotel_base_pb.HotelSummary.serializeBinaryToWriter
    );
  }
  f = message.getAmenityGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      hotel_base_pb.HubGroupAmenity.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional HotelBasicInfo basic_info = 1;
 * @return {?proto.hotel.HotelBasicInfo}
 */
proto.hotel.HotelDetail.prototype.getBasicInfo = function() {
  return /** @type{?proto.hotel.HotelBasicInfo} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.HotelBasicInfo, 1));
};


/**
 * @param {?proto.hotel.HotelBasicInfo|undefined} value
 * @return {!proto.hotel.HotelDetail} returns this
*/
proto.hotel.HotelDetail.prototype.setBasicInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.clearBasicInfo = function() {
  return this.setBasicInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.HotelDetail.prototype.hasBasicInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional HotelPolicies policies = 2;
 * @return {?proto.hotel.HotelPolicies}
 */
proto.hotel.HotelDetail.prototype.getPolicies = function() {
  return /** @type{?proto.hotel.HotelPolicies} */ (
    jspb.Message.getWrapperField(this, proto.hotel.HotelPolicies, 2));
};


/**
 * @param {?proto.hotel.HotelPolicies|undefined} value
 * @return {!proto.hotel.HotelDetail} returns this
*/
proto.hotel.HotelDetail.prototype.setPolicies = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.clearPolicies = function() {
  return this.setPolicies(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.HotelDetail.prototype.hasPolicies = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Description description = 3;
 * @return {?proto.hotel.Description}
 */
proto.hotel.HotelDetail.prototype.getDescription = function() {
  return /** @type{?proto.hotel.Description} */ (
    jspb.Message.getWrapperField(this, proto.hotel.Description, 3));
};


/**
 * @param {?proto.hotel.Description|undefined} value
 * @return {!proto.hotel.HotelDetail} returns this
*/
proto.hotel.HotelDetail.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.HotelDetail.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string category_type = 4;
 * @return {string}
 */
proto.hotel.HotelDetail.prototype.getCategoryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.setCategoryType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated ImageData images = 5;
 * @return {!Array<!proto.hotel.ImageData>}
 */
proto.hotel.HotelDetail.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.hotel.ImageData>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.ImageData, 5));
};


/**
 * @param {!Array<!proto.hotel.ImageData>} value
 * @return {!proto.hotel.HotelDetail} returns this
*/
proto.hotel.HotelDetail.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.hotel.ImageData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.ImageData}
 */
proto.hotel.HotelDetail.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.hotel.ImageData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional float min_price = 6;
 * @return {number}
 */
proto.hotel.HotelDetail.prototype.getMinPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.setMinPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional CenterInfo center_info = 7;
 * @return {?proto.hotel.CenterInfo}
 */
proto.hotel.HotelDetail.prototype.getCenterInfo = function() {
  return /** @type{?proto.hotel.CenterInfo} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.CenterInfo, 7));
};


/**
 * @param {?proto.hotel.CenterInfo|undefined} value
 * @return {!proto.hotel.HotelDetail} returns this
*/
proto.hotel.HotelDetail.prototype.setCenterInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.clearCenterInfo = function() {
  return this.setCenterInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.HotelDetail.prototype.hasCenterInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool is_premium = 8;
 * @return {boolean}
 */
proto.hotel.HotelDetail.prototype.getIsPremium = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.setIsPremium = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * repeated string languages_spoken = 9;
 * @return {!Array<string>}
 */
proto.hotel.HotelDetail.prototype.getLanguagesSpokenList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.setLanguagesSpokenList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.addLanguagesSpoken = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.clearLanguagesSpokenList = function() {
  return this.setLanguagesSpokenList([]);
};


/**
 * optional HotelReview review = 10;
 * @return {?proto.hotel.HotelReview}
 */
proto.hotel.HotelDetail.prototype.getReview = function() {
  return /** @type{?proto.hotel.HotelReview} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.HotelReview, 10));
};


/**
 * @param {?proto.hotel.HotelReview|undefined} value
 * @return {!proto.hotel.HotelDetail} returns this
*/
proto.hotel.HotelDetail.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.HotelDetail.prototype.hasReview = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ImportantInfo important_info = 11;
 * @return {?proto.hotel.ImportantInfo}
 */
proto.hotel.HotelDetail.prototype.getImportantInfo = function() {
  return /** @type{?proto.hotel.ImportantInfo} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.ImportantInfo, 11));
};


/**
 * @param {?proto.hotel.ImportantInfo|undefined} value
 * @return {!proto.hotel.HotelDetail} returns this
*/
proto.hotel.HotelDetail.prototype.setImportantInfo = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.clearImportantInfo = function() {
  return this.setImportantInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.HotelDetail.prototype.hasImportantInfo = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional HotelLocationInfo location_info = 12;
 * @return {?proto.hotel.HotelLocationInfo}
 */
proto.hotel.HotelDetail.prototype.getLocationInfo = function() {
  return /** @type{?proto.hotel.HotelLocationInfo} */ (
    jspb.Message.getWrapperField(this, proto.hotel.HotelLocationInfo, 12));
};


/**
 * @param {?proto.hotel.HotelLocationInfo|undefined} value
 * @return {!proto.hotel.HotelDetail} returns this
*/
proto.hotel.HotelDetail.prototype.setLocationInfo = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.clearLocationInfo = function() {
  return this.setLocationInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.HotelDetail.prototype.hasLocationInfo = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated RoomRate hub_rooms = 13;
 * @return {!Array<!proto.hotel.RoomRate>}
 */
proto.hotel.HotelDetail.prototype.getHubRoomsList = function() {
  return /** @type{!Array<!proto.hotel.RoomRate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.RoomRate, 13));
};


/**
 * @param {!Array<!proto.hotel.RoomRate>} value
 * @return {!proto.hotel.HotelDetail} returns this
*/
proto.hotel.HotelDetail.prototype.setHubRoomsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.hotel.RoomRate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.RoomRate}
 */
proto.hotel.HotelDetail.prototype.addHubRooms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.hotel.RoomRate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.clearHubRoomsList = function() {
  return this.setHubRoomsList([]);
};


/**
 * repeated HotelSummary recommended_hotels = 14;
 * @return {!Array<!proto.hotel.HotelSummary>}
 */
proto.hotel.HotelDetail.prototype.getRecommendedHotelsList = function() {
  return /** @type{!Array<!proto.hotel.HotelSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.HotelSummary, 14));
};


/**
 * @param {!Array<!proto.hotel.HotelSummary>} value
 * @return {!proto.hotel.HotelDetail} returns this
*/
proto.hotel.HotelDetail.prototype.setRecommendedHotelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.hotel.HotelSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HotelSummary}
 */
proto.hotel.HotelDetail.prototype.addRecommendedHotels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.hotel.HotelSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.clearRecommendedHotelsList = function() {
  return this.setRecommendedHotelsList([]);
};


/**
 * repeated HubGroupAmenity amenity_groups = 15;
 * @return {!Array<!proto.hotel.HubGroupAmenity>}
 */
proto.hotel.HotelDetail.prototype.getAmenityGroupsList = function() {
  return /** @type{!Array<!proto.hotel.HubGroupAmenity>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.HubGroupAmenity, 15));
};


/**
 * @param {!Array<!proto.hotel.HubGroupAmenity>} value
 * @return {!proto.hotel.HotelDetail} returns this
*/
proto.hotel.HotelDetail.prototype.setAmenityGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.hotel.HubGroupAmenity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubGroupAmenity}
 */
proto.hotel.HotelDetail.prototype.addAmenityGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.hotel.HubGroupAmenity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.clearAmenityGroupsList = function() {
  return this.setAmenityGroupsList([]);
};


/**
 * optional string currency = 16;
 * @return {string}
 */
proto.hotel.HotelDetail.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.HotelDetail} returns this
 */
proto.hotel.HotelDetail.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * @enum {number}
 */
proto.hotel.HotelPaymentType = {
  PAYMENT_TYPE_NONE: 0,
  PREPAYMENT: 1,
  AT_HOTEL: 2
};

/**
 * @enum {number}
 */
proto.hotel.TaxType = {
  TAX_TYPE_NONE: 0,
  TAX: 1,
  FEE: 2,
  TAX_AND_FEE: 3
};

goog.object.extend(exports, proto.hotel);
