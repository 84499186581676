import { formatCurrency as formatCurrencyUtils } from '@core/utils';
import { useAppSelector } from '@web-admin/stores';
import { ECurrency } from '@web-admin/types';

export function useCurrency() {
  const currentCurrency = useAppSelector((state) => state.common.currency);
  const defaultCurrency = '₫';

  function formatPrice(amount: number, currency?: string) {
    if (currency) {
      return formatCurrencyUtils(
        amount,
        currency.toLocaleLowerCase() === ECurrency.VND ? '0,0' : '0,0.00'
      );
    } else {
      return formatCurrencyUtils(
        amount,
        currentCurrency.value === ECurrency.VND ? '0,0' : '0,0.00'
      );
    }
  }

  function formatCurrency(amount: number, currency?: string) {
    if (currency) {
      return `${formatPrice(amount, currency)} ${currency}`;
    } else {
      return `${formatPrice(amount)} ${currentCurrency.text}`;
    }
  }

  function formatCurrencyCustom(amount: number, currency?: string) {
    if (currency) {
      return currency === 'VND'
        ? `${formatPrice(amount, currency)} ${defaultCurrency}`
        : `${formatPrice(amount, currency)} ${currency}`;
    } else {
      return `${formatPrice(amount)} ${defaultCurrency}`;
    }
  }

  return {
    formatCurrency,
    formatCurrencyCustom,
    formatPrice,
    currentCurrency,
    currency: currentCurrency.text,
  };
}

export default useCurrency;
