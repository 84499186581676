import { IPaginationRes } from '@tixlabs/grpc-client';
import {
  IReferenceItem,
  reportService,
} from '@tixlabs/grpc-client/web-partnership';
import { useAppMutation } from '@vemaybay-admin/hooks/internals';
import { useCallback, useEffect, useState } from 'react';
import ReferFriendStats from './ReferFriendStats';
import TableContent from './TableContent';

const DEFAULT_PAGE_LIMIT = 10;

export const ReferFriendCtn = () => {
  const [paginationRes, setPaginationRes] = useState<IPaginationRes>({
    pageCurrent: 1,
    pageLimit: DEFAULT_PAGE_LIMIT,
    totalPage: 1,
    totalRecord: 0,
  });

  const [listReference, setListReference] = useState<IReferenceItem[]>([]);

  const { mutateAsync: referenceList, isLoading } = useAppMutation({
    mutationKey: ['reportService', 'referenceList'],
    mutationFn: reportService.referenceList,
    onSuccess: ({ isSuccess, data }) => {
      if (isSuccess && data) {
        const { itemList, pagination } = data;
        pagination && setPaginationRes(pagination);
        setListReference(itemList);
      }
    },
  });

  const handlePagination = async (page: number) => {
    await referenceList({
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: page,
      },
    });
  };

  const getListCrossCheck = useCallback(async () => {
    await referenceList({
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: 1,
      },
    });
  }, []);

  useEffect(() => {
    getListCrossCheck();
  }, []);

  return (
    <div className='flex flex-col gap-y-2.5'>
      <ReferFriendStats />

      <TableContent
        listReference={listReference}
        isLoading={isLoading}
        pagination={paginationRes}
        onPageChange={handlePagination}
      />
    </div>
  );
};

export default ReferFriendCtn;
