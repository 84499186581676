/**
 * @fileoverview gRPC-Web generated client stub for skyhub.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: skyhub/web_partnership/hotel.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var skyhub_hotel_pb = require('../../skyhub/hotel_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.skyhub = {};
proto.skyhub.web_partnership = require('./hotel_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.skyhub.web_partnership.HotelServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.skyhub.web_partnership.HotelServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.SearchHotelReq,
 *   !proto.skyhub.web_partnership.SearchHotelRes>}
 */
const methodDescriptor_HotelService_SearchHotel = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.HotelService/SearchHotel',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.SearchHotelReq,
  proto.skyhub.web_partnership.SearchHotelRes,
  /**
   * @param {!proto.skyhub.web_partnership.SearchHotelReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.SearchHotelRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.SearchHotelReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.SearchHotelRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.SearchHotelRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.HotelServiceClient.prototype.searchHotel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.HotelService/SearchHotel',
      request,
      metadata || {},
      methodDescriptor_HotelService_SearchHotel,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.SearchHotelReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.SearchHotelRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.HotelServicePromiseClient.prototype.searchHotel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.HotelService/SearchHotel',
      request,
      metadata || {},
      methodDescriptor_HotelService_SearchHotel);
};


module.exports = proto.skyhub.web_partnership;

