import {
  Button,
  FileExcelLineIcon,
  Form,
  FormInput,
  FormInputDateFromTo,
  FormSelect,
  ONE_DAY_TIMESTAMP,
  OrdersIcon,
  SearchLineIcon,
  TFromToDate,
} from '@common-ui';
import { useExportData } from '@core/hooks';
import { addDay, getUserName, subDay } from '@core/utils';
import { IPaginationRes } from '@tixlabs/grpc-client';
import {
  IBookingDetail,
  IListBookingsFilter,
  IListBookingsRequest,
  hotelApiServiceWebPartnership
} from '@tixlabs/grpc-client/web-partnership';

import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import TableContent from './TableContent';
import {
  BOOKING_STATUS_HOTEL_LABEL,
  SELECT_STATUS_BOOKING_HOTEL_OPTIONS,
} from '@vemaybay-admin/utils';
import { BookingStatus } from '@api/hotel/base_pb';
import {
  useAppMutation,
  useCurrency,
  useTime,
} from '@vemaybay-admin/hooks/internals';

type Props = {
  //
};

const DEFAULT_PAGE_LIMIT = 20;

const MAX_FILTER_DAY = 30;

export const ListOrderHotel = (props: Props) => {
  const [searchParams, _] = useSearchParams();
  const DEFAULT_ORDER_FILTER: Pick<IListBookingsRequest, 'filter'> & {
    filterDate: TFromToDate;
    status: BookingStatus;
  } = {
    filter: {
      statusList: [], // chỉ để cho đúng struct, không dùng
      orderCode: '',
      from: new Date().setHours(0, 0, 0, 0) - ONE_DAY_TIMESTAMP * 7,
      to: new Date().setHours(23, 59, 59, 999),
      notInStatusesList: [BookingStatus.DRAFT, BookingStatus.CONFIRMED],
    },
    filterDate: {
      startDate: new Date(
        new Date(Date.now() - ONE_DAY_TIMESTAMP * 7).setHours(0, 0, 0, 0)
      ),
      endDate: new Date(new Date().setHours(23, 59, 59, 999)),
    },
    status: 0,
  };

  const { formatDate, formatHourDateTime } = useTime();
  const { formatPrice } = useCurrency();
  const { handleExport, isProcessing } = useExportData<IBookingDetail>({
    columns: [
      {
        name: 'Ngày giờ đặt',
        key: 'createdAt',
        formatValue: (createdAt) => formatHourDateTime(createdAt),
      },
      {
        name: 'Mã đơn hàng',
        key: 'orderCode',
      },
      {
        name: 'Mã đặt chỗ',
        key: 'roomsList',
        formatValue: (roomsList) => {
          const roomsToShow = roomsList.slice(0, 2);
          const isMoreThanTwo = roomsList.length > 2;
          const isMoreThanOrEqualTwo = roomsList.length >= 2;

          return roomsToShow
            .map((room, i) => {
              return `${room.confirmationId}
                ${room.confirmationId && i === 0 && isMoreThanOrEqualTwo && ','}
                ${room.confirmationId && i === 1 && isMoreThanTwo && ', ...'}`;
            })
            .join(' ');
        },
      },
      {
        name: 'Khách hàng',
        key: 'contactInfo',
        formatValue: (contactInfo) =>
          getUserName({
            firstName: contactInfo?.firstName || '',
            lastName: contactInfo?.lastName || '',
          }),
      },
      {
        name: 'Số điện thoại',
        key: 'roomsList',
        formatValue: (roomsList) => {
          const holder = roomsList[0]?.holder;
          return holder ? `(${holder.phoneCode}) ${holder.phoneNumber}` : '-';
        },
      },
      {
        name: 'Email',
        key: 'roomsList',
        formatValue: (roomsList) => roomsList[0]?.holder?.email || '-',
      },
      {
        name: 'Tổng tiền',
        key: 'priceInfo',
        formatValue: (priceInfo) => formatPrice(priceInfo?.totalPrice || 0),
      },
      {
        name: 'Trạng thái',
        key: 'status',
        formatValue: (status) => BOOKING_STATUS_HOTEL_LABEL[status],
      },
    ],
    filename: `order_hotel_${formatDate(Date.now())}`,
  });

  const methods = useForm<
    Pick<IListBookingsRequest, 'filter'> & {
      filterDate: TFromToDate;
      status: BookingStatus;
    }
  >({
    defaultValues: DEFAULT_ORDER_FILTER,
  });

  const {
    formState: { errors },
  } = methods;

  const [paginationRes, setPaginationRes] = useState<IPaginationRes>({
    pageCurrent: 1,
    pageLimit: DEFAULT_PAGE_LIMIT,
    totalPage: 1,
    totalRecord: 0,
  });

  const [filterReq, setFilterReq] = useState<IListBookingsFilter>({
    statusList: [],
    orderCode: '',
    notInStatusesList: [BookingStatus.DRAFT, BookingStatus.CONFIRMED],
    from: 0,
    to: 0,
  });

  const [listOrderHotel, setListOrder] = useState<IBookingDetail[]>([]);

  const { mutateAsync: listBookingHotel, isLoading } = useAppMutation({
    mutationKey: ['hotelApiService', 'listBookings'],
    mutationFn: hotelApiServiceWebPartnership.listBookings,
    onSuccess: ({ isSuccess, pagination, itemsList, errorCode }) => {
      if (isSuccess && pagination) {
        pagination && setPaginationRes(pagination);
        // filter order has itinerariesList empty
        setListOrder(itemsList);
      } else {
        console.log('error', errorCode);
      }
    },
    onError: (err) => {
      console.log('oops...', err);
    },
  });

  // const { mutateAsync: exportBookingFlight } = useAppMutation({
  //   mutationKey: ['flightApiService', 'exportBookingFlight'],
  //   mutationFn: flightApiService.exportBookingFlight,
  //   onSuccess: ({ isSuccess, errorCode }) => {
  //     if (isSuccess) {
  //     } else {
  //       console.log('error', errorCode);
  //     }
  //   },
  //   onError: (err) => {
  //     console.log('oops...', err);
  //   },
  // });

  const handleExportListOrderHotel = async () => {
    try {
      await handleExport(listOrderHotel);
    } catch (error) {
      console.error('Export Error:', error);
    }
  };

  const handleSubmit = async (
    data: Pick<IListBookingsRequest, 'filter'> & {
      filterDate: TFromToDate;
      status: BookingStatus;
    }
  ) => {
    let statusList: number[] = [];
    if (data?.status === 0) {
      statusList = [];
    } else if (data?.status === 3 || data?.status === 4) {
      statusList = [3, 4];
    } else {
      statusList = [data?.status];
    }
    if (data.filter) {
      setFilterReq({
        statusList: statusList,
        orderCode: data?.filter?.orderCode,
        notInStatusesList: data?.filter?.notInStatusesList,
        from: data.filterDate.startDate
          ? new Date(data.filterDate.startDate).getTime()
          : data.filter.from,
        to: data.filterDate.endDate
          ? new Date(data.filterDate.endDate).getTime()
          : data.filter.to,
      });
      await listBookingHotel({
        filter: {
          statusList: statusList,
          orderCode: data?.filter?.orderCode,
          notInStatusesList: data?.filter?.notInStatusesList,
          from: data.filterDate.startDate
            ? new Date(data.filterDate.startDate).getTime()
            : data.filter.from,
          to: data.filterDate.endDate
            ? new Date(data.filterDate.endDate).getTime()
            : data.filter.to,
        },
        pagination: {
          pageLimit: DEFAULT_PAGE_LIMIT,
          pageNumber: 1,
        },
      });
    }
  };

  const handlePagination = async (page: number) => {
    await listBookingHotel({
      filter: filterReq,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: page,
      },
    });
  };

  const getListBookingFlight = useCallback(async () => {
    // first load
    await listBookingHotel({
      filter: filterReq,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: 1,
      },
    });
  }, []);

  useEffect(() => {
    getListBookingFlight();
  }, []);

  const { startDate: fromDate, endDate: toDate } = methods.watch('filterDate');

  return (
    <div className='space-y-5'>
      <div className='flex space-x-2.5 items-center'>
        <OrdersIcon className='w-5 h-5 shrink-0' />
        <span className='font-semibold text-lg'>Quản lý đơn hàng </span>
      </div>
      <div className='w-full bg-white border rounded p-3.5'>
        <Form
          methods={methods}
          onSubmit={handleSubmit}
          className='flex justify-between pb-4 border-b mb-4 flex-col md-lg:flex-row gap-3'>
          <div className='md-lg:flex md-lg:space-x-2.5 grid grid-cols-2 gap-4'>
            <FormInputDateFromTo
              rules={{
                required: 'Ngày là bắt buộc', // thông báo lỗi khi trường trống
                validate: {
                  checkDates: (value) =>
                    (value?.startDate && value?.endDate) ||
                    'Chọn cả ngày đi và ngày về',
                },
              }}
              inputProps={{
                placeholderStart: 'Từ ngày',
                placeholderEnd: 'Đến ngày',
                showFilter: true,
                inputSize: 'sm',
                minStartDate: toDate
                  ? subDay(toDate, MAX_FILTER_DAY)
                  : undefined,
                maxStartDate: new Date(),
                maxEndDate: fromDate
                  ? addDay(fromDate, MAX_FILTER_DAY) < new Date()
                    ? addDay(fromDate, MAX_FILTER_DAY)
                    : new Date()
                  : new Date(),
                isFullTime: true,
                required: true,
              }}
              label='Thời gian'
              name='filterDate'
            />
            <FormInput
              name='filter.orderCode'
              label='Mã đơn hàng/ đặt chỗ'
              placeholder='Nhập mã đơn hàng/đặt chỗ'
            />

            <FormSelect
              name='status'
              selectOptions={SELECT_STATUS_BOOKING_HOTEL_OPTIONS}
              label={'Trạng thái'}
              inputProps={{
                isOnlyValue: true,
              }}
            />
            <div
              className={`flex flex-col ${errors.filterDate ? 'justify-center' : 'justify-end'
                }`}>
              <Button
                type='submit'
                theme='primary'
                size='sm'
                prefixIcon={<SearchLineIcon />}
                className='font-semibold !px-5 h-[40px] w-[150px]'>
                Tìm kiếm
              </Button>
            </div>
          </div>
          <div className='flex space-x-2.5'>
            <div
              className={`flex flex-col ${errors.filterDate ? 'justify-center' : 'justify-end'
                }`}>
              <Button
                size='sm'
                theme='green'
                onClick={handleExportListOrderHotel}
                // disabled={isProcessing}
                prefixIcon={<FileExcelLineIcon />}
                className='!px-5'>
                <span className='whitespace-nowrap'>
                  {isProcessing ? 'Tải về...' : 'Tải về'}
                </span>
              </Button>
            </div>
          </div>
        </Form>
        <TableContent
          listOrder={listOrderHotel}
          isLoading={isLoading}
          pagination={paginationRes}
          onPageChange={handlePagination}
        />
      </div>
    </div>
  );
};

export default ListOrderHotel;
