import {
  ArrowDownSLineIcon,
  Collapse,
  LockPasswordLineIcon,
  LogoutBoxRLineIcon,
} from '@common-ui';
import { useOnClickOutside } from '@core/hooks';
import { useAppDispatch } from '@vemaybay-admin/stores';
import { logout } from '@vemaybay-admin/stores/reducers/user';
import { DEFAULT_MODAL_CONTENT } from '@vemaybay-admin/utils';
import cn from 'classnames';
import { Fragment, useRef, useState } from 'react';
import MenuHeaderModal, { ESwitchModal, TModalHeader } from './MenuModal';

type Props = {
  imgUrl: string;
  name: string;
  wallet: number;
};

const DEFAULT_MODAL_INFO: TModalHeader = {
  ...DEFAULT_MODAL_CONTENT,
  state: ESwitchModal.NONE,
};

export const MenuHeader = ({ name, imgUrl }: Props) => {
  const dispatch = useAppDispatch();
  const collapseRef = useRef<HTMLDivElement>(null);
  const [isShowCollapse, setIsShowCollapse] = useState(false);
  const [modalInfo, setModalInfo] = useState<TModalHeader>(DEFAULT_MODAL_INFO);

  const handleLogout = () => {
    dispatch(logout());
    setIsShowCollapse(false);
    setModalInfo((pre) => ({ ...pre, open: false }));
  };

  useOnClickOutside(collapseRef, () => setIsShowCollapse(false));

  const MENU_HEADER_ITEMS = [
    // {
    //   label: 'Thông tin tài khoản',
    //   icon: <AccountCircleLineIcon className='w-5 h-5 shrink-0' />,
    //   action: () =>
    //     setModalInfo((pre) => ({
    //       ...pre,
    //       open: true,
    //       state: ESwitchModal.CHANGE_PASSWORD,
    //     })),
    // },
    {
      label: 'Đổi mật khẩu',
      icon: <LockPasswordLineIcon className='w-5 h-5 shrink-0' />,
      action: () =>
        setModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.CHANGE_PASSWORD,
        })),
    },
    {
      label: 'Đăng xuất',
      icon: <LogoutBoxRLineIcon className='w-5 h-5 shrink-0' />,
      action: () =>
        setModalInfo((pre) => ({
          ...pre,
          open: true,
          state: ESwitchModal.CONFIRM_LOGOUT,
        })),
    },
  ];

  return (
    <div className='flex space-x-5 items-center h-full z-40'>
      <Collapse
        ref={collapseRef}
        panel={
          <div className='bg-white text-black p-4 shadow-xl border rounded z-sticky-control min-w-[300px] flex flex-col gap-4 text-md'>
            {MENU_HEADER_ITEMS.map((item, index) => {
              return (
                <Fragment key={index}>
                  {index === MENU_HEADER_ITEMS.length - 1 && (
                    <div className='bg-neutral-5 h-px w-full'></div>
                  )}

                  <div
                    onClick={item.action}
                    className='flex items-center gap-2.5 py-1 cursor-pointer hover:bg-primary-2 rounded duration-150 '>
                    {item.icon}
                    <span>{item.label}</span>
                  </div>
                </Fragment>
              );
            })}
          </div>
        }
        isShow={isShowCollapse}
        panelClassName='absolute top-full right-0'
        className='relative h-full flex items-center'>
        <div
          className='flex space-x-3 items-center cursor-pointer'
          onClick={() => setIsShowCollapse((pre) => !pre)}>
          <img
            className='rounded-full w-8 h-8'
            src={imgUrl}
            alt='avatar'
            onError={(e) => {
              e.currentTarget.src = '/assets/images/user-avatar.png';
            }}
          />
          <div className='flex space-x-2 items-center'>
            <div className='text-[13px]'>{name}</div>
            <ArrowDownSLineIcon
              className={cn('duration-300', {
                'rotate-180': isShowCollapse,
              })}
            />
          </div>
        </div>
      </Collapse>
      <MenuHeaderModal
        modalInfo={modalInfo}
        handleUpdateModalInfo={setModalInfo}
        handleLogout={handleLogout}
      />
    </div>
  );
};

export default MenuHeader;
