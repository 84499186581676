//   import { HotelServiceClient } from '@/api/hotel/web/hotel_grpc_web_pb';
import { BaseApi } from '../base-api';
import { BaseApiOptions } from '@core/grpc-client';
import { HotelServiceClient } from '@api/hotel/web_partner/hotel_grpc_web_pb';
import {
  AddBookingContactInfoReq,
  AddBookingContactInfoRes,
  DeleteRateSelectionReq,
  DeleteRateSelectionRes,
  GetBookingSelectionReq,
  GetBookingSelectionRes,
  GetStaticBookingBySessionReq,
  GetStaticBookingBySessionRes,
  OrderBookingReq,
  OrderBookingRes,
  PriceCheckReq,
  PriceCheckRes,
  UpdateBookingSelectionReq,
  UpdateBookingSelectionRes,
  UpdatePriceAdjustment,
} from '@api/hotel/web_partner/hotel_pb';
import { PriceAdjustmentRequest } from '@api/hotel/base_pb';
import {
  Company,
  InvoicingInformation,
  Receiver,
  RoomHolder,
  RoomOccupancyRequest,
} from '@api/hotel/booking_pb';

class HotelSelectionApi extends BaseApi<HotelServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new HotelServiceClient(this.url, null, this.optsDev);
  }

  getStaticBookingBySession = ({
    sessionId,
  }: GetStaticBookingBySessionReq.AsObject): Promise<GetStaticBookingBySessionRes.AsObject> => {
    const req = new GetStaticBookingBySessionReq();

    req.setSessionId(sessionId);

    return this.grpc<
      GetStaticBookingBySessionReq,
      GetStaticBookingBySessionRes,
      GetStaticBookingBySessionRes.AsObject
    >(this.serviceClient.getStaticBookingBySession, req, {});
  };

  getBookingSelection = ({
    selectionId,
  }: GetBookingSelectionReq.AsObject): Promise<GetBookingSelectionRes.AsObject> => {
    const req = new GetBookingSelectionReq();

    req.setSelectionId(selectionId);

    return this.grpc<
      GetBookingSelectionReq,
      GetBookingSelectionRes,
      GetBookingSelectionRes.AsObject
    >(this.serviceClient.getBookingSelection, req, {});
  };

  addBookingContactInfo = ({
    sessionId,
    invoicingInfo,
    bedOptionId,
    roomOccupanciesList,
    specialRequest,
    commission,
    discount,
    contactEmail
  }: AddBookingContactInfoReq.AsObject): Promise<AddBookingContactInfoRes.AsObject> => {
    console.log(
      'addBookingContactInfo',
      sessionId,
      invoicingInfo,
      bedOptionId,
      roomOccupanciesList,
      specialRequest,
      commission,
      discount
    );

    const req = new AddBookingContactInfoReq();

    contactEmail && req.setContactEmail(contactEmail);

    if (invoicingInfo) {
      const invoicingInfoObj = new InvoicingInformation();
      const companyInfoObj = new Company();
      companyInfoObj.setName(invoicingInfo?.companyInfo?.name || '');
      companyInfoObj.setTaxCode(invoicingInfo?.companyInfo?.taxCode || '');
      companyInfoObj.setAddress(invoicingInfo?.companyInfo?.address || '');
      const receiverInfoObj = new Receiver();
      receiverInfoObj.setName(invoicingInfo?.receiverInfo?.name || '');
      receiverInfoObj.setPhone(invoicingInfo?.receiverInfo?.phone || '');
      receiverInfoObj.setEmail(invoicingInfo?.receiverInfo?.email || '');
      receiverInfoObj.setAddress(invoicingInfo?.receiverInfo?.address || '');
      receiverInfoObj.setNote(invoicingInfo?.receiverInfo?.note || '');
      invoicingInfoObj.setCompanyInfo(companyInfoObj);
      invoicingInfoObj.setReceiverInfo(receiverInfoObj);
      req.setInvoicingInfo(invoicingInfoObj);
    }

    const roomOccupanciesListObj = new Array<RoomOccupancyRequest>();
    for (let i = 0; i < roomOccupanciesList.length; i++) {
      const roomOccupancyObj = new RoomOccupancyRequest();

      const holderObj = new RoomHolder();
      holderObj.setGivenName(roomOccupanciesList[i].holder?.givenName || '');
      holderObj.setSurname(roomOccupanciesList[i].holder?.surname || '');
      holderObj.setEmail(roomOccupanciesList[i].holder?.email || '');
      holderObj.setPhoneCode(roomOccupanciesList[i].holder?.phoneCode || '');
      holderObj.setPhoneNumber(
        roomOccupanciesList[i].holder?.phoneNumber || ''
      );

      roomOccupancyObj.setIndex(roomOccupanciesList[i].index);
      roomOccupancyObj.setAdults(roomOccupanciesList[i].adults);
      roomOccupancyObj.setChildAgesList(roomOccupanciesList[i].childAgesList);
      roomOccupancyObj.setHolder(holderObj);
      roomOccupanciesListObj.push(roomOccupancyObj);
    }

    const commissionObj = new PriceAdjustmentRequest();
    commissionObj.setAdjustmentType(commission?.adjustmentType || 1);
    commissionObj.setValue(commission?.value || 0);
    commissionObj.setCurrency(commission?.currency || '');

    const discountObj = new PriceAdjustmentRequest();
    discountObj.setAdjustmentType(discount?.adjustmentType || 1);
    discountObj.setValue(discount?.value || 0);
    discountObj.setCurrency(discount?.currency || '');

    req.setSessionId(sessionId);
    req.setBedOptionId(bedOptionId);
    req.setSpecialRequest(specialRequest);
    req.setRoomOccupanciesList(roomOccupanciesListObj);
    req.setCommission(commissionObj);
    req.setDiscount(discountObj);

    return this.grpc<
      AddBookingContactInfoReq,
      AddBookingContactInfoRes,
      AddBookingContactInfoRes.AsObject
    >(this.serviceClient.addBookingContactInfo, req, {});
  };

  priceCheck = ({
    bookingCode,
    stateful,
  }: PriceCheckReq.AsObject): Promise<PriceCheckRes.AsObject> => {
    const req = new PriceCheckReq();
    req.setBookingCode(bookingCode);
    req.setStateful(stateful);

    return this.grpc<PriceCheckReq, PriceCheckRes, PriceCheckRes.AsObject>(
      this.serviceClient?.priceCheck,
      req,
      {
        useAnonymousToken: true,
      }
    );
  };

  orderBooking = ({
    orderCode,
  }: OrderBookingReq.AsObject): Promise<OrderBookingRes.AsObject> => {
    const req = new OrderBookingReq();
    req.setOrderCode(orderCode);

    return this.grpc<
      OrderBookingReq,
      OrderBookingRes,
      OrderBookingRes.AsObject
    >(this.serviceClient.orderBooking, req, {});
  };

  updateBookingSelection = ({
    selectionId,
    priceAdjustmentsList,
    title,
  }: UpdateBookingSelectionReq.AsObject): Promise<UpdateBookingSelectionRes.AsObject> => {
    // Create a new request instance
    const req = new UpdateBookingSelectionReq();

    // Set the selectionId
    req.setSelectionId(selectionId);

    // Map and set the price adjustments list
    if (priceAdjustmentsList) {
      console.log('priceAdjustmentsList', priceAdjustmentsList);
      const adjustments = new Array<UpdatePriceAdjustment>();

      for (let i = 0; i < priceAdjustmentsList.length; i++) {
        const adjustment = priceAdjustmentsList[i];
        console.log('Processing adjustment:', adjustment);
        const adj = new UpdatePriceAdjustment();
        adj.setHotelId(adjustment.hotelId);
        adj.setRoomId(adjustment.roomId);
        adj.setRateId(adjustment.rateId);

        // Commission handling
        if (adjustment.commission) {
          const commission = new PriceAdjustmentRequest();

          commission.setAdjustmentType(adjustment.commission.adjustmentType);
          commission.setValue(adjustment.commission.value);
          commission.setCurrency(adjustment.commission.currency);
          adj.setCommission(commission);
        }

        // Discount handling
        if (adjustment.discount) {
          const discount = new PriceAdjustmentRequest();
          discount.setAdjustmentType(adjustment.discount.adjustmentType);
          discount.setValue(adjustment.discount.value);
          discount.setCurrency(adjustment.discount.currency);
          adj.setDiscount(discount);
        }

        adjustments.push(adj);
      }

      req.setPriceAdjustmentsList(adjustments);
    }

    if (title) {
      req.setTitle(title || '');
    }

    // Make the gRPC call
    return this.grpc<
      UpdateBookingSelectionReq,
      UpdateBookingSelectionRes,
      UpdateBookingSelectionRes.AsObject
    >(this.serviceClient.updateBookingSelection, req, {});
  };

  deleteRateSelection = ({
    selectionId,
    hotelId,
    roomId,
    rateId,
  }: DeleteRateSelectionReq.AsObject): Promise<DeleteRateSelectionRes.AsObject> => {
    const req = new DeleteRateSelectionReq();

    req.setSelectionId(selectionId);
    req.setHotelId(hotelId);
    req.setRoomId(roomId);
    req.setRateId(rateId);

    return this.grpc<
      DeleteRateSelectionReq,
      DeleteRateSelectionRes,
      DeleteRateSelectionRes.AsObject
    >(this.serviceClient.deleteRateSelection, req, {});
  };
}

export const hotelSelectionApiService = new HotelSelectionApi();
export default hotelSelectionApiService;
