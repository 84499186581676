import {
  flightApiService,
  ICalculationFlightFeeDomesticReqFlightPair,
} from '@tixlabs/grpc-client/web-partner';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import {
  IBookingState,
  updateCalculationFlightFee,
  updateCalculationFlightFeeRebook,
  updateMakeupPrice,
} from '@web-booker/stores/reducers/booking';
import { getFlightId } from '@web-booker/utils';
import { useAppMutation } from '../internals';
import useAirport from '../stores/useAirport';
import { syncWalletBallance } from '@web-booker/stores/reducers/user';
import useFareCheck from './useFareCheck';
import {
  EBookingStatus,
  EPaxType,
  ICalculationFlightFeeRes,
  IItemCalculationFlightFee,
} from '@tixlabs/grpc-client';
import { IListCAItem } from '@tixlabs/index';
import { useState } from 'react';
import { useCallback } from 'react';
import { Empty } from "@api/base/base_pb";

export const useBooking = () => {
  const dispatch = useAppDispatch();
  const [listCAOptions, setListCAOptions] = useState<IListCAItem[]>([]);

  const { airportsObjectByCode } = useAirport();

  const { fetchFareCheck } = useFareCheck();

  const {
    passengerQuantity,
    bookingInfo: {
      makeupPriceCalc,
      makeupPrice,
      flightDataList,
      flightDataDomesticList,
      flightDataDomesticRebookList,
      flightIdDomestic,
      flightIdDomesticRebook,
      sessionId,
      sessionIdDomesticRebook,
      bookingCode,
      expiredAt,
      flightId,
      key,
      keyDomestic,
      keyDomesticRebook,
      rebookBookingCode,
      bookingId,
    },
    bookingInfo,
    contactInfo,
    caInfo,
    manualServiceFee,
    passengersList,
    calculatedFlightFee,
    invoicingInformation,
    isInvoiceInfo,
    calculatedFlightFeeRebook,
    isCanIssue,
    isFocusIssue,
  } = useAppSelector((state) => state.booking);
  const { isDomesticSearch, isRebook, rebookIndex, isRebookFromDetail } =
    useAppSelector((state) => state.searchFlight);

  const { mutateAsync: bookingFlight, isLoading: isLoadingBooking } =
    useAppMutation({
      mutationKey: ['flightApiService', 'bookingFlight'],
      mutationFn: flightApiService?.bookingFlight,
    });

  const {
    mutateAsync: bookingFlightDomestic,
    isLoading: isLoadingBookingDomestic,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'bookingFlightDomestic'],
    mutationFn: flightApiService?.bookingFlightDomestic,
  });

  const {
    mutateAsync: reBookingFlightDomestic,
    isLoading: isLoadingReBookingFlightDomestic,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'reBookingFlightDomestic'],
    mutationFn: flightApiService?.reBookingFlightDomestic,
  });

  const {mutateAsync: listCA, isLoading} = useAppMutation({
      mutationKey: ['flightApiService', 'listCA'],
      mutationFn: flightApiService.listCA,
      onSuccess : ({isSuccess, errorCode, itemsList}) => {
          if(isSuccess) {
              setListCAOptions(itemsList);
          }
      }
  });

  const { mutateAsync: issueTicket, isLoading: isLoadingIssue } =
    useAppMutation({
      mutationKey: ['flightApiService', 'issueTicket'],
      mutationFn: flightApiService?.issueTicket,
      onSuccess: (data) => {
        if (data.isSuccess) {
          dispatch(syncWalletBallance());
        }
      },
    });

  const {
    mutateAsync: calculationFlightFee,
    isLoading: isLoadingCalculationFlightFee,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'calculationFlightFee'],
    mutationFn: flightApiService.calculationFlightFee,
  });
  const {
    mutateAsync: calculationFlightFeeDomestic,
    isLoading: isLoadingCalculationFlightFeeDomestic,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'calculationFlightFeeDomestic'],
    mutationFn: flightApiService.calculationFlightFeeDomestic,
  });

  const { mutateAsync: calcMakeupPrice, isLoading: isLoadingCalcMakeup } =
    useAppMutation({
      mutationKey: ['flightApiService', 'calcMakeupPrice'],
      mutationFn: flightApiService.calcMakeupPrice,
    });

  const { mutateAsync: cancelBookingFlight, isLoading: isLoadingCancel } =
    useAppMutation({
      mutationKey: ['flightApiService', 'cancelBookingFlight'],
      mutationFn: flightApiService?.cancelBookingFlight,
    });

  const { mutateAsync: createTransferBookingRequest } = useAppMutation({
    mutationKey: ['flightApiService', 'createTransferBookingRequest'],
    mutationFn: flightApiService.createTransferBookingRequest,
  });

  const { mutateAsync: cancelTransferBookingRequest } = useAppMutation({
    mutationKey: ['flightApiService', 'cancelTransferBookingRequest'],
    mutationFn: flightApiService.cancelTransferBookingRequest,
  });

  const getListCA = useCallback(async() => {
    const req = new Empty();
    await listCA(req);
},[]);

  const handleCalculateMakeupPrice = async (makeupPrice: number) => {
    try {
      const {
        isSuccess,
        errorCode,
        makeupPrice: makeupPriceRes,
      } = await calcMakeupPrice({
        makeupPrice,
      });
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
      dispatch(
        updateMakeupPrice({ makeupPrice, makeupPriceCalc: makeupPriceRes })
      );
    } catch (error) {
      // console.log('handleCalculateMakeupPrice: ', error);
    }
  };

  const handleCalculateFlightFee = async () => {
    try {
      const flightId = getFlightId(flightDataList);

      if (!flightId) {
        throw new Error("Can't found flight");
      }

      const { isSuccess, errorCode, data } = await calculationFlightFee({
        flightId,
        key,
        passengerQuantity,
      });
      if (isSuccess && data) {
        dispatch(updateCalculationFlightFee(data));
      }
      throw new Error(errorCode);
    } catch (error) {}
  };

  const handleCalculateFlightFeeDomestic = async () => {
    try {
      if (!flightDataDomesticList.length) {
        throw new Error("Can't found flight");
      }

      const { isSuccess, errorCode, itemsList } =
        await calculationFlightFeeDomestic({
          flightsList: flightDataDomesticList.map(
            (flightDataDomesticSelected, index) => {
              return {
                flightId:
                  flightDataDomesticSelected.classOptionsList[0].flightId,
                key: keyDomestic[index],
              };
            }
          ),
        });
      const converDataCalculationFlightFee: ICalculationFlightFeeRes = {
        itemsList: [],
        profit: 0,
        totalPrice: 0,
        vat: 0,
        vatConfig: 0,
        priceAfterPromo: 0
      };
      const calculationFlightFeeByPaxTypeMaps = new Map<
        EPaxType,
        IItemCalculationFlightFee
      >();
      itemsList.forEach((res) => {
        converDataCalculationFlightFee.profit += res.profit;
        converDataCalculationFlightFee.totalPrice += res.totalPrice;
        converDataCalculationFlightFee.vat += res.vat;
        converDataCalculationFlightFee.vatConfig += res.vatConfig;
        res.itemsList.forEach((item) => {
          const existingItem = calculationFlightFeeByPaxTypeMaps.get(item.type);
          if (existingItem) {
            // Nếu item với 'type' này đã tồn tại trong map, cộng dồn giá trị của các trường
            for (const key in item) {
              if (
                typeof item[key] === 'number' &&
                key !== 'quantity' &&
                key !== 'type'
              ) {
                existingItem[key] += item[key];
              }
            }
          } else {
            // Ngược lại, nếu chưa tồn tại, thêm item đó vào map
            calculationFlightFeeByPaxTypeMaps.set(item.type, { ...item });
          }
        });
      });

      converDataCalculationFlightFee.itemsList = Array.from(
        calculationFlightFeeByPaxTypeMaps.values()
      );

      if (isSuccess && itemsList.length) {
        dispatch(updateCalculationFlightFee(converDataCalculationFlightFee));
      } else {
        throw new Error(errorCode);
      }
    } catch (error) {}
  };

  const handleCalculateFlightFeeDomesticRebook = async () => {
    console.log(
      'handleCalculateFlightFeeDomesticRebook',
      flightDataDomesticRebookList
    );

    try {
      if (!flightDataDomesticRebookList.length) {
        // console.log('handleCalculateFlightFeeDomesticRebook???');

        throw new Error("Can't found flight");
      }
      const flightListReq: ICalculationFlightFeeDomesticReqFlightPair[] = [];
      flightDataDomesticRebookList.forEach(
        (flightDataDomesticSelected, index) => {
          if (
            flightDataDomesticSelected.status !== EBookingStatus.COMPLETE &&
            flightDataDomesticSelected.itineraryKey
          ) {
            flightListReq.push({
              flightId: flightDataDomesticSelected.classOptionsList[0].flightId,
              key: keyDomesticRebook[index],
            });
          }
        }
      );
      const { isSuccess, errorCode, itemsList } =
        await calculationFlightFeeDomestic({
          flightsList: flightListReq,
        });
      const {
        isSuccess: isSuccessRebook,
        errorCode: errorCodeRebook,
        itemsList: itemsListRebook,
      } = await calculationFlightFeeDomestic({
        flightsList: flightDataDomesticRebookList
          .filter((i) => i.status === EBookingStatus.NONE || !i.status)
          .map((flightDataDomesticSelected, index) => {
            return {
              flightId: flightDataDomesticSelected.classOptionsList[0].flightId,
              key: keyDomesticRebook[rebookIndex],
            };
          }),
      });
      // console.log('itemsList,itemsListRebook', itemsList, itemsListRebook);
      const converDataCalculationFlightFee: ICalculationFlightFeeRes = {
        itemsList: [],
        profit: 0,
        totalPrice: 0,
        vat: 0,
        vatConfig: 0,
        priceAfterPromo: 0
      };
      const converDataCalculationFlightFeeRebook: ICalculationFlightFeeRes = {
        itemsList: [],
        profit: 0,
        totalPrice: 0,
        vat: 0,
        vatConfig: 0,
        priceAfterPromo: 0
      };
      const calculationFlightFeeByPaxTypeMaps = new Map<
        EPaxType,
        IItemCalculationFlightFee
      >();
      const calculationFlightFeeByPaxTypeMapsRebook = new Map<
        EPaxType,
        IItemCalculationFlightFee
      >();
      itemsList.forEach((res) => {
        converDataCalculationFlightFee.profit += res.profit;
        converDataCalculationFlightFee.totalPrice += res.totalPrice;
        converDataCalculationFlightFee.vat += res.vat;
        converDataCalculationFlightFee.vatConfig += res.vatConfig;
        res.itemsList.forEach((item) => {
          const existingItem = calculationFlightFeeByPaxTypeMaps.get(item.type);
          if (existingItem) {
            // Nếu item với 'type' này đã tồn tại trong map, cộng dồn giá trị của các trường
            for (const key in item) {
              if (
                typeof item[key] === 'number' &&
                key !== 'quantity' &&
                key !== 'type'
              ) {
                existingItem[key] += item[key];
              }
            }
          } else {
            // Ngược lại, nếu chưa tồn tại, thêm item đó vào map
            calculationFlightFeeByPaxTypeMaps.set(item.type, { ...item });
          }
        });
      });
      itemsListRebook.forEach((res) => {
        converDataCalculationFlightFeeRebook.profit += res.profit;
        converDataCalculationFlightFeeRebook.totalPrice += res.totalPrice;
        converDataCalculationFlightFeeRebook.vat += res.vat;
        converDataCalculationFlightFeeRebook.vatConfig += res.vatConfig;
        res.itemsList.forEach((item) => {
          const existingItem = calculationFlightFeeByPaxTypeMapsRebook.get(
            item.type
          );
          if (existingItem) {
            // Nếu item với 'type' này đã tồn tại trong map, cộng dồn giá trị của các trường
            for (const key in item) {
              if (
                typeof item[key] === 'number' &&
                key !== 'quantity' &&
                key !== 'type'
              ) {
                existingItem[key] += item[key];
              }
            }
          } else {
            // Ngược lại, nếu chưa tồn tại, thêm item đó vào map
            calculationFlightFeeByPaxTypeMapsRebook.set(item.type, { ...item });
          }
        });
      });

      converDataCalculationFlightFee.itemsList = Array.from(
        calculationFlightFeeByPaxTypeMaps.values()
      );
      converDataCalculationFlightFeeRebook.itemsList = Array.from(
        calculationFlightFeeByPaxTypeMapsRebook.values()
      );

      if (isSuccess && itemsList.length && isSuccessRebook && itemsListRebook) {
        dispatch(updateCalculationFlightFee(converDataCalculationFlightFee));
        dispatch(
          updateCalculationFlightFeeRebook(converDataCalculationFlightFeeRebook)
        );
      } else {
        throw new Error(errorCode);
      }
    } catch (error) {
      // console.log('er', error);
    }
  };

  const handleCalculateFlightFeeDomesticRebookFromDetail = async () => {
    try {
      if (!flightDataDomesticRebookList.length) {
        throw new Error("Can't found flight");
      }
      const { isSuccess, errorCode, itemsList } =
        await calculationFlightFeeDomestic({
          flightsList: flightDataDomesticRebookList
            .filter((flight) => !flight.status)
            .map((flightDataDomesticSelected, index) => {
              return {
                flightId:
                  flightDataDomesticSelected.classOptionsList[0].flightId,
                key: keyDomesticRebook[rebookIndex],
              };
            }),
        });

      const converDataCalculationFlightFee: ICalculationFlightFeeRes = {
        itemsList: [],
        profit: 0,
        totalPrice: 0,
        vat: 0,
        vatConfig: 0,
        priceAfterPromo: 0
      };

      const converDataCalculationFlightFeeTotal = {
        ...calculatedFlightFee,
      };

      const calculationFlightFeeByPaxTypeMaps = new Map<
        EPaxType,
        IItemCalculationFlightFee
      >();
      const calculationFlightFeeTotalByPaxTypeMaps = new Map<
        EPaxType,
        IItemCalculationFlightFee
      >(
        converDataCalculationFlightFeeTotal.itemsList.map((data) => [
          data.type,
          {
            ...data,
          },
        ])
      );

      itemsList.forEach((res) => {
        converDataCalculationFlightFee.profit += res.profit;
        converDataCalculationFlightFee.totalPrice += res.totalPrice;
        converDataCalculationFlightFee.vat += res.vat;
        converDataCalculationFlightFee.vatConfig += res.vatConfig;
        converDataCalculationFlightFeeTotal.profit += res.profit;
        converDataCalculationFlightFeeTotal.totalPrice += res.totalPrice;
        converDataCalculationFlightFeeTotal.vat += res.vat;
        converDataCalculationFlightFeeTotal.vatConfig += res.vatConfig;
        res.itemsList.forEach((item) => {
          const existingItem = calculationFlightFeeByPaxTypeMaps.get(item.type);
          const existingItemTotal = calculationFlightFeeTotalByPaxTypeMaps.get(
            item.type
          );
          if (existingItem) {
            // Nếu item với 'type' này đã tồn tại trong map, cộng dồn giá trị của các trường
            for (const key in item) {
              if (
                typeof item[key] === 'number' &&
                key !== 'quantity' &&
                key !== 'type'
              ) {
                existingItem[key] += item[key];
              }
            }
          } else {
            // Ngược lại, nếu chưa tồn tại, thêm item đó vào map
            calculationFlightFeeByPaxTypeMaps.set(item.type, { ...item });
          }
          if (existingItemTotal) {
            // Nếu item với 'type' này đã tồn tại trong map, cộng dồn giá trị của các trường
            for (const key in item) {
              if (
                typeof item[key] === 'number' &&
                key !== 'quantity' &&
                key !== 'type'
              ) {
                existingItemTotal[key] += item[key];
              }
            }
          } else {
            // Ngược lại, nếu chưa tồn tại, thêm item đó vào map
            calculationFlightFeeTotalByPaxTypeMaps.set(item.type, { ...item });
          }
        });
      });

      converDataCalculationFlightFee.itemsList = Array.from(
        calculationFlightFeeByPaxTypeMaps.values()
      );
      converDataCalculationFlightFeeTotal.itemsList = Array.from(
        calculationFlightFeeTotalByPaxTypeMaps.values()
      );

      if (isSuccess && itemsList.length) {
        dispatch(
          updateCalculationFlightFeeRebook(converDataCalculationFlightFee)
        );
        dispatch(
          updateCalculationFlightFee(converDataCalculationFlightFeeTotal)
        );
      } else {
        throw new Error(errorCode);
      }
    } catch (error) {}
  };

  async function checkIsAllowVat(): Promise<boolean> {
    if (isDomesticSearch) {
      return true;
    }
    try {
      const fareChecked = await fetchFareCheck({ key, flightId });
      if (fareChecked?.vat) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  const fullInfoBooking = {
    passengerQuantity,
    bookingInfo,
    contactInfo,
    passengersList,
    calculatedFlightFee,
    invoicingInformation,
    isInvoiceInfo,
    caInfo,
    manualServiceFee
  } as IBookingState;
  return {
    key,
    keyDomestic,
    keyDomesticRebook,
    flightId,
    expiredAt,
    sessionId,
    bookingCode,
    makeupPrice,
    contactInfo,
    caInfo,
    manualServiceFee,
    isInvoiceInfo,
    isLoadingIssue,
    passengersList,
    makeupPriceCalc,
    isLoadingBooking: isDomesticSearch
      ? isLoadingBookingDomestic
      : isLoadingBooking,
    passengerQuantity,
    calculatedFlightFee: calculatedFlightFee,
    calculatedFlightFeeRebook,
    isLoadingCalcMakeup,
    invoicingInformation,
    flightDataList: flightDataList,
    isOnlyDomesticBooking: isDomesticSearch,
    isRebook,
    flightDataDomesticList,
    flightDataDomesticRebookList,
    flightIdDomestic,
    flightIdDomesticRebook,
    isLoadingReBookingFlightDomestic,
    listCAOptions,
    reBookingFlightDomestic,
    issueTicket,
    checkIsAllowVat,
    listCA,
    getListCA,
    bookingFlight,
    bookingFlightDomestic,
    cancelBookingFlight,
    rebookBookingCode,
    handleCalculateFlightFee: isRebookFromDetail
      ? handleCalculateFlightFeeDomesticRebookFromDetail
      : isRebook
      ? handleCalculateFlightFeeDomesticRebook
      : isDomesticSearch
      ? handleCalculateFlightFeeDomestic
      : handleCalculateFlightFee,
    handleCalculateMakeupPrice,
    isLoadingCaculationFlightFee: isDomesticSearch
      ? isLoadingCalculationFlightFeeDomestic
      : isLoadingCalculationFlightFee,
    createTransferBookingRequest,
    cancelTransferBookingRequest,
    bookingId,
    fullInfoBooking,
    isCanIssue,
    isFocusIssue,
  };
};

export default useBooking;
