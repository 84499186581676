import { BookingStatus } from '@api/hotel/base_pb';
import { CheckFillIcon, ForbidFillIcon, Ticket2FillIcon } from '@icon';
import { BOOKING_STATUS_HOTEL_LABEL } from '@vemaybay-admin/utils';
import cn from 'classnames';

type Props = {
  //
  status: BookingStatus;
  className?: string;
  classNameText?: string;
};

// const objIcon = {
//   [BookingStatus.FAILED]: <ForbidFillIcon className='text-red-6 w-4 h-4' />,
//   [BookingStatus.CANCELLED]: <ForbidFillIcon className='text-red-6 w-4 h-4' />,

//   [BookingStatus.PENDING]: (
//     <CheckFillIcon className='text-secondary-6 w-4 h-4' />
//   ),
//   [BookingStatus.REFUNDED]: <CheckFillIcon className='text-blue-500 w-4 h-4' />,
//   [BookingStatus.SUCCESS]: <Ticket2FillIcon className='text-success w-4 h-4' />,
// };



export const objClassName = {
  [BookingStatus.DRAFT]: 'text-secondary-6',
  [BookingStatus.IN_PROCESS]: 'text-secondary-6',
  [BookingStatus.CONFIRMED]: 'text-secondary-6',
  [BookingStatus.PENDING]: 'text-secondary-6',
  [BookingStatus.EXPIRED]: 'text-secondary-6',
  [BookingStatus.SUCCESS]: 'text-green-6',
  [BookingStatus.FAILED]: 'text-red-6',
  [BookingStatus.CANCELLED]: 'text-red-6',
  [BookingStatus.REFUNDED]: 'text-blue-600',
};

export const Status = ({ status, className, classNameText }: Props) => {
  return (
    <div className={cn('', className)}>
      <span
        className={cn('font-semibold', objClassName[status], classNameText)}>
        {/* {status} */}
        {BOOKING_STATUS_HOTEL_LABEL[status]}
      </span>
    </div>
  );
};
