import React from 'react';

import TextField, { TextFieldProps } from './TextField';
import { SelectOptionItemCombine, SelectSearchProps } from '../UI/SelectSearch';
import InputSearchHotel from '../UI/InputSearchHotel';

export function FormInputSearchHotel({
  inputProps = {},
  handleSearch,
  destinationDataList,
  isLoadingSearch,
  hotelName,
  ...rest
}: TextFieldProps & {
  handleSearch: (keyword: string, type: string) => void;
  isLoadingSearch: boolean;
  destinationDataList: any[];
  hotelName?: string;
} & {
  inputProps?: Pick<
    SelectSearchProps,
    | 'className'
    | 'disabled'
    | 'inputSize'
    | 'inputSearchClassName'
    | 'optionSelectSearchClassName'
    | 'optionGroupSelectSearchClassName'
    | 'isOnlyValue'
    | 'isRoot'
    | 'renderLabel'
    | 'displayValue'
    | 'compareFunc'
    | 'isAllowUncheck'
    | 'isOnlyRead'
  >;
}) {
  return (
    <TextField {...rest}>
      {({ value, ...rest }) => (
        <InputSearchHotel
          selectOptions={[]}
          {...inputProps}
          {...rest}
          handleSearch={handleSearch}
          isLoadingSearch={isLoadingSearch}
          destinationDataList={destinationDataList}
          value={value as SelectOptionItemCombine}
          {...hotelName? {hotelName: hotelName}:{}}
        />
      )}
    </TextField>
  );
}

export default FormInputSearchHotel;
