import React, { useState } from 'react';
import cn from 'classnames';
import { DownNewIcon } from '@icon';

type Props = {
  title: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  defaultShow?: boolean;
  isShadow?: boolean;
  price?: string;
};

export const CollapseCard = ({
  title,
  children,
  icon,
  className,
  defaultShow = false,
  isShadow = false,
  price,
}: Props) => {
  const [isShowContent, setIsShowContent] = useState(defaultShow);
  const handleShow = () => {
    setIsShowContent(!isShowContent);
  };

  return (
    <div className={cn({ 'shadow-card': isShadow }, className)}>
      <div
        className={cn('flex justify-between rounded-2xl py-1 delay-200', {
          'rounded-b-none !delay-0': isShowContent,
        })}>
        <div className='flex gap-2'>
          {icon && <div className='flex flex-col justify-center'>{icon}</div>}

          <div
            className={cn('', {
              'text-lg font-semibold text-gray-800': !price,
            })}>
            {title}
          </div>
          {price && (
            <div
              className='flex cursor-pointer flex-col justify-center'
              onClick={handleShow}>
              <DownNewIcon
                className={cn('h-4 w-4 transition-all duration-200', {
                  'rotate-180': isShowContent,
                })}
              />
            </div>
          )}
        </div>
        {!price ? (
          <div
            className='flex cursor-pointer flex-col justify-center'
            onClick={handleShow}>
            <DownNewIcon
              className={cn('h-6 w-6 transition-all duration-200', {
                'rotate-180': isShowContent,
              })}
            />
          </div>
        ) : (
          <span>{price}</span>
        )}
      </div>
      <div className='overflow-hidden'>
        <div
          className={cn(
            'transition-all duration-200',
            { 'max-h-0 -translate-y-full': !isShowContent },
            { 'max-h-full -translate-y-0 py-2': isShowContent }
          )}
          style={{ maxHeight: isShowContent ? 'none' : 0 }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CollapseCard;
