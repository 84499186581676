import { AppErrorBoundary } from '@common-ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider as ThemeConfigProviderAntd } from 'antd';
import { FC, ReactNode } from 'react';
import { ToastBar, Toaster } from 'react-hot-toast';
import { Provider as ReduxProvide } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { AppLogicProvider, TranslationProvider } from './containers/Providers';
import { persistor, store } from './stores';
import { themeConfig } from './utils';

type AppProps = {
  children: ReactNode;
};

const queryClient = new QueryClient();

/**
 * Set your global app-providers (i.e: redux, react-query,...) here
 */

export const AppProviders: FC<AppProps> = (props) => {
  return (
    <AppErrorBoundary>
      <ReduxProvide store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <TranslationProvider>
              <ThemeConfigProviderAntd theme={themeConfig}>
                <>{props.children}</>
                <Toaster
                  containerStyle={{
                    top: '100px',
                  }}
                  position='top-right'>
                  {(t) => (
                    <ToastBar
                      toast={t}
                      style={{
                        ...t.style,
                      }}
                    />
                  )}
                </Toaster>
              </ThemeConfigProviderAntd>
            </TranslationProvider>
          </QueryClientProvider>
        </PersistGate>
      </ReduxProvide>
    </AppErrorBoundary>
  );
};

export default AppProviders;
