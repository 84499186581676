import {
  HiddenFee,
  HiddenFeeType,
  HotelLocationType,
} from '@api/skyhub/hidden_fee_pb';

export type EHiddenFeeType = HiddenFeeType;
export const EHiddenFeeType = { ...HiddenFeeType } as const;

export type EHotelLocationType = HotelLocationType;
export const EHotelLocationType = { ...HotelLocationType } as const;

export interface IHiddenFee extends HiddenFee.AsObject {}
