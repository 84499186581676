import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import cn from 'classnames';
import {
  ForwardedRef,
  Fragment,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  InputSize,
  LoadingRing,
  PassPropsType,
  TBaseInputProps,
} from '@common-ui';
import { ByComparator } from '@headlessui/react/dist/types';
import { SelectOptionItem } from '@tixlabs/types';

import VirtualList from 'rc-virtual-list';
import { useDebounceValueSearchHotel, useThrottleValue } from '@core/hooks';
import { toLowerCaseNonAccentVietnamese } from '@core/utils';
import { Spin } from 'antd';
import { ConfigServicePromiseClient } from '@api/airplane/web_partner_admin/config_grpc_web_pb';

type VirtualListProps = Parameters<typeof VirtualList>[number];

export type SelectOptionItemCombine =
  | SelectOptionItem<unknown>
  | string
  | number
  | boolean
  | undefined;

export type SelectSearchProps = {
  isLoadingSearch: boolean;
  destinationDataList: any[];
  isOnlyRead?: boolean;
  className?: string;
  // selectButtonClassName?: string;
  inputSearchClassName?: string;
  optionSelectSearchClassName?: string;
  optionGroupSelectSearchClassName?: string;
  selectOptions: SelectOptionItem[];
  compareBy?: ByComparator<SelectOptionItemCombine>;
  disabled?: boolean;
  isOnlyValue?: boolean;
  isRoot?: boolean;
  isVirtualMode?: boolean;
  isAllowUncheck?: boolean;
  isUnCheck?: boolean;
  autoFocus?: boolean;
  autoComplete?: string;
  hotelName?: string;
  compareFunc?: (query: string, option: SelectOptionItem) => boolean;
  handleSearch: (keyword: string, type: string) => void;
  renderLabel?: (option: SelectOptionItem) => ReactNode;
  displayValue?: (option: SelectOptionItem | undefined) => string;
  virtualListProps?: Omit<VirtualListProps, 'data'>;
} & PassPropsType<SelectOptionItemCombine> &
  TBaseInputProps;

const defaultCompare: ByComparator<SelectOptionItemCombine> = (a, b) => {
  if (typeof a === 'object' && typeof b === 'object') {
    return a.value === b.value;
  }

  // (isOnlyValue ? value : (value as SelectOptionItem)?.value)
  return a === b;
  // return a.name.toLowerCase() === b.name.toLowerCase()
};

const defaultFilter = (option: SelectOptionItem, query: string) => {
  const textCompare = toLowerCaseNonAccentVietnamese(
    JSON.stringify(option.label + option.label)?.toLowerCase()
  );
  return textCompare.includes(query.toLowerCase());
};

function SelectSearchInner(
  {
    isLoadingSearch,
    destinationDataList,
    name,
    selectOptions,
    compareBy = defaultCompare,
    isUnCheck,
    disabled,
    className,
    inputSearchClassName,
    optionSelectSearchClassName,
    optionGroupSelectSearchClassName,
    isError,
    placeholder,
    value,
    onChange,
    hotelName,
    inputSize = InputSize.SM,
    isOnlyValue,
    isRoot = true,
    handleSearch,
    renderLabel,
    displayValue,
    compareFunc,
    isVirtualMode,
    virtualListProps,
    isAllowUncheck = false,
    isOnlyRead = false,
    autoFocus = false,
    autoComplete = 'off',
  }: SelectSearchProps,
  // }: SelectSearchProps<SelectOptionItem<unknown>[][number]>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [query, setQuery] = useState('');
  const [valueInput, setValueInput] = useState('');
  const [isFocusSearch, setIsFocusSearch] = useState(false);
  const trimQuery = query.trim();
  const optionsRef = useRef<HTMLDivElement>(null);
  const { debouncedValue: debouncedQuery, isDebouncing } =
    useDebounceValueSearchHotel<string>(trimQuery, 800);
  const throttleQuery = useThrottleValue<string>(debouncedQuery, 500);

  const [isTyping, setIsTyping] = useState(false);
  const [hotelNameData, setHotelNameData] = useState('');

  useEffect(() => {
    if (trimQuery.length >= 3) {
      setIsTyping(true);
      const timer = setTimeout(() => {
        setIsTyping(false);
      }, 900);

      return () => clearTimeout(timer);
    } else {
      setIsTyping(false);
    }
  }, [trimQuery]);

  const isSearch = isTyping || isLoadingSearch;
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
      onChange?.(undefined); // Optionally reset the value state if needed
    }
  };

  useEffect(() => {
    if (throttleQuery.length >= 3) {
      handleSearch(throttleQuery, 'search');
    }
  }, [throttleQuery]);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsFocusSearch(false);
      }
    }
    hotelName && setHotelNameData(hotelName);

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getDisplayValue = (e: any) => {
    if (!e || query === '') {
      return '';
    }
    return [e.name].join(', ').replace(/\+/g, ' ');
  };

  useEffect(()=>{
    if(query === ''){
      if(onChange){
        onChange('')
        return;
      }
    }
  }, [query])

  const [selectedTab, setSelectedTab] = useState(0);

  const handleOnChange = (newValueOption: any) => {
    setValueInput(newValueOption.name);

    if (!onChange || newValueOption === undefined) {
      return;
    }
    if (query === '') {
      onChange('');
      return;
    }
    onChange(newValueOption);
    return;
  };

  return (
    <div className={cn('text-black', className)}>
      <Combobox value={value} disabled={!!disabled} onChange={handleOnChange}>
        <div
          className={cn({
            relative: isRoot,
          })}>
          <div
            className={cn(
              'base-select relative flex space-x-2.5 items-center',
              `base-select-${inputSize}`,
              inputSearchClassName,
              // isError && '!ring-common-error'
              {
                'bg-theme-black/5 ': disabled,
                error: isError,
              }
            )}>
            <Combobox.Input
              onFocus={() => {
                setIsFocusSearch(true);
              }}
              className={cn(
                'w-full bg-transparent text-left focus:outline-none focus:ring-0 focus:border-none'
              )}
              {...(hotelNameData && hotelNameData !== ''
                ? { value: hotelName }
                : {})}
              displayValue={(e) => getDisplayValue(e)}
              onChange={(event) => {
                setHotelNameData('');
                setQuery(event.target.value);
              }}
              onClick={() => setIsFocusSearch(true)}
              placeholder={placeholder}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
            />
          </div>
          {query && query.length >= 3 && (
            <Transition
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <div
                ref={optionsRef}
                className={cn(
                  'text-bi-sm md:text-bi-base absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                  {
                    'py-2': trimQuery !== '' || destinationDataList.length,
                  },
                  optionGroupSelectSearchClassName
                )}>
                <Combobox.Options
                  className={cn([
                    'bizi-absolute bizi-z-popover bizi-mt-1 bizi-max-h-[366px] md:bizi-w-[440px] bizi-w-full bizi-overflow-auto bizi-rounded-xl bizi-text-sm',
                    'bizi-bg-theme-white bizi-shadow-popover-search-flight',
                    {
                      'bizi-py-2 !mb-0':
                        trimQuery !== '' || destinationDataList.length,
                    },
                  ])}>
                  {isSearch ? (
                    <div className='flex justify-center items-center w-full py-3'>
                      <LoadingRing size='md' />
                    </div>
                  ) : destinationDataList.length === 0 ? (
                    <div className='relative cursor-default select-none px-4 py-2 text-gray-700'>
                      Nothing found.
                    </div>
                  ) : (
                    destinationDataList.map((destination) => (
                      <Combobox.Option
                        key={destination.id}
                        value={destination}
                        className={({ active, selected }) =>
                          cn(
                            'relative cursor-pointer select-none pr-2 text-theme-black',
                            active || selected ? 'bg-primary/5 ' : ''
                          )
                        }>
                        <div className='px-5 py-3 w-full'>
                          {destination.name} <br />
                          <span className='text-[#b6b5b5]'>
                            {destination.address}
                          </span>
                        </div>
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </div>
            </Transition>
          )}
        </div>
      </Combobox>
    </div>
  );
}
export const InputSearchHotel = forwardRef(SelectSearchInner);
InputSearchHotel.displayName = 'InputSearchHotel';

export default InputSearchHotel;
