import { BookingStatus } from '@api/hotel/base_pb';
import { TransactionType, PaymentMethod } from '@api/base/enum_pb';
import { Transaction } from '@api/hotel/transaction_pb';

export type EBookingStatusHotel = BookingStatus;
export const EBookingStatusHotel = {
  ...BookingStatus,
} as const;

export type ETransactionHotelType = TransactionType;
export const ETransactionHotelType = { ...TransactionType } as const;

export type EPaymentMethoddHotelType = PaymentMethod;
export const EPaymentMethoddHotelType = { ...PaymentMethod } as const;

export interface ITransactionHotel extends Transaction.AsObject {}
