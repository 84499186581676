import {
  hotelApiServiceWebPartnership
} from '@tixlabs/grpc-client/web-partnership';

import { useAppMutation } from '../internals';


export const useHotel = () => {
  const {
    mutateAsync: updateBookingStatus,
    isLoading: isLoadingBookingStatus,
  } = useAppMutation({
    mutationKey: ['hotelApiServiceWebPartnership', 'listBookings'],
    mutationFn: hotelApiServiceWebPartnership.updateBookingStatus,
    onError: (err) => {
      console.log('oops...', err);
    },
  });
  return { updateBookingStatus, isLoadingBookingStatus };
};

export default useHotel;
