import {
  AgentGroupTransaction,
  GetAgentGroupsReqFilter,
  GetGroupTransactionHistoriesResData,
  PartnerAgentGroup,
  TransactionType,
} from '@api/partner/web_partner/partner_agent_group_pb';

export interface IDebtReqFilter extends GetAgentGroupsReqFilter.AsObject {}
export interface IDebtGroup extends PartnerAgentGroup.AsObject {}
export interface IGetGroupTransactionHistoriesResData
  extends GetGroupTransactionHistoriesResData.AsObject {}
export interface IAgentGroupTransaction
  extends AgentGroupTransaction.AsObject {}

export type ETransactionType = TransactionType;
export const ETransactionType = {
  ...TransactionType,
} as const;
