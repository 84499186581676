import { formatCurrency as formatCurrencyUtils } from '@core/utils';
import { useAppSelector } from '@vemaybay-admin/stores';
import { ECurrency } from '@vemaybay-admin/types';

export function useCurrency() {
  const currentCurrency = useAppSelector((state) => state.common.currency);
  const defaultCurrency = '₫';
  function formatPrice(amount: number, currency?: string) {
    if (currency) {
      return formatCurrencyUtils(
        amount,
        currency.toLocaleLowerCase() === ECurrency.VND ? '0,0' : '0,0.00'
      );
    } else {
      return formatCurrencyUtils(
        amount,
        currentCurrency.value === ECurrency.VND ? '0,0' : '0,0.00'
      );
    }
  }

  function formatCurrency(amount: number, currency?: string) {
    if (currency) {
      return `${formatPrice(amount, currency)} ${currency}`;
    } else {
      return `${formatPrice(amount)} ${defaultCurrency}`;
    }
  }

  function formatCurrencyCustom(amount: number, currency?: string) {
    if (currency) {
      return currency === 'VND'
        ? `${formatPrice(amount, currency)} ${defaultCurrency}`
        : `${formatPrice(amount, currency)} ${currency}`;
    } else {
      return `${formatPrice(amount)} ${defaultCurrency}`;
    }
  }

  function formatPriceCustome(amount: number, currency?: string) {
    // new
    const hasDecimal = amount % 1 !== 0;

    if (hasDecimal && amount) {
      // Tách phần nguyên và phần thập phân
      const [integerPart, decimalPart] = amount.toString().split('.');

      // Định dạng phần nguyên với dấu phẩy ngăn cách phần nghìn
      const formattedInteger = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ','
      );

      // Kết hợp phần nguyên và phần thập phân
      const formattedAmount = `${formattedInteger}.${decimalPart}`;

      // Thêm currency nếu có
      return formattedAmount;
    } else {
      // Nếu không có phần thập phân, dùng định dạng mặc định
      return formatCurrencyUtils(
        amount,
        currency
          ? currency.toLocaleLowerCase() === ECurrency.VND
            ? '0,0'
            : '0,0'
          : currentCurrency.value === ECurrency.VND
          ? '0,0'
          : '0,0'
      );
    }
  }

  function formatCurrencyHaveDecimal(amount: number, currency?: string) {
    if (currency) {
      return currency === 'VND'
        ? `${formatPriceCustome(amount, currency)} ${defaultCurrency}`
        : `${formatPriceCustome(amount, currency)} ${currency}`;
    } else {
      return `${formatPriceCustome(amount)} ${defaultCurrency}`;
    }
  }

  return {
    formatCurrencyHaveDecimal,
    formatCurrencyCustom,
    formatCurrency,
    formatPrice,
    currentCurrency,
    currency: currentCurrency.text,
  };
}

export default useCurrency;
