// source: hotel/booking_selection.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var hotel_base_pb = require('../hotel/base_pb.js');
goog.object.extend(proto, hotel_base_pb);
var hotel_hotel_pb = require('../hotel/hotel_pb.js');
goog.object.extend(proto, hotel_hotel_pb);
goog.exportSymbol('proto.hotel.BookingSelection', null, global);
goog.exportSymbol('proto.hotel.BookingSelectionBasicInfo', null, global);
goog.exportSymbol('proto.hotel.BookingSelectionHotel', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.BookingSelectionBasicInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.hotel.BookingSelectionBasicInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.BookingSelectionBasicInfo.displayName = 'proto.hotel.BookingSelectionBasicInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.BookingSelection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.BookingSelection.repeatedFields_, null);
};
goog.inherits(proto.hotel.BookingSelection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.BookingSelection.displayName = 'proto.hotel.BookingSelection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.hotel.BookingSelectionHotel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.hotel.BookingSelectionHotel.repeatedFields_, null);
};
goog.inherits(proto.hotel.BookingSelectionHotel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.hotel.BookingSelectionHotel.displayName = 'proto.hotel.BookingSelectionHotel';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.BookingSelectionBasicInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.BookingSelectionBasicInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.BookingSelectionBasicInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BookingSelectionBasicInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    destination: (f = msg.getDestination()) && hotel_base_pb.Destination.toObject(includeInstance, f),
    editable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.BookingSelectionBasicInfo}
 */
proto.hotel.BookingSelectionBasicInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.BookingSelectionBasicInfo;
  return proto.hotel.BookingSelectionBasicInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.BookingSelectionBasicInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.BookingSelectionBasicInfo}
 */
proto.hotel.BookingSelectionBasicInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = new hotel_base_pb.Destination;
      reader.readMessage(value,hotel_base_pb.Destination.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.BookingSelectionBasicInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.BookingSelectionBasicInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.BookingSelectionBasicInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BookingSelectionBasicInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      hotel_base_pb.Destination.serializeBinaryToWriter
    );
  }
  f = message.getEditable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string selection_id = 1;
 * @return {string}
 */
proto.hotel.BookingSelectionBasicInfo.prototype.getSelectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingSelectionBasicInfo} returns this
 */
proto.hotel.BookingSelectionBasicInfo.prototype.setSelectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.hotel.BookingSelectionBasicInfo.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingSelectionBasicInfo} returns this
 */
proto.hotel.BookingSelectionBasicInfo.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 updated_at = 3;
 * @return {number}
 */
proto.hotel.BookingSelectionBasicInfo.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BookingSelectionBasicInfo} returns this
 */
proto.hotel.BookingSelectionBasicInfo.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Destination destination = 4;
 * @return {?proto.hotel.Destination}
 */
proto.hotel.BookingSelectionBasicInfo.prototype.getDestination = function() {
  return /** @type{?proto.hotel.Destination} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.Destination, 4));
};


/**
 * @param {?proto.hotel.Destination|undefined} value
 * @return {!proto.hotel.BookingSelectionBasicInfo} returns this
*/
proto.hotel.BookingSelectionBasicInfo.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingSelectionBasicInfo} returns this
 */
proto.hotel.BookingSelectionBasicInfo.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingSelectionBasicInfo.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool editable = 5;
 * @return {boolean}
 */
proto.hotel.BookingSelectionBasicInfo.prototype.getEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.BookingSelectionBasicInfo} returns this
 */
proto.hotel.BookingSelectionBasicInfo.prototype.setEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.BookingSelection.repeatedFields_ = [7,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.BookingSelection.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.BookingSelection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.BookingSelection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BookingSelection.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    destination: (f = msg.getDestination()) && hotel_base_pb.Destination.toObject(includeInstance, f),
    partner: (f = msg.getPartner()) && hotel_base_pb.PartnerInfo.toObject(includeInstance, f),
    content: jspb.Message.getFieldWithDefault(msg, 6, ""),
    hotelsList: jspb.Message.toObjectList(msg.getHotelsList(),
    proto.hotel.BookingSelectionHotel.toObject, includeInstance),
    checkIn: jspb.Message.getFieldWithDefault(msg, 8, ""),
    checkOut: jspb.Message.getFieldWithDefault(msg, 9, ""),
    occupanciesList: jspb.Message.toObjectList(msg.getOccupanciesList(),
    hotel_base_pb.OccupancyRequest.toObject, includeInstance),
    editable: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.BookingSelection}
 */
proto.hotel.BookingSelection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.BookingSelection;
  return proto.hotel.BookingSelection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.BookingSelection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.BookingSelection}
 */
proto.hotel.BookingSelection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelectionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = new hotel_base_pb.Destination;
      reader.readMessage(value,hotel_base_pb.Destination.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    case 5:
      var value = new hotel_base_pb.PartnerInfo;
      reader.readMessage(value,hotel_base_pb.PartnerInfo.deserializeBinaryFromReader);
      msg.setPartner(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 7:
      var value = new proto.hotel.BookingSelectionHotel;
      reader.readMessage(value,proto.hotel.BookingSelectionHotel.deserializeBinaryFromReader);
      msg.addHotels(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckIn(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckOut(value);
      break;
    case 10:
      var value = new hotel_base_pb.OccupancyRequest;
      reader.readMessage(value,hotel_base_pb.OccupancyRequest.deserializeBinaryFromReader);
      msg.addOccupancies(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEditable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.BookingSelection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.BookingSelection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.BookingSelection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BookingSelection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      hotel_base_pb.Destination.serializeBinaryToWriter
    );
  }
  f = message.getPartner();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      hotel_base_pb.PartnerInfo.serializeBinaryToWriter
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHotelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.hotel.BookingSelectionHotel.serializeBinaryToWriter
    );
  }
  f = message.getCheckIn();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCheckOut();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOccupanciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      hotel_base_pb.OccupancyRequest.serializeBinaryToWriter
    );
  }
  f = message.getEditable();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional string selection_id = 1;
 * @return {string}
 */
proto.hotel.BookingSelection.prototype.getSelectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingSelection} returns this
 */
proto.hotel.BookingSelection.prototype.setSelectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.hotel.BookingSelection.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingSelection} returns this
 */
proto.hotel.BookingSelection.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 updated_at = 3;
 * @return {number}
 */
proto.hotel.BookingSelection.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.hotel.BookingSelection} returns this
 */
proto.hotel.BookingSelection.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional Destination destination = 4;
 * @return {?proto.hotel.Destination}
 */
proto.hotel.BookingSelection.prototype.getDestination = function() {
  return /** @type{?proto.hotel.Destination} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.Destination, 4));
};


/**
 * @param {?proto.hotel.Destination|undefined} value
 * @return {!proto.hotel.BookingSelection} returns this
*/
proto.hotel.BookingSelection.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingSelection} returns this
 */
proto.hotel.BookingSelection.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingSelection.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PartnerInfo partner = 5;
 * @return {?proto.hotel.PartnerInfo}
 */
proto.hotel.BookingSelection.prototype.getPartner = function() {
  return /** @type{?proto.hotel.PartnerInfo} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.PartnerInfo, 5));
};


/**
 * @param {?proto.hotel.PartnerInfo|undefined} value
 * @return {!proto.hotel.BookingSelection} returns this
*/
proto.hotel.BookingSelection.prototype.setPartner = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingSelection} returns this
 */
proto.hotel.BookingSelection.prototype.clearPartner = function() {
  return this.setPartner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingSelection.prototype.hasPartner = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string content = 6;
 * @return {string}
 */
proto.hotel.BookingSelection.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingSelection} returns this
 */
proto.hotel.BookingSelection.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated BookingSelectionHotel hotels = 7;
 * @return {!Array<!proto.hotel.BookingSelectionHotel>}
 */
proto.hotel.BookingSelection.prototype.getHotelsList = function() {
  return /** @type{!Array<!proto.hotel.BookingSelectionHotel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.hotel.BookingSelectionHotel, 7));
};


/**
 * @param {!Array<!proto.hotel.BookingSelectionHotel>} value
 * @return {!proto.hotel.BookingSelection} returns this
*/
proto.hotel.BookingSelection.prototype.setHotelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.hotel.BookingSelectionHotel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.BookingSelectionHotel}
 */
proto.hotel.BookingSelection.prototype.addHotels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.hotel.BookingSelectionHotel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.BookingSelection} returns this
 */
proto.hotel.BookingSelection.prototype.clearHotelsList = function() {
  return this.setHotelsList([]);
};


/**
 * optional string check_in = 8;
 * @return {string}
 */
proto.hotel.BookingSelection.prototype.getCheckIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingSelection} returns this
 */
proto.hotel.BookingSelection.prototype.setCheckIn = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string check_out = 9;
 * @return {string}
 */
proto.hotel.BookingSelection.prototype.getCheckOut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingSelection} returns this
 */
proto.hotel.BookingSelection.prototype.setCheckOut = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated OccupancyRequest occupancies = 10;
 * @return {!Array<!proto.hotel.OccupancyRequest>}
 */
proto.hotel.BookingSelection.prototype.getOccupanciesList = function() {
  return /** @type{!Array<!proto.hotel.OccupancyRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.OccupancyRequest, 10));
};


/**
 * @param {!Array<!proto.hotel.OccupancyRequest>} value
 * @return {!proto.hotel.BookingSelection} returns this
*/
proto.hotel.BookingSelection.prototype.setOccupanciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.hotel.OccupancyRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.OccupancyRequest}
 */
proto.hotel.BookingSelection.prototype.addOccupancies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.hotel.OccupancyRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.BookingSelection} returns this
 */
proto.hotel.BookingSelection.prototype.clearOccupanciesList = function() {
  return this.setOccupanciesList([]);
};


/**
 * optional bool editable = 11;
 * @return {boolean}
 */
proto.hotel.BookingSelection.prototype.getEditable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.hotel.BookingSelection} returns this
 */
proto.hotel.BookingSelection.prototype.setEditable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.hotel.BookingSelectionHotel.repeatedFields_ = [3,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.hotel.BookingSelectionHotel.prototype.toObject = function(opt_includeInstance) {
  return proto.hotel.BookingSelectionHotel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.hotel.BookingSelectionHotel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BookingSelectionHotel.toObject = function(includeInstance, msg) {
  var f, obj = {
    basicInfo: (f = msg.getBasicInfo()) && hotel_base_pb.HotelBasicInfo.toObject(includeInstance, f),
    categoryType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    hotel_base_pb.ImageData.toObject, includeInstance),
    centerInfo: (f = msg.getCenterInfo()) && hotel_base_pb.CenterInfo.toObject(includeInstance, f),
    review: (f = msg.getReview()) && hotel_base_pb.HotelReview.toObject(includeInstance, f),
    locationInfo: (f = msg.getLocationInfo()) && hotel_hotel_pb.HotelLocationInfo.toObject(includeInstance, f),
    amenityGroupsList: jspb.Message.toObjectList(msg.getAmenityGroupsList(),
    hotel_base_pb.HubGroupAmenity.toObject, includeInstance),
    roomRateList: jspb.Message.toObjectList(msg.getRoomRateList(),
    hotel_hotel_pb.RoomRate.toObject, includeInstance),
    description: (f = msg.getDescription()) && hotel_hotel_pb.Description.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.hotel.BookingSelectionHotel}
 */
proto.hotel.BookingSelectionHotel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.hotel.BookingSelectionHotel;
  return proto.hotel.BookingSelectionHotel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.hotel.BookingSelectionHotel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.hotel.BookingSelectionHotel}
 */
proto.hotel.BookingSelectionHotel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new hotel_base_pb.HotelBasicInfo;
      reader.readMessage(value,hotel_base_pb.HotelBasicInfo.deserializeBinaryFromReader);
      msg.setBasicInfo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryType(value);
      break;
    case 3:
      var value = new hotel_base_pb.ImageData;
      reader.readMessage(value,hotel_base_pb.ImageData.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 4:
      var value = new hotel_base_pb.CenterInfo;
      reader.readMessage(value,hotel_base_pb.CenterInfo.deserializeBinaryFromReader);
      msg.setCenterInfo(value);
      break;
    case 5:
      var value = new hotel_base_pb.HotelReview;
      reader.readMessage(value,hotel_base_pb.HotelReview.deserializeBinaryFromReader);
      msg.setReview(value);
      break;
    case 6:
      var value = new hotel_hotel_pb.HotelLocationInfo;
      reader.readMessage(value,hotel_hotel_pb.HotelLocationInfo.deserializeBinaryFromReader);
      msg.setLocationInfo(value);
      break;
    case 7:
      var value = new hotel_base_pb.HubGroupAmenity;
      reader.readMessage(value,hotel_base_pb.HubGroupAmenity.deserializeBinaryFromReader);
      msg.addAmenityGroups(value);
      break;
    case 8:
      var value = new hotel_hotel_pb.RoomRate;
      reader.readMessage(value,hotel_hotel_pb.RoomRate.deserializeBinaryFromReader);
      msg.addRoomRate(value);
      break;
    case 9:
      var value = new hotel_hotel_pb.Description;
      reader.readMessage(value,hotel_hotel_pb.Description.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.hotel.BookingSelectionHotel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.hotel.BookingSelectionHotel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.hotel.BookingSelectionHotel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.hotel.BookingSelectionHotel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBasicInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      hotel_base_pb.HotelBasicInfo.serializeBinaryToWriter
    );
  }
  f = message.getCategoryType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      hotel_base_pb.ImageData.serializeBinaryToWriter
    );
  }
  f = message.getCenterInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      hotel_base_pb.CenterInfo.serializeBinaryToWriter
    );
  }
  f = message.getReview();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      hotel_base_pb.HotelReview.serializeBinaryToWriter
    );
  }
  f = message.getLocationInfo();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      hotel_hotel_pb.HotelLocationInfo.serializeBinaryToWriter
    );
  }
  f = message.getAmenityGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      hotel_base_pb.HubGroupAmenity.serializeBinaryToWriter
    );
  }
  f = message.getRoomRateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      hotel_hotel_pb.RoomRate.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      hotel_hotel_pb.Description.serializeBinaryToWriter
    );
  }
};


/**
 * optional HotelBasicInfo basic_info = 1;
 * @return {?proto.hotel.HotelBasicInfo}
 */
proto.hotel.BookingSelectionHotel.prototype.getBasicInfo = function() {
  return /** @type{?proto.hotel.HotelBasicInfo} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.HotelBasicInfo, 1));
};


/**
 * @param {?proto.hotel.HotelBasicInfo|undefined} value
 * @return {!proto.hotel.BookingSelectionHotel} returns this
*/
proto.hotel.BookingSelectionHotel.prototype.setBasicInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingSelectionHotel} returns this
 */
proto.hotel.BookingSelectionHotel.prototype.clearBasicInfo = function() {
  return this.setBasicInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingSelectionHotel.prototype.hasBasicInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string category_type = 2;
 * @return {string}
 */
proto.hotel.BookingSelectionHotel.prototype.getCategoryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.hotel.BookingSelectionHotel} returns this
 */
proto.hotel.BookingSelectionHotel.prototype.setCategoryType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ImageData images = 3;
 * @return {!Array<!proto.hotel.ImageData>}
 */
proto.hotel.BookingSelectionHotel.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.hotel.ImageData>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.ImageData, 3));
};


/**
 * @param {!Array<!proto.hotel.ImageData>} value
 * @return {!proto.hotel.BookingSelectionHotel} returns this
*/
proto.hotel.BookingSelectionHotel.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.hotel.ImageData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.ImageData}
 */
proto.hotel.BookingSelectionHotel.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.hotel.ImageData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.BookingSelectionHotel} returns this
 */
proto.hotel.BookingSelectionHotel.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional CenterInfo center_info = 4;
 * @return {?proto.hotel.CenterInfo}
 */
proto.hotel.BookingSelectionHotel.prototype.getCenterInfo = function() {
  return /** @type{?proto.hotel.CenterInfo} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.CenterInfo, 4));
};


/**
 * @param {?proto.hotel.CenterInfo|undefined} value
 * @return {!proto.hotel.BookingSelectionHotel} returns this
*/
proto.hotel.BookingSelectionHotel.prototype.setCenterInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingSelectionHotel} returns this
 */
proto.hotel.BookingSelectionHotel.prototype.clearCenterInfo = function() {
  return this.setCenterInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingSelectionHotel.prototype.hasCenterInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional HotelReview review = 5;
 * @return {?proto.hotel.HotelReview}
 */
proto.hotel.BookingSelectionHotel.prototype.getReview = function() {
  return /** @type{?proto.hotel.HotelReview} */ (
    jspb.Message.getWrapperField(this, hotel_base_pb.HotelReview, 5));
};


/**
 * @param {?proto.hotel.HotelReview|undefined} value
 * @return {!proto.hotel.BookingSelectionHotel} returns this
*/
proto.hotel.BookingSelectionHotel.prototype.setReview = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingSelectionHotel} returns this
 */
proto.hotel.BookingSelectionHotel.prototype.clearReview = function() {
  return this.setReview(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingSelectionHotel.prototype.hasReview = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional HotelLocationInfo location_info = 6;
 * @return {?proto.hotel.HotelLocationInfo}
 */
proto.hotel.BookingSelectionHotel.prototype.getLocationInfo = function() {
  return /** @type{?proto.hotel.HotelLocationInfo} */ (
    jspb.Message.getWrapperField(this, hotel_hotel_pb.HotelLocationInfo, 6));
};


/**
 * @param {?proto.hotel.HotelLocationInfo|undefined} value
 * @return {!proto.hotel.BookingSelectionHotel} returns this
*/
proto.hotel.BookingSelectionHotel.prototype.setLocationInfo = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingSelectionHotel} returns this
 */
proto.hotel.BookingSelectionHotel.prototype.clearLocationInfo = function() {
  return this.setLocationInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingSelectionHotel.prototype.hasLocationInfo = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated HubGroupAmenity amenity_groups = 7;
 * @return {!Array<!proto.hotel.HubGroupAmenity>}
 */
proto.hotel.BookingSelectionHotel.prototype.getAmenityGroupsList = function() {
  return /** @type{!Array<!proto.hotel.HubGroupAmenity>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_base_pb.HubGroupAmenity, 7));
};


/**
 * @param {!Array<!proto.hotel.HubGroupAmenity>} value
 * @return {!proto.hotel.BookingSelectionHotel} returns this
*/
proto.hotel.BookingSelectionHotel.prototype.setAmenityGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.hotel.HubGroupAmenity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.HubGroupAmenity}
 */
proto.hotel.BookingSelectionHotel.prototype.addAmenityGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.hotel.HubGroupAmenity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.BookingSelectionHotel} returns this
 */
proto.hotel.BookingSelectionHotel.prototype.clearAmenityGroupsList = function() {
  return this.setAmenityGroupsList([]);
};


/**
 * repeated RoomRate room_rate = 8;
 * @return {!Array<!proto.hotel.RoomRate>}
 */
proto.hotel.BookingSelectionHotel.prototype.getRoomRateList = function() {
  return /** @type{!Array<!proto.hotel.RoomRate>} */ (
    jspb.Message.getRepeatedWrapperField(this, hotel_hotel_pb.RoomRate, 8));
};


/**
 * @param {!Array<!proto.hotel.RoomRate>} value
 * @return {!proto.hotel.BookingSelectionHotel} returns this
*/
proto.hotel.BookingSelectionHotel.prototype.setRoomRateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.hotel.RoomRate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.hotel.RoomRate}
 */
proto.hotel.BookingSelectionHotel.prototype.addRoomRate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.hotel.RoomRate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.hotel.BookingSelectionHotel} returns this
 */
proto.hotel.BookingSelectionHotel.prototype.clearRoomRateList = function() {
  return this.setRoomRateList([]);
};


/**
 * optional Description description = 9;
 * @return {?proto.hotel.Description}
 */
proto.hotel.BookingSelectionHotel.prototype.getDescription = function() {
  return /** @type{?proto.hotel.Description} */ (
    jspb.Message.getWrapperField(this, hotel_hotel_pb.Description, 9));
};


/**
 * @param {?proto.hotel.Description|undefined} value
 * @return {!proto.hotel.BookingSelectionHotel} returns this
*/
proto.hotel.BookingSelectionHotel.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.hotel.BookingSelectionHotel} returns this
 */
proto.hotel.BookingSelectionHotel.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.hotel.BookingSelectionHotel.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 9) != null;
};


goog.object.extend(exports, proto.hotel);
