import React, { forwardRef } from 'react';
import cn from 'classnames';
import { useCurrency, useTime } from '@vemaybay-admin/hooks/internals';
import { useCountry } from '@vemaybay-admin/hooks/apps';
import DOMPurify from 'dompurify';
import {
  IBookingDetail,
} from '@tixlabs/grpc-client/web-partnership';
import { Rate } from 'antd';
type Props = {
  className?: string;
  bookingData: IBookingDetail;
  orderCode: string;
};

export const PrintConfirmHotel = forwardRef<HTMLDivElement, Props>(
  ({ orderCode, className, bookingData }, ref) => {
    const { formatTimeDate, formatDateDayMonthYear } = useTime();
    const { getCountryByCode } = useCountry();
    const { formatCurrencyHaveDecimal } = useCurrency();

    const countryCode = bookingData.hotelInfo?.address?.countryCode;

    const countryName = countryCode
      ? getCountryByCode(countryCode)?.country
      : 'Unknown Country';
    return (
      <div className={cn('flex flex-col bg-white', className)} ref={ref}>
        {/* Header */}
        <div className='bg-[#00264E] flex items-center justify-between gap-2 bg-header px-5 py-6'>
          <img
            style={{ width: '108px', height: '28px' }}
            src='assets/images/logo-vemaybay_white.png'
            alt='logo'
          />
          <div className='text-white text-end'>
            {/* <div className='text-neutral-1 mb-3'>
              {partnerShopBasicInfo?.name}
            </div> */}
            <div className='text-neutral-1 text-base'>
              Hotline: (+84) 932 126 988
            </div>
            <div className='text-neutral-1 text-base'>team@vemaybay.vn</div>
          </div>
        </div>

        {/* Title */}
        <div className='flex flex-col gap-2 bg-[#F2F9FA] px-4 py-3'>
          <div className='text-lg font-semibold'>
            XÁC NHẬN ĐẶT PHÒNG KHÁCH SẠN
          </div>
          <div className='text-base'>
            Mã đơn hàng: <strong>{orderCode}</strong>
          </div>
          <div className='text-base'>
            Ngày đặt: <strong>{formatTimeDate(bookingData?.createdAt)}</strong>
          </div>
        </div>

        {/* Hotel Information */}
        <div className='border-neutral-200 px-4 py-3 flex flex-col gap-[6px]'>
          <div className='text-lg font-semibold'>
            {bookingData.hotelInfo?.name}
          </div>

          <Rate
            style={{ fontSize: 14 }}
            className='custome-star'
            disabled
            allowHalf
            defaultValue={bookingData.hotelInfo?.rating || 0}
          />

          <div className='text-base font-medium'>
            Địa chỉ:{' '}
            {[bookingData.hotelInfo?.address?.line1,
            bookingData.hotelInfo?.address?.line2,
            bookingData.hotelInfo?.address?.city,
              countryName,]
              .filter((item) => item)
              .join(', ')}{' '}
          </div>

          <div className='flex gap-1 text-base font-medium'>
            <span>Số điện thoại</span>
            <span>
              {bookingData.roomsList[0]?.holder?.phoneCode && bookingData.roomsList[0]?.holder?.phoneNumber
                ? `(${bookingData.roomsList[0]?.holder?.phoneCode}) ${bookingData.roomsList[0]?.holder?.phoneNumber}`
                : null}
            </span>
          </div>
        </div>

        {/* Booking Information */}
        <div className='px-4 pt-3 pb-2'>
          <h4 className='mb-2 text-md font-semibold'>Thông tin đặt phòng</h4>

          <div className='flex flex-col gap-4 text-base'>
            <div className='flex text-base'>
              <div className='w-1/3 text-custom-gray sm:w-1/4'>Đặt phòng:</div>
              <div className='w-2/3 font-semibold sm:w-3/4'>
                {`${bookingData?.roomsList.length} phòng, ${bookingData?.totalNights} đêm`}{' '}
              </div>
            </div>

            <div className='flex'>
              <div className='w-1/3 text-custom-gray sm:w-1/4'>Loại phòng:</div>
              <div className='w-2/3 font-semibold sm:w-3/4'>
                {bookingData.roomsList[0]?.name}
              </div>
            </div>

            <div className='flex'>
              <div className='w-1/3 text-custom-gray sm:w-1/4'>
                Loại giường:
              </div>
              <div className='w-2/3 font-semibold sm:w-3/4'>
                {bookingData.roomsList[0]?.bedOption?.name || ''}
              </div>
            </div>

            <div className='flex'>
              <div className='w-1/3 text-custom-gray sm:w-1/4'>Nhận phòng:</div>
              <div className='flex gap-3'>
                <span className='col-span-10 font-semibold sm:col-span-4 md:col-span-3'>
                  {bookingData.checkInDate &&
                    bookingData.checkInDate !== '' &&
                    formatDateDayMonthYear(bookingData.checkInDate)}
                </span>
                <span className='col-span-5 font-normal text-custom-gray sm:col-span-6 md:col-span-2'>
                  {bookingData.checkInTime && `Từ ${bookingData.checkInTime}`}
                </span>
              </div>
            </div>

            <div className='flex'>
              <div className='w-1/3 text-custom-gray sm:w-1/4'>Trả phòng:</div>
              <div className='flex gap-3'>
                <span className='col-span-10 font-semibold sm:col-span-4 md:col-span-3'>
                  {bookingData.checkOutDate &&
                    bookingData.checkOutDate !== '' &&
                    formatDateDayMonthYear(bookingData.checkOutDate)}
                </span>
                <span className='col-span-5 font-normal text-custom-gray sm:col-span-6 md:col-span-2'>
                  {bookingData.checkOutTime &&
                    `Đến ${bookingData.checkOutTime}`}
                </span>
              </div>
            </div>

            <div className='flex'>
              <div className='w-1/3 text-custom-gray  sm:w-1/4'>
                Đặt chỗ cho:
              </div>
              <div className='w-2/3 font-semibold sm:w-3/4'>
                <div className='text-base font-semibold'>
                  {bookingData.roomsList &&
                    (() => {
                      const totals = bookingData.roomsList.reduce(
                        (acc, room) => {
                          acc.adt += room?.occupancy?.adt || 0;
                          acc.chd += room?.occupancy?.chd || 0;
                          return acc;
                        },
                        { adt: 0, chd: 0 }
                      );

                      const displayText = [
                        totals.adt > 0 ? `${totals.adt} người lớn` : '',
                        totals.chd > 0 ? `${totals.chd} trẻ em` : '',
                      ]

                        .filter((item) => item)
                        .join(', ');

                      return displayText;
                    })()}
                </div>
              </div>
            </div>

            {bookingData?.amenitiesList.length > 0 ? (
              <div className='flex'>
                <div className='w-1/3 text-custom-gray  sm:w-1/4'>
                  Tiện nghi
                </div>
                <div className='w-2/3 font-semibold sm:w-3/4'>
                  {bookingData?.amenitiesList.join(', ')}
                </div>
              </div>
            ) : null}

            {bookingData?.specialRequest ? (
              <div className='flex'>
                <div className='w-1/3 text-custom-gray sm:w-1/4'>
                  Yêu cầu đặc biệt
                </div>
                <div className='flex flex-col w-2/3 sm:w-3/4'>
                  <div className='font-semibold'>
                    {bookingData?.specialRequest}
                  </div>
                  <div className='text-custom-gray'>Không đảm bảo đáp ứng yêu cầu đặc biệt.
                    Nếu không nhận được phản hồi từ nơi lưu trú,
                    hãy liên hệ trực tiếp với họ để xác nhận.
                    Nơi lưu trú có thể thu phí với một số yêu cầu đặc biệt
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {/* Room list */}
        {bookingData.roomsList.map((room, index) => (
          <div key={index} className='flex flex-col gap-3 pt-3 pb-2 px-4'>
            <div className='text-md font-semibold'>
              Phòng {index + 1}: {room.name}
            </div>

            <div className='flex flex-col gap-4 text-base'>
              <div className='flex'>
                <div className='w-1/3 text-custom-gray sm:w-1/4'>
                  Tên khách:
                </div>
                <div className='w-2/3 font-semibold sm:w-3/4'>
                  {room.holder?.surname} {room.holder?.givenName}
                </div>
              </div>

              <div className='flex'>
                <div className='w-1/3 text-custom-gray  sm:w-1/4'>
                  Mã đặt chỗ:
                </div>
                <div className='w-2/3 font-semibold sm:w-3/4'>
                  {room?.confirmationId}
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* Cancellation Policy */}
        {bookingData?.cancelPoliciesList?.length > 0 && (
          <div className='p-4 border-t border-neutral-200'>
            <h4 className='mb-[10px] text-md font-semibold'>Chính sách hủy</h4>
            {bookingData?.cancelPoliciesList
              .filter((item) => item !== '')
              .map((item, index) => {
                const cleanHTML = DOMPurify.sanitize(item);
                return (
                  <div
                    key={index}
                    className='leading-normal text-justify custom-list text-base'
                    dangerouslySetInnerHTML={{ __html: cleanHTML }}
                  />
                );
              })}
          </div>
        )}


        {/* Important Information */}
        {bookingData?.checkInInstructionsList?.length > 0 && (
          <div className='p-4'>
            <h4 className='mb-[10px] text-md font-semibold'>
              Thông tin quan trọng
            </h4>
            {bookingData?.checkInInstructionsList
              .filter((item) => item !== '')
              .map((item, index) => {
                const cleanHTML = DOMPurify.sanitize(item);
                return (
                  <div
                    key={index}
                    className='leading-normal text-justify custom-list text-base'
                    dangerouslySetInnerHTML={{ __html: cleanHTML }}
                  />
                );
              })}
          </div>
        )}
        {/* Note */}
        {bookingData?.priceInfo?.totalPayAtHotel && bookingData?.priceInfo?.totalPayAtHotel > 0
          ? (
            <div className='px-4 pb-4 text-base' style={{ paddingTop: '10px' }}>
              {`Lưu ý: Bạn cần thanh toán thêm ${formatCurrencyHaveDecimal(bookingData?.priceInfo.totalPayAtHotel,
                bookingData?.priceInfo.payAtHotelCurrency)} cho khách
                sạn khi đến nơi lưu trú. Giá thực tế có thể thay 
                đổi tùy theo chính sách của khách sạn và thuế địa 
                phương tại thời điểm lưu trú.`}
            </div>
          )
          : null}
      </div>
    );
  }
);

PrintConfirmHotel.displayName = 'PrintConfirmHotel';

export default PrintConfirmHotel;
